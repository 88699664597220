/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { Fragment, useEffect, useState } from "react";
import { BaseMedium, Big, Display, Large, OverlapAfterNLines, SmallMedium, Uppercase, colors, secondaryColor } from "../ui-lib/Atoms/Typography/styles";
import mediaQueries from "../ui-lib/Atoms/MediaQueries";
import ScheduleCardList from "./ScheduleCardList";
import { InsiderLink } from "../InsiderLink";
import SmallEventCardList from "./SmallEventCardList";
import { isEmpty } from "lodash";
import { getItemUrl } from "../../helpers/linkGenerators";
import { Map } from "immutable";
import { sendClevertapEvent } from "../../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
import { SmallSecondaryLightButton } from "../ui-lib/Atoms/Buttons/styles";
const Showcase = ({ fetchGoPage, events, loading, showcaseInCurrentCity, selectedCity }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 660);
    const [mounted, setMounted] = useState(false);
    const hasEvents = loading !== undefined && loading === false && events && events.size > 0;
    // add the selected city in the first card
    const sortedEvents = events &&
        events
            .filter(event => event.get("event_state") === "available" || event.get("event_state") === "coming_soon")
            .sort((eventA, eventB) => {
            const cityA = eventA.get("city").toLowerCase();
            const cityB = eventB.get("city").toLowerCase();
            if (cityA === selectedCity) {
                return -1; // Move eventA to the front
            }
            else if (cityB === selectedCity) {
                return 1; // Move eventB to the front
            }
            else {
                return 0; // Keep the order unchanged for other events
            }
        });
    // hide the showcase if the selected city is not in the list of events
    const showShowcaseInCurrentCity = sortedEvents && sortedEvents.map((event) => event.get("city").toLowerCase()).includes(selectedCity);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 660);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        setMounted(true);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    if (isEmpty(showcaseInCurrentCity))
        return <Fragment />;
    const { cardListType: template = "", heading, subheading, caption, tag, mobileImage, desktopImage, cardListLabel = "", categoryIcon, cta = "Read more", link, isOnline = false, lightMode = false, overrideShowInCurrentCityCheck = false, openExternalLinkInNewTab = true } = showcaseInCurrentCity[0];
    useEffect(() => {
        if (tag) {
            fetchGoPage(tag);
        }
    }, [tag]);
    if (!(showShowcaseInCurrentCity || overrideShowInCurrentCityCheck) || (template !== "" && sortedEvents.size === 0))
        return <Fragment />;
    const ctaLink = getItemUrl(new Map(link), selectedCity);
    const openInNewTab = link.resource_type === "external" && openExternalLinkInNewTab;
    const getContentStyle = (template, hasEvents) => {
        const commonStyles = [PaddedAllSides, HalfWidthLargeDesktop];
        switch (template) {
            case "schedule-card":
                return hasEvents ? [...commonStyles, NoBottomPadding] : [...commonStyles];
            case "small-event-card":
                return hasEvents ? [SmallEventCardContentPadding] : [...commonStyles];
            default:
                return [...commonStyles];
        }
    };
    const sendClevertap = (payload) => sendClevertapEvent(CLEVERTAP_ACTIONS.SHOWCASE_CLICKED, Object.assign({ link: ctaLink, image: isMobile ? mobileImage : desktopImage, city: selectedCity, heading, resourceType: link.resource_type, tag }, payload));
    return (<div css={css([
            Container(lightMode),
            mounted && BackgroundImage(isMobile ? mobileImage : desktopImage, lightMode),
            template === "small-event-card" && ContainerRow
        ])} className="container">
			<EventDetails heading={heading} subheading={subheading} caption={caption} Icon={categoryIcon} cta={cta} link={ctaLink} isOnline={isOnline} overrideCss={getContentStyle(template, hasEvents)} openInNewTab={openInNewTab} onClick={() => sendClevertap()}/>

			{template === "schedule-card" && (<div css={css([Content, ScheduleCardBottomPadding])}>
					{hasEvents && (<Fragment>
							{cardListLabel && (<div css={css([CardListTitle, LeftRightPadding])}>
									<p css={css([Display, Large, Uppercase])}>{cardListLabel}</p>
									<div css={css(Seperator)}/>
								</div>)}

							<ScheduleCardList items={sortedEvents} overflowLink={ctaLink} accentColor={secondaryColor(isOnline)} loading={loading} lightMode={lightMode} openInNewTab={openInNewTab} onClick={sendClevertap}/>
						</Fragment>)}
				</div>)}

			{template === "small-event-card" && (<div css={css([Content, Gap, SmallEventCardPadding, hasEvents && events.size === 1 && `width: auto;`])}>
					{hasEvents && (<Fragment>
							{cardListLabel && (<div css={css([CardListTitle, HideForSmallDevices])}>
									<div css={css(Seperator)}/>
									<p css={css([Display, Big, Uppercase])}>{cardListLabel}</p>
									<div css={css(Seperator)}/>
								</div>)}

							<SmallEventCardList items={sortedEvents} overflowLink={ctaLink} overflowIcon={categoryIcon} accentColor={secondaryColor(isOnline)} loading={loading} overflowLabel={`Explore more in ${heading}`} lightMode={lightMode} openInNewTab={openInNewTab} onClick={sendClevertap}/>
						</Fragment>)}
				</div>)}
		</div>);
};
export default Showcase;
const EventDetails = ({ heading, subheading, caption, Icon, cta, link, isOnline = false, overrideCss = [], openInNewTab = false, onClick = () => { } }) => {
    const isInsiderLink = link.includes("insider.in");
    return (<div css={css([Content, ...overrideCss])}>
			{Icon && (<div css={css(IconContainer(isOnline))}>
					{React.cloneElement(Icon, {
                width: "32",
                height: "32",
                fill: "#FCFCFD"
            })}
				</div>)}

			<div css={css(Details)}>
				<p css={css([Display, Big, OverlapAfterNLines("1")])}>{heading}</p>
				{subheading && <p css={css([BaseMedium, OverlapAfterNLines()])}>{subheading}</p>}
				{caption && <p css={css([SmallMedium, OverlapAfterNLines("4")])}>{caption}</p>}
			</div>

			<InsiderLink href={link} target={openInNewTab ? "_blank" : undefined} isSPA={!isInsiderLink} onClick={onClick}>
				<button css={css([SmallSecondaryLightButton])}>{cta}</button>
			</InsiderLink>
		</div>);
};
const BackgroundImage = (image, lightMode) => `
	${lightMode
    ? `background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 23.96%, rgba(255, 255, 255, 0.00) 100%), 
			url(${image}) top left/cover no-repeat;`
    : `background: linear-gradient(0deg, rgba(12, 23, 47, 0.60) 15%, rgba(12, 23, 47, 0.00) 100%), url(${image}) top left/cover no-repeat;`}`;
const Container = (lightMode) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 15px;
    color: ${lightMode ? colors["--grey-highlight-copy"] : colors["--Grey-Text-White"]};
    border-radius: 30px;
	background: ${colors["--Background-BG-SecBTN-Light"]};
	margin: 45px auto;

    ${mediaQueries("mobile")} {
		width: 100% !important;
        justify-content: flex-end;
        height: 656.25px;
        min-height: 570px;
        border-radius: 0;
		margin: 30px auto;
	}

    ${mediaQueries("tablet")} {
        min-height: 288px;
    }

    ${mediaQueries("desktop")} {
        min-height: 392px;
    }

    ${mediaQueries("extraLargeDesktop")} {
        min-height: 540px;
    }
`;
const ContainerRow = `
    ${mediaQueries("largeDesktop")} {
        align-items: flex-end;
        flex-direction: row;
    }

    ${mediaQueries("extraLargeDesktop")} {
        gap: 30px;
    }
`;
const Content = `
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;
const HalfWidthLargeDesktop = `
	${mediaQueries("largeDesktop")} {
		width: 50%;
	}
`;
const Gap = `
	${mediaQueries("largeDesktop")} {
		gap: 30px;
	}
`;
const PaddedAllSides = `
	${mediaQueries("mobileAndTablet")} {
		padding: 120px 30px 30px 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding: 60px;
	}
`;
const ScheduleCardBottomPadding = `
	${mediaQueries("mobileAndTablet")} {
		padding-bottom: 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding-bottom: 60px;
	}
`;
const SmallEventCardPadding = `
	${mediaQueries("mobileAndTablet")} {
		padding-bottom: 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding: 60px 60px 60px 0;
	}
`;
const SmallEventCardContentPadding = `
	${mediaQueries("mobileAndTablet")} {
		padding: 120px 30px 0 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding: 60px 0 60px 60px;
	}
`;
const LeftRightPadding = `
	${mediaQueries("mobileAndTablet")} {
		padding: 0 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding: 0 60px;
	}
`;
const NoBottomPadding = `
	padding-bottom: 0 !important;
`;
const Details = `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
`;
const CTAButton = `
	border-radius: 12px;
	border: 1px solid rgba(249, 249, 250, 0.06);
	background: ${colors["--Brand-Primary---Navy"]};
	color: ${colors["--Grey-Text-White"]};
	display: flex;
	padding: 6px 15px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	height: 36px;
`;
const IconContainer = (isOnline) => `
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 12px;
    background: ${secondaryColor(isOnline)};  
`;
const CardListTitle = `
    display: flex;  
    width: 100%;
    align-items: center;
    white-space: nowrap;
	gap: 15px;
`;
const Seperator = `
    width: 100%;
    height: 1px;
    background: rgba(249, 249, 250, 0.06);
`;
const HideForSmallDevices = `
    ${mediaQueries("mobileAndTablet")} {
        display: none;
    }
`;
