import { connect } from "react-redux";
import { getCapitalizedSelectedCityName, isFromApp } from "../selectors/index";
import QuickLinks from "../components/QuickLinks";
const mapStateToProps = (state) => {
    return {
        selectedCity: getCapitalizedSelectedCityName(state),
        isFromApp: isFromApp(state)
    };
};
export default connect(mapStateToProps)(QuickLinks);
