import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
export const AccordionPanel = props => {
    const sectionPanelClassName = classNames(props.className, { expanded: props.isPanelShowing });
    return (<div aria-expanded={props.isPanelShowing} className={sectionPanelClassName}>
			{props.isPanelShowing && props.children}
		</div>);
};
AccordionPanel.propTypes = {
    isPanelShowing: PropTypes.bool,
    className: PropTypes.string
};
AccordionPanel.defaultProps = {
    className: "accordion-panel"
};
