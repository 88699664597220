/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CrossIcon } from "./ui/icons/CloseIcon";
import { isH5Build } from "../helpers/misc";
import { openInBrowserForApp } from "../helpers/appBridge";
const INTERSTITIAL_SESSION_KEY = 'interstitial_ad_shown';
const InterstitialAd = ({ isFromApp = false }) => {
    const [isClosed, setIsClosed] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 565);
    const [shouldShowAd, setShouldShowAd] = useState(false);
    const imageUrl = isMobile
        ? "https://media.insider.in/image/upload/c_crop,g_custom/v1733932198/ellk3dsxgqchfgccs3al.png"
        : "https://media.insider.in/image/upload/c_crop,g_custom/v1733932199/he6eytom1wacsdujqrk1.png";
    const districtURL = "https://link.district.in/DSTRKT/insiderinterstetial";
    useEffect(() => {
        // Check if ad has been shown in this session
        const hasBeenShown = sessionStorage.getItem(INTERSTITIAL_SESSION_KEY) === 'true';
        if (!hasBeenShown) {
            setShouldShowAd(true);
        }
    }, []);
    const handleClose = () => {
        // Mark as shown in session storage
        sessionStorage.setItem(INTERSTITIAL_SESSION_KEY, 'true');
        setIsClosed(true);
    };
    const handleImageClick = () => {
        // Mark as shown before opening link
        sessionStorage.setItem(INTERSTITIAL_SESSION_KEY, 'true');
        if (isH5Build || isFromApp) {
            openInBrowserForApp(districtURL);
        }
        else {
            window.open(districtURL, "_blank");
        }
        setIsClosed(true);
    };
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 565);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // Don't render if ad shouldn't be shown
    if (!shouldShowAd || isClosed) {
        return null;
    }
    return ReactDOM.createPortal(<div css={css([overlayStyle, !isImageLoaded && "display: none;"])} id="transparent-overlay" onClick={handleClose}>
			<div css={css(contentStyle)} onClick={e => {
            e.stopPropagation();
        }}>
				<button css={css(closeButtonStyle)} onClick={handleClose} aria-label="Close ad">
					<CrossIcon width="12" height="12" fill="white"/>
				</button>
				<img src={imageUrl} alt="Interstitial Ad" css={css(imageStyle)} onLoad={() => setIsImageLoaded(true)} onError={handleClose} onClick={handleImageClick}/>
			</div>
		</div>, document.body);
};
export default InterstitialAd;
const overlayStyle = `
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;

	@media (max-width: 565px) {
		align-items: flex-end;
	}
`;
const contentStyle = `
	position: relative;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;

	@media (max-width: 565px) {
		width: 100%;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		overflow: hidden;
		animation: slideUp 0.3s ease-out;
	}

	@keyframes slideUp {
		from {
			transform: translateY(100%);
		}
		to {
			transform: translateY(0);
		}
	}
`;
const closeButtonStyle = `
	position: absolute;
	top: 15px;
	right: 7%;
	background: rgba(255, 255, 255, 0.1);
	border: none;
	cursor: pointer;
	display: inline-flex;
	padding: 9px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	border-radius: 9px;
	z-index: 10;
`;
const imageStyle = `
	@media (min-width: 565px) {
		width: 90%;
		height: auto;
		max-width: 760px;
		max-height: 465.895px;
		border-radius: 12px;
	}

	@media (max-width: 565px) {
		width: 100%;
		height: auto;
	}
`;
