/** @jsx jsx */
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import { jsx, css } from "@emotion/core";
import { colors } from "../styles/common";
const AllHrefStyles = css `
	background-color: ${colors.white};
	color: black;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid ${colors.gray};
	border-radius 5px;
	min-width: 66px;
	
	&:hover {
		pointer: cursor;
	}
`;
const DisplayContentStyle = css `
	display: contents;
`;
const CarouselExtra = (props) => {
    return (<div css={DisplayContentStyle}>
			<InsiderLink css={AllHrefStyles} href={`${props.viewAllHref}`}>
				View all
			</InsiderLink>
		</div>);
};
export default CarouselExtra;
