import React from "react";
export const InsiderVibes = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24 8C17.3726 8 12 13.3726 12 20V27C12 28.1046 11.1046 29 10 29H9C7.34315 29 6 27.6569 6 26V22C6 20.3431 7.34315 19 9 19H10.0352C10.5479 11.7347 16.6042 6 24 6C31.3958 6 37.4521 11.7347 37.9648 19H39C40.6569 19 42 20.3431 42 22V26C42 27.6569 40.6569 29 39 29H38C36.8954 29 36 28.1046 36 27V20C36 13.3726 30.6274 8 24 8ZM9 21C8.44772 21 8 21.4477 8 22V26C8 26.5523 8.44772 27 9 27H10V21H9ZM40 22C40 21.4477 39.5523 21 39 21H38V27H39C39.5523 27 40 26.5523 40 26V22Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M18.4743 16.1494C18.7691 15.9671 19.1372 15.9506 19.4472 16.1056L27.4472 20.1056C27.786 20.275 28 20.6212 28 21V26.9712C28.0038 27.1001 27.9827 27.2312 27.9343 27.3573C27.9182 27.3993 27.8994 27.44 27.878 27.479C27.7844 27.6507 27.6471 27.7846 27.4869 27.8737C27.3776 27.9347 27.2556 27.9759 27.1259 27.9922C27.0219 28.0054 26.9152 28.0024 26.8097 27.9819C26.7106 27.9628 26.6166 27.9291 26.5303 27.883L20 24.6179V37C20 39.7614 17.7614 42 15 42C12.2386 42 10 39.7614 10 37C10 34.2386 12.2386 32 15 32C16.1256 32 17.1643 32.3719 18 32.9996V17C18 16.6534 18.1795 16.3316 18.4743 16.1494ZM18 37C18 35.3431 16.6569 34 15 34C13.3431 34 12 35.3431 12 37C12 38.6569 13.3431 40 15 40C16.6569 40 18 38.6569 18 37ZM26 21.618V25.3818L20 22.3818V18.618L26 21.618Z" fill="#0C172F"/>
			<path d="M32 31C32 30.4477 31.5523 30 31 30C30.4477 30 30 30.4477 30 31V39C30 39.5523 30.4477 40 31 40C31.5523 40 32 39.5523 32 39V31Z" fill="#0C172F"/>
			<path d="M27 32C27.5523 32 28 32.4477 28 33V37C28 37.5523 27.5523 38 27 38C26.4477 38 26 37.5523 26 37V33C26 32.4477 26.4477 32 27 32Z" fill="#0C172F"/>
			<path d="M36 33C36 32.4477 35.5523 32 35 32C34.4477 32 34 32.4477 34 33V37C34 37.5523 34.4477 38 35 38C35.5523 38 36 37.5523 36 37V33Z" fill="#0C172F"/>
			<path d="M39 33.5C39.5523 33.5 40 33.9477 40 34.5V35.5C40 36.0523 39.5523 36.5 39 36.5C38.4477 36.5 38 36.0523 38 35.5V34.5C38 33.9477 38.4477 33.5 39 33.5Z" fill="#0C172F"/>
			<path d="M24 34.5C24 33.9477 23.5523 33.5 23 33.5C22.4477 33.5 22 33.9477 22 34.5V35.5C22 36.0523 22.4477 36.5 23 36.5C23.5523 36.5 24 36.0523 24 35.5V34.5Z" fill="#0C172F"/>
		</svg>);
};
