// Dumb component that just toggles between Event and Cell cards
import React from "react";
import PropTypes from "prop-types";
// components
import CellCard from "./CellCard";
import ImageCard from "./ImageCard";
import ItemCard from "./ItemCard";
import SummerCard from "./SummerCard";
import TextCard from "./TextCard";
import TinyCard from "./TinyCard";
// containers
import ArticleCard from "../containers/ArticleCardWithSubscription";
import EventCard from "../containers/EventCardWithSubscription";
// helpers
import { generateArticleLink } from "../helpers/linkGenerators";
class DataCard extends React.PureComponent {
    render() {
        const { allowFavouriting, autoPlay, cardData, cardPrefix, isFromApp, isSummerLandingPage, lazy, playOnHover, addBackground, tags, addToCt, index } = this.props, model = cardData && cardData.get("model");
        if (cardData === undefined) {
            // this is where we want to display a shell instead
            // use the EventCard shell since that's the prettiest
            return <EventCard cardData={cardData} isFromApp={isFromApp}/>;
        }
        if (model === "artist" || model === "venue") {
            return <TinyCard cardData={cardData} isFromApp={isFromApp}/>;
        }
        if (model === "cell") {
            return <CellCard cardData={cardData} isFromApp={isFromApp}/>;
        }
        // this is so hacky
        // todo refactor all of DataCard not have these stupid if else
        if (model === "text") {
            return <TextCard text={cardData.get("text")} href={cardData.get("href")} className={cardData.get("className")}/>;
        }
        if (model === "image") {
            return (<ImageCard caption={cardData.get("caption")} captionClassName={cardData.get("captionClassName")} className={cardData.get("className")} href={cardData.get("href")} imgClassName={cardData.get("imgClassName")} shouldDisplayGenreStrip={cardData.get("shouldDisplayGenreStrip")} src={cardData.get("src")}/>);
        }
        // HACK
        if (model === "article" && isSummerLandingPage) {
            return (<SummerCard href={generateArticleLink(cardData.get("slug"))} imgSrc={cardData.get("horizontal_cover_image")} title={cardData.get("title")}/>);
        }
        if (model === "article") {
            return (<ArticleCard autoPlay={autoPlay} cardData={cardData} cardPrefix={cardPrefix} isFromApp={isFromApp} playOnHover={playOnHover} addBackground={addBackground}/>);
        }
        if (model === "item") {
            return <ItemCard cardData={cardData} metaClassName="list-card"/>;
        }
        return (<EventCard allowFavouriting={allowFavouriting} cardData={cardData} cardPrefix={cardPrefix} isFromApp={isFromApp} lazy={lazy} tags={tags} addToCt={addToCt} index={index}/>);
    }
}
DataCard.propTypes = {
    autoPlay: PropTypes.bool,
    cardData: PropTypes.object,
    isFromApp: PropTypes.bool.isRequired,
    lazy: PropTypes.bool,
    playOnHover: PropTypes.bool,
    addBackground: PropTypes.bool
};
export default DataCard;
