import React from "react";
import { connect } from "react-redux";
// components
import HomePageSearch from "../components/HomePageSearch";
// containers
import SearchViewTogglerWithSubscription from "./SearchViewTogglerWithSubscription";
// actions
import { setSearchBarInView } from "../actions/index";
import { getSelectedCityName } from "../selectors";
const mapStateToProps = (state) => ({
    selectedCity: getSelectedCityName(state)
});
const mapDispatchToProps = (dispatch) => {
    return {
        setSearchBarInView: (bool) => dispatch(setSearchBarInView(bool))
    };
};
const HomePageSearchRedux = connect(mapStateToProps, mapDispatchToProps)(HomePageSearch);
const showInlineSearchTrueFn = ({ showInlineSearch }) => true;
const HomePageSearchWithSubscription = props => {
    return (<SearchViewTogglerWithSubscription componentToRender={HomePageSearchRedux} showOnDesktop={false} toggleBehaviourFn={showInlineSearchTrueFn} {...props}/>);
};
export default HomePageSearchWithSubscription;
