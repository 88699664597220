import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "NH7 Weekender",
        subtitle: "Back in Pune!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731913877/vin1ptq3e4eekvw9yncn.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    {
        title: "Artbat LIVE!",
        subtitle: "In Mumbai",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731137277/sfakwwuokqycymwcd6be.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "artbat-live-in-mumbai-dec-24-2024"
        }
    },
    {
        title: "Maceo Plex",
        subtitle: "LIVE in Mumbai!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509182/ttfjtmt14vw7q6y4qcvw.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "maceo-plex-at-nesco-mumbai-ny2025"
        }
    },
    //Delhi
    {
        title: "Coming to Delhi",
        subtitle: "Indie Music Festival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509227/qiser55b7xtptr2ih1wt.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "indie-music-festival-jan11-2025"
        }
    },
    {
        title: "3 Legends LIVE!",
        subtitle: "Three Maestros Unite",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894118/lcwphlec8fpefxhwphok.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "triveni-3mp-delhi-dec29-2024"
        }
    },
    {
        title: "Artbat LIVE",
        subtitle: "In Delhi on Dec 25!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731688017/gqas8lo1wkox887g5djm.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "artbat-live-in-delhi-christmas-day-dec25-2024"
        }
    },
    //Bengaluru
    {
        title: "Legends LIVE",
        subtitle: " Shankar Ehsaan Loy",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687993/pgtao0k9bjv417tldrhk.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "shankar-ehsaan-loy-live-in-concert-bangalore-2024-dec21-2024"
        }
    },
    {
        title: "Korolova LIVE",
        subtitle: "At Sunburn Union!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731138025/updmsusmib72ebor85pu.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "korolova-live-in-bangalore-dec14-2024"
        }
    },
    {
        title: "Christmas Sante",
        subtitle: "In Bengaluru, Dec 22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894153/ih3ww2fkzfndh10o2d4u.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-christmas-edition-bengaluru-dec22-2024"
        }
    },
    //Pune
    {
        title: "NH7 Weekender",
        subtitle: "Back in Pune!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731913877/vin1ptq3e4eekvw9yncn.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    {
        title: "Da Vinci Live",
        subtitle: "Immersive Art Journey",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292229/pwzqfoo1xnlsgqfylplx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "da-vinci-genius-an-interactive-immersive-experience-mumbai-nov30-2024"
        }
    },
    {
        title: "Xmas Vibes",
        subtitle: "Ft. Madmen LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509575/qpzbwioiiytkoe2olhua.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "santas-shakedown-christmas-eve"
        }
    },
    //Goa
    {
        title: "No EDM, No Party",
        subtitle: "Raeeth, Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699563/qx8djyzerxqedakh6nxc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-experience-31-dec-dec31-2024"
        }
    },
    {
        title: "Good Luck Beta",
        subtitle: "PCRC on Tour",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729863756/hi2bfuyth28rtyo5wqyb.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-goa-dec15-2024"
        }
    },
    {
        title: "Korolova LIVE",
        subtitle: "In Goa This Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731138025/updmsusmib72ebor85pu.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "korolova-at-salud-goa-dec13-2024"
        }
    },
    //Hyderabad
    {
        title: "Arjun Vagale",
        subtitle: "LIVE in Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509599/xmmzufg7pdlgv0phe6ny.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "ext-presents-arjun-vagale-aardy-beholdgond-nzo-dec14-2024"
        }
    },
    {
        title: "Beyond the Flea",
        subtitle: "Coming to Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509624/rjs4j1tlmjfc2s1psiyz.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "beyond-the-flea-dec15-2024"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Chennai
    {
        title: "Cinema in Focus",
        subtitle: "In Chennai this Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894362/qgotw8oojiqoxrm24gna.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "22nd-chennai-international-film-festival-delegate-registration-dec12-2024"
        }
    },
    {
        title: "PETTA RAP",
        subtitle: "Back in Chennai!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509675/lxt634sopn2rmgtjrhkl.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "petta-rap-the-originals-dec15-2024"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
    //Kolkata
    {
        title: "Armaan Live",
        subtitle: "Pop Beats in Kolkata",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292824/ol2ktysnm7oueailqfqx.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-kolkata-dec24-2024"
        }
    },
    {
        title: "Agents of Time",
        subtitle: "LIVE on Dec 29th!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1729498345/hbubi7mz5x3x0mzhwf2o.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "reverb-rti-present-agents-of-time-kolkata"
        }
    },
    {
        title: "It's a 'Mehfil'",
        subtitle: "Bismil Ke Mehfil ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728704307/iik0biqehnzcn18smnj7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "bismil-ke-mehfil-dec28-2024"
        }
    },
    //coimbatore
    {
        title: "Vibe with Deva",
        subtitle: "Thenisai Thendral LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687719/iesutmc9uwtbagem4lwp.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        title: "Cinema in Focus",
        subtitle: "In Chennai this Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894392/cfpvxspm9rl2ozkkvxab.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "22nd-chennai-international-film-festival-delegate-registration-dec12-2024"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
    //lucknow
    {
        title: "It's All Hearts",
        subtitle: "Shreya Ghoshal LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687723/naedks0x9qkpm62rrr04.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "shreya-ghoshal-all-hearts-tour-lucknow-dec21-2024"
        }
    },
    {
        title: "Farhan Akhtar",
        subtitle: "LIVE in Lucknow!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509758/pmxm3i4ylnhchqlcibx1.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-lucknow-dec22-2024"
        }
    },
    {
        title: "21 Savage LIVE",
        subtitle: "On Jan 31st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894448/kh2lcv9uxblsjeyjup6o.jpg",
        includeCities: ["Lucknow"],
        link: {
            resource_type: "event",
            slug: "indian-sneaker-festival-jan31-feb2-2025"
        }
    },
    //Visakhapatnam
    {
        title: "Ben Bohmer LIVE",
        subtitle: "In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687709/hobsdmq4bnflbfdj4icp.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-immersive-experience-quake-hyderabad-dec29-2024"
        }
    },
    {
        title: "Merakee LIVE",
        subtitle: "In Vizag",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894536/kwhoifpgmb8uewmdf5gz.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "micless-with-merakee-vizag-dec14-2024"
        }
    },
    {
        title: "Vidya Vox Live",
        subtitle: "Fusion Beats in Vizag",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292951/lrkc17lbiosn2nzf2ggg.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        }
    },
    //Vizag
    {
        title: "Ben Bohmer LIVE",
        subtitle: "In Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687709/hobsdmq4bnflbfdj4icp.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-immersive-experience-quake-hyderabad-dec29-2024"
        }
    },
    {
        title: "Merakee LIVE",
        subtitle: "In Vizag",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894536/kwhoifpgmb8uewmdf5gz.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "micless-with-merakee-vizag-dec14-2024"
        }
    },
    {
        title: "Vidya Vox Live",
        subtitle: "Fusion Beats in Vizag",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292951/lrkc17lbiosn2nzf2ggg.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        }
    },
    //Madurai
    {
        title: "Vibe with Deva",
        subtitle: "Thenisai Thendral LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731687732/oijtq2o76zup3vwi546c.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        title: "Cinema in Focus",
        subtitle: "In Chennai this Dec!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894392/cfpvxspm9rl2ozkkvxab.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "22nd-chennai-international-film-festival-delegate-registration-dec12-2024"
        }
    },
    {
        title: "December 28th",
        subtitle: "Vijay Antony LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736133/adhms6udg2gtgn6zcpib.jpg",
        includeCities: ["Madurai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        }
    },
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443931/fie76nud8zwxbh4om3fv.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1730443926/wldtavuqq7uqy1dpqkuz.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731737031/ks8zhedhbu9zcy6kpwab.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731737035/bgjvucoe14hbcytpve1p.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloomlive-delhi-ncr-dec28-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293325/gfwicpcm8z7kiwkgh4in.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293325/my9t4tpc0egzk9r4qz7u.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-bengaluru-dec28-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738596/l9hchl7bdwx46eo2a44q.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738600/x7bpalndil28ktj3jiv4.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509888/bfz1rgy5zclbmbyolheg.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509891/temgca79fayaqidtrerv.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509813/dwieaoptmmkmqm0r8ifi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509837/g0aiic4hxo6qirryw9s6.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "circus-x-namascray-experience-2024-ny2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509932/fofeyd4bmbivs5libvvw.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509933/g4evllu3gk6vmazrodgv.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "petta-rap-the-originals-dec15-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894899/kyxxgks88m0zsory8sex.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894929/v2tpfcpmfyjvz62wb5lq.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-kolkata-dec24-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105013/kmkb6wvqgnlq9icerzfu.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105002/hcjxm4k2ru6m41papxox.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738978/x3lygeubowccqzemwxuo.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738981/tte8aiiddiwa0bepkfaq.jpg",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "legacy-rewind-ft-farhan-akhtar-live-lucknow-dec22-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895010/dxh8bxwgsyel27syk22l.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895008/elrko17tpa89pfxandil.jpg",
        includeCities: ["visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895010/dxh8bxwgsyel27syk22l.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895008/elrko17tpa89pfxandil.jpg",
        includeCities: ["vizag"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105013/kmkb6wvqgnlq9icerzfu.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732105002/hcjxm4k2ru6m41papxox.jpg",
        includeCities: ["madurai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-madurai-jan18-2025"
        }
    },
];
export const showcase = [
    //Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293586/jsicdmci3arb1prbd3az.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732293585/yqtx50iaqyi0hepettmk.jpg",
        heading: "AP Dhillon - 2024 India Tour",
        subheading: "Catch  'The Brownprint' 2024 tour Live with Premium Experiences",
        caption: "AP Dhillon, center stage! The Brownprint Tour’s 360° lands in India, with a 360° experience and redefining vibes with his biggest hits.",
        cta: "Read more",
        includeCities: ["delhi", "chandigarh"],
        tag: "ap-dhillon-live-the-brownprint",
        cardListType: "schedule-card",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "article",
            slug: "ap-dhillon-india-tour"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733510672/g8jffcpoxb8clclukidw.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733510679/aj0498lkiwiih7eon8fl.jpg",
        categoryIcon: <Music />,
        heading: "Salud: Vibes by the Shore",
        subheading: "Goa’s go-to venue for unforgettable experiences",
        caption: "Enjoy panoramic views, great vibes, delicious food, and drinks at Salud, Goa—the perfect spot for events or a relaxing evening by the beach!",
        cta: "Book Now",
        includeCities: ["goa"],
        cardListType: "small-event-card",
        tag: "salud-goa",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/salud-goa/venue"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //Type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733820482/pkuc5retytohvxpjrocp.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733820487/o2srsdigrt6wsb3gsxkq.jpg",
        categoryIcon: <Music />,
        heading: "Korolova Live at Sunburn Union",
        subheading: "A Spectacular Night of Melodic Beats in Bengaluru!",
        caption: "Ukraine’s Korolova, a melodic house and techno sensation, brings her global hits and 180M+ streams to Sunburn Union Bengaluru. Don’t miss this epic evening!",
        cta: "Buy Now",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "korolova-live-in-bangalore-dec14-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738155/slvnwtnoxgt9fp1k2u8w.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738129/uob4xfbfix2s9iyn0hz4.jpg",
        categoryIcon: <Music />,
        heading: "NH7 Weekender is back!",
        subheading: "15 years of NH7 Weekender",
        caption: "Time flies when you're in The Weekender State of Mind! Celebrate 15 years of NH7 Weekender on Dec 14-15 at Teerth Fields, Pune.",
        cta: "Buy Now",
        includeCities: ["pune", "mumbai"],
        link: {
            resource_type: "event",
            slug: "nh7-weekender-2024-pune-dec14-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895519/dt1rilqdxodtxknwazff.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895520/irepy0iqdlg3uwxfj1ut.jpg",
        categoryIcon: <Music />,
        heading: "Ben Böhmer: In Bloom",
        subheading: "Hyderabad gets ready for an epic live performance",
        caption: "Experience Ben Böhmer’s largest tour in Hyderabad with his signature sounds and musical innovation. Don’t miss this unforgettable night!",
        cta: "Buy Now",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-immersive-experience-quake-hyderabad-dec29-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733511005/p81yhc6vxnkt0aornpvh.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1733595168/c3myyutdks3usqosxl8f.jpg",
        categoryIcon: <Music />,
        heading: "Vidya Vox Live in Vizag",
        subheading: "A global music sensation, blending Indian roots with modern beats",
        caption: "Catch Vidya Vox live in Vizag! Enjoy her viral hits and iconic mashups blending Indian melodies with electronic and hip-hop beats. Don’t miss it!",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "vidya-vox-vizag-14th-dec-dec14-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895519/dt1rilqdxodtxknwazff.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732895520/irepy0iqdlg3uwxfj1ut.jpg",
        categoryIcon: <Music />,
        heading: "Ben Bohmer LIVE",
        subheading: "Experience the magic of Ben Bohmer with Anyasa & Stephan Bodzin this Dec 27!",
        caption: "Join us for an unforgettable night with Ben Bohmer in Kolkata on Dec 27 at Bisw Bangla Milan Mela. Get ready for mind-blowing melodies and an epic live performance!",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "ben-bohmer-bloom-live-kolkata-27dec-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738331/y5ysniu4l9qzdjmekppa.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731738336/agemddfvawjhtewmar62.jpg",
        categoryIcon: <Music />,
        heading: "Vijay Antony LIVE",
        subheading: "Relive the nostalgia with him on stage!",
        caption: "Vijay Antony, the OG of Tamil rap since 2005, continues to shape the industry with timeless hits like \“Saathikadi,” \“Aathichudi,\” and “Machakanni.\"",
        cta: "Buy Now",
        includeCities: ["chennai", "coimbatore", "madurai"],
        link: {
            resource_type: "event",
            slug: "vijay-antony-3-live-in-concert-chennai-dec28-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
