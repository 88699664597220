import React from "react";
import { imageCacheLink, gifVideoLink } from "../helpers/linkGenerators";
class GifVideo extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.playVideo = () => {
            if (this.props.playOnHover && this.videoRef && this.videoRef.play) {
                this.videoRef.play();
            }
        };
        this.pauseVideo = () => {
            if (this.props.playOnHover && this.videoRef && this.videoRef.pause) {
                this.videoRef.pause();
            }
        };
    }
    render() {
        return (<video src={gifVideoLink(this.props.src)} muted={true} autoPlay={this.props.autoPlay} preload="auto" loop={true} poster={imageCacheLink(this.props.src, { w: 0, f: "jpg" })} playsInline={true} className={this.props.className} ref={ref => (this.videoRef = ref)} onMouseEnter={this.playVideo} onMouseLeave={this.pauseVideo}/>);
    }
}
GifVideo.defaultProps = {
    playOnHover: true,
    autoPlay: false
};
export default GifVideo;
