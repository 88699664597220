/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// components
import Image from "../components/Image";
import { InsiderLink } from "../components/InsiderLink";
// helpers + selectors
import { generateEventLink } from "../helpers/linkGenerators";
// helpers
import { CLEVERTAP_ACTIONS, EVENT_CATEGORIES, GENERAL_ACTIONS, HIT_TYPES } from "../helpers/constants";
import { sendClevertapEvent } from "../helpers/misc";
import { colors } from "../styles/common";
// Emotion JS
const CardImageStyles = `
	width: 168px;
	height: 94.5px;
	border-radius: 9px;
	background-color: ${colors["lighter-gray"]};
	img {
		width: 100%;
		height: 100%;
		border-radius: 9px;
	}
`;
const CardNameContainerStyles = `
	position: relative;
	margin: 6px 0;
	color: ${colors.lightGray};
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
	height: 40px;
    display: flex;
    align-items: center;
`;
const CardNameTextStyles = `
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;
const IMAGE_WIDTH = 180;
const IMAGE_WIDTHS = [160, 180, 220, 260, 320];
const IMAGE_STRING = `(max-width: 34.9em) 42vw, (max-width: 63.9em) 18.75vw, (max-width: 100em) 180px, 180px`;
export const RecentlyViewedEventCard = (props) => {
    const { eventData, index } = props;
    const horizontalCoverImage = eventData.get("horizontal_cover_image");
    const imageUrl = horizontalCoverImage;
    const sendAnalyticsEvents = () => {
        // send event to clevertap
        if (clevertap) {
            sendClevertapEvent(CLEVERTAP_ACTIONS.RECENTLY_VIEWED_CLICK, {
                depth: index + 1,
                slug: eventData.get("slug")
            });
        }
        window.ga &&
            ga(GENERAL_ACTIONS.SEND, {
                hitType: HIT_TYPES.EVENT,
                eventCategory: EVENT_CATEGORIES.RECENTLY_VIEWED,
                eventAction: eventData.get("name"),
                eventLabel: eventData.get("slug")
            });
    };
    return (<InsiderLink href={generateEventLink(eventData.get("slug"))} activeClassName="" isSPA={true} onClick={sendAnalyticsEvents} css={css(`height: 100%;
				display: block;`)}>
			<Image css={css(CardImageStyles)} src={imageUrl} alt={eventData.get("name")} imgWidth={IMAGE_WIDTH} imgWidths={IMAGE_WIDTHS} sizeString={IMAGE_STRING}/>
			{eventData.get("name").length && (<div css={css(CardNameContainerStyles)}>
					<p css={css(CardNameTextStyles)}>{eventData.get("name")}</p>
				</div>)}
		</InsiderLink>);
};
