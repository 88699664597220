/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";
import { mediaFeatures } from "../styles/common";
import { mobilePlaceHolderLinks, placeholderQuickLinks, quickLinks } from "../helpers/constants/homepageConfig";
import IconCard from "./ui-lib/Molecules/Cards/IconCard";
import { isEmpty, shuffle } from "lodash";
import { getItemUrl } from "../helpers/linkGenerators";
import { Map } from "immutable";
import { filterItemsByCity, sendClevertapEvent } from "../helpers/misc";
import { InsiderLink } from "./InsiderLink";
import { CLEVERTAP_ACTIONS } from "../helpers/constants";
/**
 *
 * Displays a section of quick links
 *
 * @param {string} selectedCity - The selected city for which quick links are displayed.
 * @param {boolean} isFromApp - A flag indicating whether the request is from the app.
 * @returns {ReactElement} QuickLinks component.
 */
const QuickLinks = ({ selectedCity = "online", isFromApp = false }) => {
    /**
     * Sanitises the list of quick links to display.
     *
     * @param {IIconCard[]} quickLinks - The list of quick links to filter.
     * @returns {IIconCard[] | undefined} - The filtered and prepared list of quick links.
     */
    const getItemsToShow = (quickLinks) => {
        let filteredLinks = filterItemsByCity(quickLinks, selectedCity);
        // if only one qualifies, hide the component
        if (filteredLinks.length <= 1) {
            return;
        }
        // If there are fewer than 3 links, add placeholders
        if (filteredLinks.length < 3) {
            filteredLinks = filteredLinks.concat(isFromApp ? mobilePlaceHolderLinks : placeholderQuickLinks);
        }
        // If there are more than 3 links, shuffle them
        if (filteredLinks.length > 3) {
            filteredLinks = shuffle(filteredLinks);
        }
        return filteredLinks.slice(0, 3).map(item => (Object.assign(Object.assign(Object.assign({}, item), { link: getItemUrl(new Map(item.link), selectedCity) }), (item.link.resource_type === "external" && { openInNewTab: true }))));
    };
    const items = getItemsToShow(quickLinks);
    // if quickLinks are empty, render nothing
    if (isEmpty(quickLinks) || isEmpty(items)) {
        return <Fragment />;
    }
    return (<section css={css(Container)}>
			<div className="card-list-wrapper" css={css(ListWrapper)}>
				<IconCardList items={items} city={selectedCity}/>
			</div>
		</section>);
};
export default QuickLinks;
/**
 *
 * Displays a list of icon cards, each representing a quick link
 *
 * @param {IIconCard[]} items - The list of icon cards to display.
 * @param {string} city - The selected city for which the icon cards are displayed.
 * @returns {ReactElement} IconCardList component.
 */
const IconCardList = ({ items, city }) => {
    if (!items) {
        return <Fragment />;
    }
    const sendClevertap = (payload) => sendClevertapEvent(CLEVERTAP_ACTIONS.QUICK_LINK_CLICKED, payload);
    return (<ul className="card-list" css={css(CardListContainer)}>
			{items.map((item, index) => {
            const { title = "", subtitle = "", image = "", link, openInNewTab = false } = item;
            const isInsiderLink = link.includes("insider.in");
            return (<InsiderLink href={link} css={css(LinkStyle)} target={openInNewTab ? "_blank" : undefined} isSPA={!isInsiderLink} onClick={() => sendClevertap(Object.assign({ title,
                    subtitle,
                    link,
                    city, position: index + 1 }, (typeof image === "string" && { image })))}>
						<IconCard item={item}/>
					</InsiderLink>);
        })}
		</ul>);
};
const Container = `
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        margin: 30px 0 20px 0;
    }	
`;
const ListWrapper = `
	margin: 0;
    overflow: auto hidden;
	// to hide the scroll bar but keep the functionality
	&::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	  }
`;
const CardListContainer = `
	display: flex;
	align-items: center;
	padding: 10px 0;
`;
const LinkStyle = `
	flex: 1;
	li {
		@media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
			margin: 0 15px 0 0; 
		}
	}

	@media screen and ${mediaFeatures["for-large-tablet"]} {
		&:not(:last-child) {
			margin-right: 30px;
		}
	}
`;
