/** @jsx jsx */
import { jsx, css } from "@emotion/core";
// components
import IconAndTextCard from "./IconAndTextCard";
import TextCard from "./TextCard";
// helpers
import { generateSEOCategoryLink } from "../helpers/linkGenerators";
import { colors } from "../styles/common";
// EmotionJS
const PrimaryCategoryCardStyles = `
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: auto;
	&:hover {
		figure {
			border-color: ${colors.insiderPink};
		}
	}
`;
const CategoryCardStyles = `
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border: 1px solid ${colors["slightly-lighter-gray"]};
	border-radius: 9px;
	padding: 9px 18px;
	&:hover {
		border-color: ${colors.insiderPink};
	}
`;
const CategoryCard = ({ activeClassName, category, primary = true, selectedCity, timeFilter }) => {
    const href = generateSEOCategoryLink(selectedCity, category.get("name"), timeFilter);
    const text = `${category.get("name")} ${category.get("eventCount")} events`;
    const categoryId = category
        .get("events")
        .get(0)
        .getIn(["category_id", "_id"]);
    if (primary) {
        return (<IconAndTextCard activeClassName={activeClassName} activeOverride={true} categoryEventCount={`${category.get("eventCount")}`} categoryName={`${category.get("name")}`} css={css(PrimaryCategoryCardStyles)} href={href} matchWithoutQueryParams={true} text={text} categoryId={categoryId}/>);
    }
    return (<TextCard activeClassName={activeClassName} activeOverride={true} css={css(CategoryCardStyles)} href={href} categoryEventCount={`${category.get("eventCount")}`} categoryName={`${category.get("name")}`} matchWithoutQueryParams={true} text={text}/>);
};
export default CategoryCard;
