import React, { Component } from "react";
import Helmet from "react-helmet";
import { differenceInDays, format } from "date-fns";
import { generateAbsoluteUrl, generateEventLink } from "../helpers/linkGenerators";
import { safeJsonLdReplacer } from "../helpers/safeJsonLdReplace";
class HomePageHelmet extends Component {
    constructor() {
        super(...arguments);
        this.getEventLocation = (eventData, eventSlug) => {
            const eventType = eventData.get("event_type");
            const venueName = eventData.get("venue_name");
            const venueCity = eventData.get("city");
            if (eventType === "online") {
                return {
                    location: {
                        "@type": "VirtualLocation",
                        url: eventSlug
                    }
                };
            }
            return {
                location: {
                    "@type": "Place",
                    name: venueName,
                    address: {
                        "@type": "PostalAddress",
                        addressCountry: "India",
                        streetAddress: `${venueName}, ${venueCity}`
                    },
                    geo: {
                        "@type": "GeoCoordinates",
                        latitude: eventData.getIn(["venue_geolocation", "latitude"]),
                        longitude: eventData.getIn(["venue_geolocation", "longitude"])
                    }
                }
            };
        };
        this.getSchemaForHomePageHelmet = (eventsData) => {
            const homePageSchema = eventsData.map(eventData => {
                const eventSlug = generateAbsoluteUrl(generateEventLink(eventData.get("slug")));
                const { location } = this.getEventLocation(eventData, eventSlug);
                const startDate = format(new Date(eventData.get("min_show_start_time") * 1000), "yyyy-MM-dd");
                let schema = {
                    "@context": "http://schema.org",
                    "@type": "Event",
                    name: eventData.get("name").replace(/<\/?[^>]+(>|$)/g, ""),
                    url: eventSlug,
                    image: eventData.get("horizontal_cover_image"),
                    startDate,
                    location
                };
                return schema;
            });
            return homePageSchema;
        };
        this.checkIfEventWithinFortnight = (timestamp) => {
            const currentDate = new Date();
            const diff = differenceInDays(new Date(timestamp), currentDate);
            return diff <= 14;
        };
        this.getFeaturedEventsForSelectedCity = (eventsData) => {
            const filterEventsForSelectedCity = eventsData.filter(event => (event.get("city").toLowerCase() === this.props.selectedCity ||
                event.get("event_type") === this.props.selectedCity) &&
                this.checkIfEventWithinFortnight(event.get("min_show_start_time") * 1000)).sort((eventA, eventB) => eventA.get("min_show_start_time") - eventB.get("min_show_start_time"));
            return filterEventsForSelectedCity.slice(0, 10);
        };
    }
    render() {
        const { featuredEventsData, selectedCity, title, meta, physicalEventsData } = this.props;
        const eventsList = selectedCity === "mumbai" ? featuredEventsData : selectedCity === "bengaluru" ? physicalEventsData.get("events") : null;
        const eventsListForSchema = eventsList && this.getFeaturedEventsForSelectedCity(eventsList);
        const homePageSchema = eventsListForSchema && this.getSchemaForHomePageHelmet(eventsListForSchema);
        return (<Helmet>
				<title>{title}</title>
				{selectedCity === "online" && <link rel="canonical" href="https://insider.in/online"/>}
				{meta.map((tag, i) => {
                return <meta {...tag} key={i}/>;
            })}
				{homePageSchema === null || homePageSchema === void 0 ? void 0 : homePageSchema.map((schema, i) => {
                return (<script type="application/ld+json" key={i}>
							{JSON.stringify(schema, safeJsonLdReplacer, 4)}
						</script>);
            })}
			</Helmet>);
    }
}
HomePageHelmet.defaultProps = {};
export default HomePageHelmet;
