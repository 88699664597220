export const pricing = `
    font-weight: normal;
    &::before {
        content: "\\20b9";
        font-weight: normal;
    }
`;
export const pricingFree = `
    &::before{
        display: none;
    }
`;
