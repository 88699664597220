import React from "react";
export const Cricket = ({ width = "64", height = "64", fill = "#0C172F", viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M21 7C21 6.44772 20.5523 6 20 6H14C13.4477 6 13 6.44772 13 7L13 8L11 8C10.4477 8 10 8.44772 10 9C10 9.55229 10.4477 10 11 10L13 10L13 11C13 11.5523 13.4477 12 14 12H20C20.5523 12 21 11.5523 21 11V10H22C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8H21V7ZM19 8L15 8V10L19 10V8Z" fill={fill}/>
			<path d="M6 14C6 12.8954 6.89543 12 8 12H12C13.1046 12 14 12.8954 14 14V41C14 41.5523 13.5523 42 13 42C12.4477 42 12 41.5523 12 41L12 14H8V41C8 41.5523 7.55228 42 7 42C6.44772 42 6 41.5523 6 41V14Z" fill={fill}/>
			<path d="M23.4371 26.2425C23.7133 25.7642 23.5494 25.1526 23.0711 24.8765C22.5928 24.6003 21.9812 24.7642 21.7051 25.2425L21.2765 25.9848C21.0004 26.4631 21.1642 27.0747 21.6425 27.3508C22.1208 27.627 22.7324 27.4631 23.0085 26.9848L23.4371 26.2425Z" fill={fill}/>
			<path d="M21.6894 29.2656C21.9953 28.8057 21.8706 28.185 21.4107 27.8791C20.9509 27.5732 20.3302 27.6979 20.0243 28.1578L19.1671 29.4462C18.8612 29.906 18.986 30.5268 19.4458 30.8327C19.9056 31.1386 20.5264 31.0138 20.8323 30.554L21.6894 29.2656Z" fill={fill}/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M20 14C20 12.8954 20.8954 12 22 12H26C27.1046 12 28 12.8954 28 14V23.3926C29.266 25.5026 29.3794 28.2181 28.0619 30.5001C28.0415 30.5354 28.0209 30.5704 28 30.6052V41C28 41.5523 27.5523 42 27 42C26.4477 42 26 41.5523 26 41V32.7465C24.8246 33.5636 23.4239 33.9999 22 34.0013V41C22 41.5523 21.5523 42 21 42C20.4477 42 20 41.5523 20 41V33.7101C19.487 33.5567 18.9836 33.3417 18.4997 33.0623C15.1517 31.1293 14.0045 26.8482 15.9375 23.5001C16.8689 21.887 18.3454 20.7847 20 20.2918V14ZM22 19.999C23.1896 19.9979 24.3954 20.3004 25.4997 20.938C25.6724 21.0376 25.8392 21.1436 26 21.2553V14H22V19.999ZM17.6696 24.5001C18.8557 22.4457 21.2795 21.5515 23.4553 22.2155C23.4428 22.2343 23.4307 22.2535 23.4193 22.2734L22.9907 23.0157C22.7146 23.494 22.8785 24.1055 23.3568 24.3817C23.8351 24.6578 24.4466 24.494 24.7228 24.0157L25.1514 23.2734C25.1722 23.2372 25.1906 23.2003 25.2065 23.1628C27.0402 24.6915 27.5642 27.3622 26.3298 29.5001C24.9491 31.8916 21.8912 32.711 19.4997 31.3303C17.1082 29.9495 16.2889 26.8916 17.6696 24.5001Z" fill={fill}/>
			<path d="M34 14C34 12.8954 34.8954 12 36 12H40C41.1046 12 42 12.8954 42 14V41C42 41.5523 41.5523 42 41 42C40.4477 42 40 41.5523 40 41V14H36V41C36 41.5523 35.5523 42 35 42C34.4477 42 34 41.5523 34 41V14Z" fill={fill}/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M25 9C25 8.44772 25.4477 8 26 8H27V7C27 6.44772 27.4477 6 28 6H34C34.5523 6 35 6.44772 35 7V8H37C37.5523 8 38 8.44772 38 9C38 9.55229 37.5523 10 37 10H35V11C35 11.5523 34.5523 12 34 12H28C27.4477 12 27 11.5523 27 11V10H26C25.4477 10 25 9.55229 25 9ZM33 8L29 8V10L33 10V8Z" fill={fill}/>
		</svg>);
};
