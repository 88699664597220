/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment, useEffect, useState } from "react";
import Image from "../../../Atoms/Image";
import { BaseBold, FontColor, SmallMedium, TextOverflowEllipsis } from "../../../Atoms/Typography/styles";
import { ChevronRight } from "../../../../ui/icons/ChevronRight";
/**
 *
 * Represents an icon card displaying an image, title, and subtitle.
 *
 * @param {IIconCard} item - The object containing the card details.
 * @returns {ReactElement} IconCard component.
 */
const IconCard = ({ item }) => {
    const { title = "", subtitle = "", image = "" } = item;
    const [loaded, setLoaded] = useState(false);
    if (!title && !image) {
        return <Fragment />;
    }
    useEffect(() => {
        setLoaded(true); // the images start loading before the render starts. This forces it to start loading after the list renders
    }, []);
    return (<li css={css(Container)}>
			{typeof image === "string" ? <Image style={ImageStyle} src={loaded ? image : undefined}/> : image}
			<div css={css(TextContainer)}>
				<p css={css([FontColor("--grey-dark-copy"), BaseBold, TextOverflowEllipsis])}>{title}</p>
				<p css={css([FontColor("--grey-regular-copy"), SmallMedium, TextOverflowEllipsis])}>{subtitle}</p>
			</div>
			<ChevronRight fill="#0C172F"/>
		</li>);
};
export default IconCard;
const Container = `
	display: flex;
	padding: 9px;
	align-items: center;
	gap: 9px;
	border-radius: 15px;
	border: 1px solid #E7E8EA;
	cursor: pointer;

	&:hover {
		border: 1px solid white;
		box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 10px 0px;
	}
`;
const ImageStyle = `
	display: flex;
	width: 60px;
	height: 60px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 9px;
`;
const TextContainer = `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	width: 160px;
`;
