/**
 * Defines the device sizes used in media queries.
 */
const deviceSizes = {
    small: 660,
    medium: 1024,
    large: 1350
};
const mediaFeatures = {
    mobile: `(max-width: ${deviceSizes.small}px)`,
    tablet: `(min-width: ${deviceSizes.small}px) and (max-width: ${deviceSizes.medium}px)`,
    mobileAndTablet: `(max-width: ${deviceSizes.medium - 1}px)`,
    desktop: `(min-width: ${deviceSizes.medium}px) and (max-width: ${deviceSizes.large}px)`,
    largeDesktop: `(min-width: ${deviceSizes.medium}px)`,
    extraLargeDesktop: `(min-width: ${deviceSizes.large}px)`
};
/**
 * Function to generate and get a specific media query based on the device.
 * @param device - The device for the media query (e.g., "mobile").
 * @returns The media query string.
 */
const mediaQueries = (device) => `@media screen and ${mediaFeatures[device]}`;
export default mediaQueries;
