import React from "react";
export const HoliTrips = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M31.6351 6.93177C30.6118 5.90843 28.8443 5.57801 27.71 6.71233C26.5757 7.84664 26.9061 9.61409 27.9294 10.6374C28.3025 11.0105 28.7509 11.1713 29.1058 11.2665C29.2852 11.3146 29.4715 11.3534 29.6376 11.3876L29.6742 11.3951C29.8345 11.4282 29.9851 11.4592 30.1422 11.4971C30.7727 11.6494 31.492 11.9085 32.2929 12.7094C32.5901 13.0067 33.0416 13.0863 33.4226 12.9086C33.8036 12.731 34.0328 12.334 33.9962 11.9153C33.76 9.21301 32.4661 7.7628 31.6351 6.93177ZM29.1242 8.12654C29.2554 7.99532 29.7363 7.8614 30.2209 8.34598C30.5695 8.69462 30.9849 9.13805 31.33 9.76384C31.0788 9.67496 30.8385 9.60778 30.6118 9.55302C30.4201 9.50672 30.2376 9.46917 30.0819 9.43714L30.0413 9.42878C29.8691 9.39329 29.7383 9.36545 29.624 9.33481C29.3932 9.27289 29.3482 9.22775 29.3437 9.2233C28.8592 8.73872 28.993 8.25776 29.1242 8.12654Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7287 8.14468C16.115 6.87363 14.8064 6.00232 13.3 6.00232C11.3116 6.00232 9.66447 7.523 9.51158 9.47459C7.55644 9.62056 6 11.2382 6 13.2331C6 15.3252 7.71176 17.0023 9.8 17.0023H17.5C19.9748 17.0023 22 15.0152 22 12.5408C22 10.0664 19.9748 8.07925 17.5 8.07925C17.2374 8.07925 16.9797 8.10164 16.7287 8.14468ZM13.3 8.00232C14.2156 8.00232 14.9605 8.67131 15.0823 9.52131C15.1267 9.83122 15.3137 10.1024 15.5875 10.2542C15.8614 10.4059 16.1905 10.4206 16.4768 10.2939C16.7876 10.1564 17.1332 10.0792 17.5 10.0792C18.8912 10.0792 20 11.1917 20 12.5408C20 13.8899 18.8912 15.0023 17.5 15.0023H9.8C8.79544 15.0023 8 14.1998 8 13.2331C8 12.2664 8.79544 11.4639 9.8 11.4639C9.98692 11.4639 10.1654 11.4915 10.3323 11.5422C10.6866 11.6498 11.0712 11.5529 11.3322 11.2903C11.5931 11.0277 11.6877 10.6425 11.5779 10.2889C11.5275 10.1264 11.5 9.95304 11.5 9.77155C11.5 8.80482 12.2954 8.00232 13.3 8.00232Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M35.3489 30.8949C34.6601 29.2063 33.029 28.0023 31.1 28.0023C28.5611 28.0023 26.5402 30.0854 26.5006 32.6188C23.9834 32.6728 22 34.7758 22 37.31C22 39.8775 24.0358 42.0023 26.6 42.0023H36.5C39.5613 42.0023 42 39.4642 42 36.3869C42 33.3097 39.5613 30.7716 36.5 30.7716C36.1056 30.7716 35.7204 30.8141 35.3489 30.8949ZM31.1 30.0023C32.3898 30.0023 33.4908 30.9856 33.6736 32.3087C33.7169 32.6221 33.9059 32.8965 34.1833 33.0486C34.4607 33.2008 34.7937 33.2127 35.0812 33.0807C35.5142 32.8821 35.9935 32.7716 36.5 32.7716C38.4093 32.7716 40 34.3662 40 36.3869C40 38.4077 38.4093 40.0023 36.5 40.0023H26.6C25.1878 40.0023 24 38.821 24 37.31C24 35.7991 25.1878 34.6177 26.6 34.6177C26.8649 34.6177 27.119 34.6584 27.3576 34.7336C27.7108 34.8447 28.0964 34.7518 28.36 34.4918C28.6237 34.2319 28.7221 33.8476 28.616 33.493C28.5409 33.2421 28.5 32.9743 28.5 32.6946C28.5 31.1837 29.6878 30.0023 31.1 30.0023Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M41.29 6.71233C40.1557 5.57801 38.3882 5.90843 37.3649 6.93177C36.5339 7.7628 35.24 9.21301 35.0038 11.9153C34.9672 12.334 35.1964 12.731 35.5774 12.9086C35.9584 13.0863 36.4099 13.0067 36.7071 12.7094C37.508 11.9085 38.2273 11.6494 38.8578 11.4971C39.0149 11.4592 39.1655 11.4282 39.3258 11.3951L39.3624 11.3876C39.5285 11.3534 39.7148 11.3146 39.8942 11.2665C40.2491 11.1713 40.6975 11.0105 41.0706 10.6374C42.0939 9.61409 42.4243 7.84664 41.29 6.71233ZM38.7791 8.34598C39.2637 7.8614 39.7446 7.99532 39.8758 8.12654C40.007 8.25776 40.1409 8.73863 39.6563 9.22322C39.6519 9.22767 39.6068 9.27289 39.376 9.33481C39.2617 9.36545 39.1309 9.39329 38.9587 9.42878L38.9181 9.43714C38.7624 9.46917 38.5799 9.50672 38.3882 9.55302C38.1615 9.60778 37.9212 9.67496 37.67 9.76384C38.0151 9.13805 38.4305 8.69462 38.7791 8.34598Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M41.0668 17.0924C40.5259 16.0798 39.5195 14.4584 36.9456 13.6645C36.5584 13.545 36.1373 13.6711 35.8794 13.9837C35.6215 14.2964 35.5779 14.7337 35.7688 15.0912C36.2661 16.022 36.2872 16.7757 36.229 17.4444C36.2146 17.609 36.1957 17.7658 36.1757 17.9319L36.171 17.9706C36.1502 18.1429 36.1273 18.3354 36.1157 18.5228C36.0933 18.8844 36.0985 19.3804 36.3528 19.8563C37.0737 21.2059 38.7451 21.8025 40.1088 20.9604C41.4209 20.1502 41.7704 18.4095 41.0668 17.0924ZM38.2214 17.6179C38.2482 17.3099 38.2626 16.9713 38.2412 16.6045C38.7688 17.0818 39.0719 17.6027 39.3028 18.0347C39.564 18.5238 39.3751 19.0629 39.058 19.2587C38.7933 19.4221 38.3639 19.371 38.1195 18.9188C38.1165 18.9082 38.0998 18.8405 38.1119 18.6468C38.1194 18.5258 38.1351 18.3887 38.1566 18.21L38.1617 18.1679C38.1812 18.0063 38.204 17.8175 38.2214 17.6179Z" fill="#0C172F"/>
			<path d="M23.2677 23.3206C23.6583 23.7111 23.6583 24.3443 23.2677 24.7348L18.318 29.6846C17.9275 30.0751 17.2943 30.0751 16.9038 29.6846C16.5133 29.294 16.5133 28.6609 16.9038 28.2704L21.8535 23.3206C22.244 22.9301 22.8772 22.9301 23.2677 23.3206Z" fill="#0C172F"/>
			<path d="M26.021 21.9815C26.4116 21.591 26.4116 20.9578 26.021 20.5673C25.6305 20.1768 24.9973 20.1768 24.6068 20.5673L23.8997 21.2744C23.5092 21.665 23.5092 22.2981 23.8997 22.6886C24.2902 23.0792 24.9234 23.0792 25.3139 22.6886L26.021 21.9815Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24.682 16.2495L24.6948 16.2369C24.8051 16.1226 24.9427 16.0344 25.0982 15.9826L33.5835 13.1542C33.9428 13.0344 34.339 13.1279 34.6068 13.3957C34.8747 13.6636 34.9682 14.0597 34.8484 14.4191L32.02 22.9044C31.9681 23.0599 31.8799 23.1975 31.7657 23.3077L31.753 23.3206L19.7322 35.3414C19.3417 35.7319 18.7085 35.7319 18.318 35.3414L16.1967 33.2202L14.7348 34.6819C15.2675 35.797 15.0721 37.1732 14.1484 38.0968L12.0271 40.2182C10.8555 41.3897 8.95604 41.3897 7.78447 40.2182C6.6129 39.0466 6.6129 37.1471 7.78447 35.9755L9.90579 33.8542C10.8294 32.9306 12.2054 32.7351 13.3205 33.2677L14.7825 31.806L12.6611 29.6846C12.2706 29.294 12.2706 28.6609 12.6611 28.2704L24.682 16.2495ZM25.3891 18.3709L14.7825 28.9775L19.0251 33.2201L29.6317 22.6135L25.3891 18.3709ZM11.32 35.2684C11.7105 34.8779 12.3437 34.8779 12.7342 35.2684C13.1247 35.6589 13.1247 36.2921 12.7342 36.6826L10.6129 38.8039C10.2224 39.1945 9.58921 39.1945 9.19868 38.8039C8.80816 38.4134 8.80816 37.7803 9.19868 37.3897L11.32 35.2684ZM27.2657 17.3683L30.6343 20.7369L32.3186 15.684L27.2657 17.3683Z" fill="#0C172F"/>
		</svg>);
};
