/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ImageCard from "./ImageCard";
import { generateArticleLink } from "../helpers/linkGenerators";
import { getCategoryNameFromResource } from "../selectors/index.js";
import { getComputedCategoryColour } from "../selectors/index.js";
import * as cardStyles from "../styles/cardStyles";
class ArticleCard extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            categoryName: this.props.cardData ? getCategoryNameFromResource(this.props.cardData) : undefined,
            categoryColour: this.props.cardData ? getComputedCategoryColour(this.props.cardData) : undefined
        };
        this.onClick = () => {
            const data = {
                objectID: this.props.cardData.get("objectID"),
                position: this.props.cardData.get("searchPosition"),
                queryID: this.props.queryID
            };
            if (data.objectID && data.position && data.queryID) {
                window.aa && window.aa("click", data);
            }
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.cardData !== this.props.cardData) {
            this.setState({
                categoryName: nextProps.cardData ? getCategoryNameFromResource(nextProps.cardData) : undefined,
                categoryColour: nextProps.cardData ? getComputedCategoryColour(nextProps.cardData) : undefined
            });
        }
    }
    render() {
        const cardPrefix = this.props.cardPrefix ? this.props.cardPrefix : "article";
        const titleCSS = [
            cardStyles[`${cardPrefix}CardName`],
            !this.props.addBackground && cardStyles[`cellCardNameRemoveBg`]
        ];
        return (<ImageCard onClick={this.onClick} css={css(cardStyles[`${cardPrefix}Card`])} shouldDisplayGenreStrip={true} src={this.props.cardData.get("horizontal_cover_image")} title={this.props.cardData.get("title")} infoCSS={cardStyles[`${cardPrefix}CardInfo`]} titleCSS={titleCSS} autoPlay={this.props.cardData.autoPlay} playOnHover={this.props.cardData.playOnHover} imgCSS={cardStyles[`${cardPrefix}CardImage`]} href={generateArticleLink(this.props.cardData.get("slug"))} categoryColour={this.state.categoryColour} categoryName={this.state.categoryName} cardPrefix={cardPrefix}/>);
    }
}
export default ArticleCard;
