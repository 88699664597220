/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { Fragment } from "react";
import CarouselWrapper from "./CarouselWrapper";
import CarouselHeader from "./CarouselHeader";
import Carousel from "./Carousel";
import DataCard from "../containers/DataCardWithSubscription";
import { CLEVERTAP_ACTIONS, NUM_CATEGORY_CARDS_TO_DISPLAY_ON_HOME_PAGE, NUM_CATEGORY_CAROUSEL_TO_DISPLAY_ON_HOME_PAGE } from "../helpers/constants";
import { InsiderLink } from "./InsiderLink";
import { colors, mediaFeatures } from "../styles/common";
import { generateSEOCategoryLink, getItemUrl } from "../helpers/linkGenerators";
import { carouselIconImageStyle } from "../styles/modules/carouselStyles";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
import { filterItemsByCity, getCards, sendClevertapEvent } from "../helpers/misc";
import { getCategoryIcon } from "./CategoryIconMap";
import ImageBanner from "./ui-lib/Molecules/Cards/ImageBanner";
import { categoryBanners, categoryBannersForIOs } from "../helpers/constants/homepageConfig";
import { isEmpty } from "lodash";
import { Map } from "immutable";
import { getOSFromUserAgent } from "../helpers/misc/common";
const carouselExtraLinkBtn = `
	background-color: ${colors["white"]};
	color: black;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid ${colors["gray"]};
	border-radius 5px;
	min-width: 66px;
`;
const HeightStyles = `
	ul {
		@media screen and (max-width: 375px) {
			height: 108vw;
		}
		@media screen and (max-width: 350px) {
			height: 115vw;
		}
		@media screen and (max-width: 300px) {
			height: 125vw;
		}
	}
`;
const BannerImageCss = `
	width: 100%;
	border-radius: 30px;
	margin-bottom: 30px;
	@media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
		border-radius: 15px;
        margin-bottom: 15px;
    }
`;
const CategoryCarouselViewMoreHandler = (city, categoryName) => {
    sendClevertapEvent(CLEVERTAP_ACTIONS.CAROUSEL_VIEW_ALL_CLICKED, {
        carousel_name: `Category Carousel: ${categoryName}`,
        city: city
    });
};
class MultipleCategoryCarousels extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleResize = () => {
            this.setState({
                isMobile: window.innerWidth < 560 // isMobile check to toggle between desktop and mobile images for the image banner
            });
        };
        this.eventCards = (events, category, multipleCategoryCarouselClickedEvent, categoryCardsNumber) => {
            const dataCards = events
                .take(categoryCardsNumber)
                .valueSeq()
                .map((card, idx) => {
                return (<li className="carousel-item carousel-item-right" key={`category-carousel-card-${idx}`} onClick={multipleCategoryCarouselClickedEvent(idx + 1, card.get("name"), category)}>
						<DataCard key={`category-${idx}`} cardData={card}/>
					</li>);
            });
            return dataCards;
        };
        this.sendClevertap = (payload) => sendClevertapEvent(CLEVERTAP_ACTIONS.CATEGORY_HIGHLIGHT_CLICKED, payload);
        this.state = {
            mounted: false,
            isMobile: window.innerWidth < 560
        };
    }
    componentDidMount() {
        this.setState({ mounted: true });
        window.addEventListener("resize", this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    render() {
        const { cards, multipleCategoryCarouselClickedEvent, city, allCards } = this.props;
        const cardsToDisplay = getCards(cards, allCards);
        const { mounted, isMobile } = this.state;
        const bannersInCurrentCity = filterItemsByCity(categoryBanners, city);
        const device = getOSFromUserAgent();
        if (cardsToDisplay.size > 0) {
            return cardsToDisplay.take(NUM_CATEGORY_CAROUSEL_TO_DISPLAY_ON_HOME_PAGE).map((card, idx) => {
                const categoryName = card.get("name", "all-events");
                const categoryLink = generateSEOCategoryLink(city, categoryName);
                const categoryId = card
                    .get("events")
                    .get(0)
                    .getIn(["category_id", "_id"]);
                const CategoryIconComponent = () => {
                    return <div css={css(carouselIconImageStyle)}> {getCategoryIcon(categoryId)}</div>;
                };
                const showViewAll = !(city !== "online" && card.get("events").find(c => c.get("event_type") === "online"));
                let bannerDetails = !isEmpty(bannersInCurrentCity) && bannersInCurrentCity.find(banner => banner.categoryId === categoryId);
                const hideBannerForIOs = bannerDetails && bannerDetails.hideForIOS;
                const showDifferentBannerForIOs = bannerDetails && bannerDetails.showForIOS;
                if (device === "ios") {
                    if (hideBannerForIOs) {
                        bannerDetails = null;
                    }
                    if (showDifferentBannerForIOs) {
                        const bannersInCurrentCity = filterItemsByCity(categoryBannersForIOs, city);
                        bannerDetails =
                            !isEmpty(bannersInCurrentCity) && bannersInCurrentCity.find(banner => banner.categoryId === categoryId);
                    }
                }
                const bannerImage = bannerDetails && (isMobile ? bannerDetails.mobileImage : bannerDetails.desktopImage);
                const bannerLink = bannerDetails && getItemUrl(new Map(bannerDetails.link), city);
                return (<CarouselWrapper className="card-list-wrapper card-carousel" key={`category-carousel-${idx}`}>
						<CarouselHeader>
							<CarouselTitleWithIcon title={card.get("name")} IconComponent={CategoryIconComponent} showViewAll={showViewAll}/>
							{showViewAll && (<InsiderLink isSPA={true} href={categoryLink} onClick={() => CategoryCarouselViewMoreHandler(city, categoryName)}>
									<button css={css(carouselExtraLinkBtn)}>View all</button>
								</InsiderLink>)}
						</CarouselHeader>

						{mounted && bannerDetails ? (<div className="container">
								<ImageBanner imageCss={BannerImageCss} image={bannerImage} link={bannerLink} openInNewTab={bannerDetails.link.resource_type === "external"} onClick={() => this.sendClevertap({ link: bannerLink, image: bannerImage, city, categoryName })}/>
							</div>) : (<Fragment />)}

						<div css={css(HeightStyles)}>
							<Carousel numCarouselItems={3} listType="card">
								{this.eventCards(card.get("events"), card.get("name"), multipleCategoryCarouselClickedEvent, NUM_CATEGORY_CARDS_TO_DISPLAY_ON_HOME_PAGE)}
							</Carousel>
						</div>
					</CarouselWrapper>);
            });
        }
        else {
            return null;
        }
    }
}
export default MultipleCategoryCarousels;
