import { colors, mediaFeatures } from "../common";
export const carouselHeaderTitleStyle = `
    display: flex;
    align-items: center;
    justify-content: start;
`;
export const carouselIconStyle = `
    height: 24px;
    width: 24px;
    @media screen and ${mediaFeatures["for-tablet"]} {
        height: 32px;
        width: 32px;
    }
`;
export const carouselIconImageStyle = `
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and ${mediaFeatures["for-tablet"]} {
        height: 32px;
        width: 32px;
    }
`;
export const headerWrapperStyle = `
    display: flex;
    flex-direction: column;
`;
export const subHeaderStyles = `
    margin-bottom: 0px;
    color: ${colors.subTextGrey};
    font-weight: 500;
    line-height: 150%;
    text-wrap: balance;
`;
