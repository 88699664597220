import { createSelector } from "reselect";
import { getEventSlug, getLoginModalState, getUserData } from "./index";
import { List, Map } from "immutable";
const formatEvent = (event, isPastEvent) => {
    return new Map({
        _id: event.get("_id"),
        min_show_start_time: event.getIn(["venues", "0", "shows", "0", "start_utc_timestamp"]) || 0,
        name: event.get("name"),
        type: event.get("type"),
        slug: event.get("slug"),
        horizontal_cover_image: event.get("horizontal_cover_image"),
        vertical_cover_image: event.get("vertical_cover_image"),
        tags: event.get("tags"),
        city: event.get("city"),
        venue_name: event.getIn(["venues", "0", "name"]),
        venue_date_string: event.getIn(["venues", "0", "date_string"]),
        is_rsvp: event.get("is_rsvp"),
        category_id: event.get("category_id"),
        event_state: isPastEvent ? "expired" : event.get("event_state"),
        price_display_string: event.get("price_display_string"),
        model: "event"
    });
};
/**
 * get active favourited events data
 * @param state
 * @param ownprops
 * @return {boolean}
 */
export const getActiveFavouriteEvents = createSelector([getUserData], user => {
    var events = user.getIn(["favourites", "active"]) || new List([]);
    // format event as necessary
    events.size > 0 && (events = events.map(e => formatEvent(e)));
    return events;
});
/**
 * get past favourited events data
 * @param state
 * @param ownprops
 * @return {boolean}
 */
export const getPastFavouriteEvents = createSelector([getUserData], user => {
    var events = user.getIn(["favourites", "past"]) || new List([]);
    // format event as necessary
    events.size > 0 && (events = events.map(e => formatEvent(e, true)));
    return events;
});
/**
 * has fetched favourites for the first time
 * @param state
 * @param ownprops
 * @return {boolean}
 */
export const hasFetchedFavouriteEvents = createSelector([getUserData], user => {
    return user.getIn(["favourites"]).size > 0;
});
/**
 * has the user favourited this event
 * @param state
 * @param ownprops
 * @return {boolean}
 */
export const isFavouritedEvent = createSelector([getUserData, getEventSlug], (user, eventSlug) => {
    const favourites = user.getIn(["favourites", "slugs"]) || new List([]);
    return favourites.findIndex(slug => slug === eventSlug) > -1;
});
/**
 * checks whether any backend data fetches are happening
 * @param state
 * @return {string}
 */
export const isFetchingFavouritesData = createSelector([getUserData], user => {
    return user.getIn(["requestingActiveFavouritesData"]) || user.getIn(["requestingPastFavouritesData"]);
});
/**
 * favourites modal source, either "edp" or "navbar"
 * @param state
 * @return {string}
 */
export const loginModalSource = createSelector([getLoginModalState], modal => modal.get("source", ""));
