import { BackgroundColor, BaseBold, Display, FontColor, LargeBold, Uppercase } from "../Typography/styles";
const ButtonContainer = `
    display: flex;
    padding: 6px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 9px;
    line-height: 36px !important;
`;
const SmallButtonContainer = `
    display: flex;
    padding: 6px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 9px;
    line-height: 24px !important;
`;
export const PrimaryButton = [
    ButtonContainer,
    BackgroundColor("--Brand-Primary---Navy"),
    Display,
    LargeBold,
    FontColor("--Grey-Text-White"),
    Uppercase
];
export const MutedPrimaryButton = [
    ButtonContainer,
    BackgroundColor("--Brand-Primary---Navy"),
    Display,
    LargeBold,
    FontColor("--Grey-Text-White"),
    Uppercase,
    "opacity: 0.45; cursor: unset;"
];
export const SmallPrimaryButton = [
    SmallButtonContainer,
    BackgroundColor("--Brand-Primary---Navy"),
    Display,
    BaseBold,
    FontColor("--Grey-Text-White"),
    Uppercase
];
export const SmallSecondaryLightButton = [
    SmallButtonContainer,
    BackgroundColor("--Background-BG-SecBTN-Light"),
    Display,
    BaseBold,
    FontColor("--Brand-Primary---Navy"),
    Uppercase
];
export const MutedErrorButton = [
    ButtonContainer,
    BackgroundColor("--Comm-Error-Light"),
    Display,
    LargeBold,
    FontColor("--Comm-Negative"),
    Uppercase
];
export const ErrorButton = [
    ButtonContainer,
    BackgroundColor("--Comm-Negative"),
    Display,
    LargeBold,
    FontColor("--Grey-Text-White"),
    Uppercase
];
export const whitePrimaryButton = [
    ButtonContainer,
    BackgroundColor("--Background-BG-SecBTN-Light"),
    Display,
    LargeBold,
    FontColor("--grey-highlight-copy"),
    Uppercase,
];
export const mutedWhitePrimaryButton = [
    ButtonContainer,
    BackgroundColor("--Background-BG-SecBTN-Light"),
    Display,
    LargeBold,
    FontColor("--Grey-Inactive"),
    Uppercase,
];
