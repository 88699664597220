/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "../../../../styles/common";
/**
 * Renders an image based on the provided source URL.
 *
 * @param {string} src - The source URL of the image.
 * @param {string} style - Additional CSS styles for the image.
 * @param {string} alt - The alternative text for the image.
 * @returns {ReactElement} Image component.
 */
const Image = ({ src, style = "", alt = "" }) => {
    return <img src={src} alt={alt} css={css([imgStyle, style])}/>;
};
export default Image;
const imgStyle = `
    background: ${colors["lighter-gray"]};
`;
