/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";
import ScheduleCard from "../ui-lib/Molecules/Cards/ScheduleCard";
import ProgressIndicator from "../ui-lib/Atoms/ProgressIndicator";
import mediaQueries from "../ui-lib/Atoms/MediaQueries";
import { format } from "date-fns";
const ScheduleCardList = ({ items, loading, overflowLink, accentColor, lightMode = false, openInNewTab = false, onClick = () => { } }) => {
    const maxCards = 6;
    return (<Fragment>
			<div css={css(Container)} id="homepage-showcase-cards">
				{items.slice(0, maxCards).map((item, index) => {
            const timestamp = item.get("start_date");
            const date = new Date(timestamp * 1000);
            const formattedDate = format(date, "do");
            const formattedMonth = format(date, "MMMM");
            const city = item.get("city");
            const slug = item.get("slug");
            return (<ScheduleCard index={index + 1} maxCards={maxCards} totalCount={items.size} date={formattedDate} month={formattedMonth} city={city} loading={loading} slug={slug} overflowLink={overflowLink} accentColor={accentColor} lightMode={lightMode} openInNewTab={openInNewTab} onClick={onClick}/>);
        })}
			</div>
			<ProgressIndicator componentId="homepage-showcase-cards" accentColor={accentColor} lightMode={lightMode}/>
		</Fragment>);
};
export default ScheduleCardList;
const Container = `
    display: flex;
    align-items: center;
    align-content: center;
    gap: 15px;
    overflow: auto hidden;
    align-self: stretch;
	// to hide the scroll bar but keep the functionality
	&::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	  }

	${mediaQueries("mobileAndTablet")} {
		padding: 0 30px;
	}

	${mediaQueries("largeDesktop")} {
		padding: 0 60px;
	}

	${mediaQueries("extraLargeDesktop")}{
		gap: 30px;
	}
`;
