import React from "react";
export const Workshops = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M27 10C26.4477 10 26 10.4477 26 11C26 11.5523 26.4477 12 27 12H33.2609C33.8132 12 34.2609 11.5523 34.2609 11C34.2609 10.4477 33.8132 10 33.2609 10H27Z" fill="#0C172F"/>
			<path d="M26 15C26 14.4477 26.4477 14 27 14H35C35.5523 14 36 14.4477 36 15C36 15.5523 35.5523 16 35 16H27C26.4477 16 26 15.5523 26 15Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16ZM16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16C20 18.2091 18.2091 20 16 20Z" fill="#0C172F"/>
			<path d="M10 29C10 25.6863 12.6863 23 16 23C18.9445 23 21.3937 25.1211 21.9028 27.9187C24.2333 27.4937 26 25.4532 26 23C26 22.4477 26.4477 22 27 22C27.5523 22 28 22.4477 28 23C28 26.866 24.866 30 21 30C20.4477 30 20 29.5523 20 29C20 26.7909 18.2091 25 16 25C13.7909 25 12 26.7909 12 29C12 29.5523 11.5523 30 11 30C10.4477 30 10 29.5523 10 29Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9.6511 6C7.62342 6 6 7.6481 6 9.67247V30.3275C6 32.3519 7.62342 34 9.6511 34H38.3489C40.3766 34 42 32.3519 42 30.3275V9.67247C42 7.6481 40.3766 6 38.3489 6H9.6511ZM8 9.67247C8 8.7375 8.7431 8 9.6511 8H38.3489C39.2569 8 40 8.7375 40 9.67247V30.3275C40 31.2625 39.2569 32 38.3489 32H9.6511C8.7431 32 8 31.2625 8 30.3275V9.67247Z" fill="#0C172F"/>
			<path d="M11 42L37 42C37.5523 42 38 41.5523 38 41C38 40.4477 37.5523 40 37 40H33.0026L33 40H31C30.4477 40 30 39.5523 30 39V37C30 36.4477 29.5523 36 29 36C28.4477 36 28 36.4477 28 37V39C28 39.5523 27.5523 40 27 40H21C20.4477 40 20 39.5523 20 39V37C20 36.4477 19.5523 36 19 36C18.4477 36 18 36.4477 18 37V39C18 39.5523 17.5523 40 17 40H11C10.4477 40 10 40.4477 10 41C10 41.5523 10.4477 42 11 42Z" fill="#0C172F"/>
		</svg>);
};
