/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment, useEffect, useState } from "react";
import SmallEventCard from "../ui-lib/Molecules/Cards/SmallEventCard";
import { range } from "lodash";
import mediaQueries from "../ui-lib/Atoms/MediaQueries";
import ProgressIndicator from "../ui-lib/Atoms/ProgressIndicator";
import { ChevronRight } from "../ui/icons/ChevronRight";
import { ChevronLeft } from "../ui/icons/ChevronLeft";
import { format } from "date-fns";
import { colors } from "../ui-lib/Atoms/Typography/styles";
const SmallEventCardList = ({ items, overflowIcon, loading, overflowLink, accentColor, overflowLabel, lightMode = false, openInNewTab = false, onClick = () => { } }) => {
    const totalCount = items.size;
    const maxCards = Math.min(6, totalCount);
    const cardsInView = 2;
    const [startIndex, setStartIndex] = useState(0);
    const [showCarousel, setShowCarousel] = useState(window.innerWidth >= 1024 && totalCount > cardsInView);
    const handleResize = () => {
        setShowCarousel(window.innerWidth >= 1024 && totalCount > cardsInView);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handlePrev = () => {
        setStartIndex(Math.max(0, startIndex - cardsInView));
    };
    const handleNext = () => {
        let newIndex = startIndex + cardsInView;
        if (newIndex >= maxCards) {
            newIndex = startIndex;
        }
        setStartIndex(newIndex);
    };
    const getActiveIndex = () => Math.floor(startIndex / cardsInView);
    return (<Fragment>
			<div css={css([Container, showCarousel && MinWidth])} id="homepage-showcase-cards">
				{items
            .slice(startIndex, showCarousel ? startIndex + cardsInView : maxCards)
            .map((event, index) => {
            const timestamp = event.get("start_date");
            const date = new Date(timestamp * 1000);
            const formattedDate = format(date, "do MMMM");
            const cardIndex = startIndex + index + 1;
            return (<SmallEventCard index={cardIndex} maxCards={6} totalCount={totalCount} image={event.get("horizontal_cover_image")} eventName={event.get("name")} date={formattedDate} city={event.get("city")} slug={event.get("slug")} overflowIcon={overflowIcon} overflowLink={overflowLink} accentColor={accentColor} overflowLabel={overflowLabel} openInNewTab={openInNewTab} onClick={onClick} eventState={event.get("event_state")}/>);
        })}
			</div>
			{showCarousel ? (<div css={css(NavigatorContainer)}>
					<button css={css(ButtonWithIcon(lightMode))} onClick={handlePrev}>
						<ChevronLeft fill={lightMode ? colors["--Grey-Text-White"] : undefined}/>
					</button>
					<div css={css(ProgressIndicatorContainer)}>
						{range(maxCards / cardsInView).map(index => (<div key={index} css={css(HorizontalLine(index === getActiveIndex(), accentColor))}/>))}
					</div>
					<button css={css(ButtonWithIcon(lightMode))} onClick={handleNext}>
						<ChevronRight fill={lightMode ? colors["--Grey-Text-White"] : undefined} strokeWidth=".8"/>
					</button>
				</div>) : (<ProgressIndicator componentId="homepage-showcase-cards" accentColor={accentColor} lightMode={lightMode}/>)}
		</Fragment>);
};
export default SmallEventCardList;
const Container = `
    display: flex;
    align-items: center;
    align-content: center;
    gap: 15px;
    overflow: auto hidden;
    align-self: stretch;
	padding-left: 10px;

	// to hide the scroll bar but keep the functionality
	&::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	  }

	${mediaQueries("mobileAndTablet")} {
		padding: 0 30px;
	}

	${mediaQueries("extraLargeDesktop")}{
		gap: 30px;
	}
`;
const MinWidth = `
    justify-content: flex-start;
    min-width: 630px;
`;
const HorizontalLine = (active, accentColor) => `
	width: 30px;
	height: 3px;
	background-color: ${active ? (accentColor ? accentColor : "#31C0F0") : "#E1E3E6"};
	border-radius: 2px;
`;
const NavigatorContainer = `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    align-self: stretch;
`;
const ProgressIndicatorContainer = `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;
const ButtonWithIcon = (lightMode) => `
	display: flex;
	padding: 6px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 12px;
	background: ${lightMode ? colors["--Brand-Primary---Navy"] : colors["--Background-BG-SecBTN-Light"]};
`;
