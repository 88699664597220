/** @jsx jsx */
import { jsx, css } from "@emotion/core";
// containers
import InsiderLinkWithActiveOverride from "../containers/InsiderLinkWithOverrideWithSubscription";
//helpers
import { pluralize } from "../helpers/constants";
import { colors, mediaFeatures } from "../styles/common";
import { getCategoryIcon } from "./CategoryIconMap";
// Emotion JS
const CategoryCardImageWrapperStyles = `
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 78px;
	@media screen and ${mediaFeatures["for-small-mobile"]} {
		width: 64px;
	}
	@media screen and ${mediaFeatures["for-small-tablet-only"]} {
    	width: 105px;
	}
	@media screen and ${mediaFeatures["for-large-tablet"]} {
    	width: 80px;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
    	width: 105px;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
    	width: 125px;
	}
`;
const CategoryCardImageStyles = `
    display: flex;
    align-items: center;
    justify-content: center;
	border: 1px solid ${colors["slightly-lighter-gray"]};
	border-radius: 9px;
	img {
		display: block;
		width: 48px;
		height: auto;
	}
	height: 78px;
	width: 78px;
	@media screen and ${mediaFeatures["for-small-mobile"]} {
		height: 64px;
		width: 64px;
	}
	@media screen and ${mediaFeatures["for-small-tablet-only"]} {
		height: 105px;
    	width: 105px;
		img {
			width: 64px;
		}
	}
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		height: 80px;
    	width: 80px;
		img {
			width: 48px;
		}
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		height: 105px;
    	width: 105px;
		img {
			width: 64px;
		}
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		height: 125px;
    	width: 125px;
		img {
			width: 64px;
		}
	}
`;
const CategoryCardNameStyles = `
	span {
		display: block;
		text-align: center;
	}
`;
const CategoryNameStyles = `
	margin-top: 12px;
	margin-bottom: 3px;
	font-size: 14px;
	font-weight: 500;
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 16px;
		font-weight: 500;
	}
	color: ${colors.black};
`;
const CategoryEventCountStyles = `
	font-size: 11px;
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 14px;
	}
	color: ${colors.black};
	opacity: 0.6;
`;
const WithIconLink = ({ activeClassName, activeOverride, children, className, href, matchWithoutQueryParams }) => {
    if (href) {
        return (<InsiderLinkWithActiveOverride activeClassName={activeClassName} activeOverride={activeOverride} className={className} href={href} matchWithoutQueryParams={matchWithoutQueryParams}>
				{children}
			</InsiderLinkWithActiveOverride>);
    }
    return <div>{children}</div>;
};
const IconAndTextCard = ({ activeClassName, activeOverride, categoryEventCount, categoryName, className, href, matchWithoutQueryParams, text, categoryId }) => {
    return (<WithIconLink activeClassName={activeClassName} activeOverride={activeOverride} className={className} href={href} matchWithoutQueryParams={matchWithoutQueryParams}>
			<div css={css(CategoryCardImageWrapperStyles)}>
				<div css={css(CategoryCardImageStyles)}>{categoryId && getCategoryIcon(categoryId)}</div>
				{text.length > 0 && (<div css={css(CategoryCardNameStyles)}>
						<span css={css(CategoryNameStyles)}>{categoryName}</span>
						<span css={css(CategoryEventCountStyles)}>{pluralize(parseInt(categoryEventCount), `event`)}</span>
					</div>)}
			</div>
		</WithIconLink>);
};
export default IconAndTextCard;
