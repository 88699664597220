import { connect } from "react-redux";
import { fetchModelDataForTag } from "../actions/index";
import { getGoPageEvents, getGoPageLoading } from "../selectors/go";
import Showcase from "../components/Showcase";
import { getSelectedCityName } from "../selectors";
import { filterItemsByCity } from "../helpers/misc";
import { showcase } from "../helpers/constants/homepageConfig";
const mapStateToProps = (state) => {
    var _a;
    const selectedCity = getSelectedCityName(state);
    const showcaseInCurrentCity = filterItemsByCity(showcase, selectedCity);
    const tag = showcaseInCurrentCity && ((_a = showcaseInCurrentCity[0]) === null || _a === void 0 ? void 0 : _a.tag);
    return {
        events: getGoPageEvents(state, { params: { slug: tag } }),
        loading: getGoPageLoading(state, { params: { slug: tag } }),
        showcaseInCurrentCity,
        selectedCity
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchGoPage: (slug) => dispatch(fetchModelDataForTag({ tagSlugs: [slug], models: ["event"] }))
});
const ShowcaseWithSubscription = connect(mapStateToProps, mapDispatchToProps)(Showcase);
export default ShowcaseWithSubscription;
