import React from "react";
export const Adventure = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23.4743 10.1494C23.1795 10.3316 23 10.6534 23 11V16.9799C22.9997 16.9938 22.9997 17.0077 23 17.0217V19.5858L18 24.5858L15.7071 22.2929C15.3166 21.9024 14.6834 21.9024 14.2929 22.2929L8.58584 28H7C6.44772 28 6 28.4477 6 29C6 29.5523 6.44772 30 7 30L41 30C41.1369 30 41.2673 29.9725 41.3861 29.9228C41.4983 29.8758 41.6037 29.8076 41.6961 29.718C41.7035 29.7108 41.7108 29.7035 41.718 29.6961C41.8925 29.5161 42 29.2706 42 29C42 28.8648 41.9732 28.7359 41.9245 28.6182C41.8757 28.4999 41.8033 28.389 41.7071 28.2929L33.7071 20.2929C33.3166 19.9024 32.6834 19.9024 32.2929 20.2929L29 23.5858L25 19.5858V17.618L30.4472 14.8944C30.786 14.725 31 14.3788 31 14C31 13.6212 30.786 13.275 30.4472 13.1056L24.4472 10.1056C24.1372 9.95058 23.7691 9.96714 23.4743 10.1494ZM38.5858 28L33 22.4142L30.4142 25L33.4142 28H38.5858ZM30.5858 28L24 21.4142L18.7071 26.7071C18.3166 27.0976 17.6834 27.0976 17.2929 26.7071L15 24.4143L11.4143 28L30.5858 28ZM25 12.618L27.7639 14L25 15.382V12.618Z" fill="#0C172F"/>
			<path d="M27 32.9999C27 32.4477 27.4477 32 28 32L33 32C33.5523 32 34 32.4477 34 33C34 33.5523 33.5523 34 33 34L28 34C27.4477 34 27 33.5522 27 32.9999Z" fill="#0C172F"/>
			<path d="M8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20C8 20.5523 8.44772 21 9 21C9.55228 21 10 20.5523 10 20C10.5523 20 11 19.5523 11 19C11 18.4477 10.5523 18 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18Z" fill="#0C172F"/>
			<path d="M34 14C34 13.4477 34.4477 13 35 13C35 12.4477 35.4477 12 36 12C36.5523 12 37 12.4477 37 13C37.5523 13 38 13.4477 38 14C38 14.5523 37.5523 15 37 15C37 15.5523 36.5523 16 36 16C35.4477 16 35 15.5523 35 15C34.4477 15 34 14.5523 34 14Z" fill="#0C172F"/>
			<path d="M15 32C14.4477 32 14 32.4477 14 33C14 33.5522 14.4477 34 15 34L24 34C24.5523 34 25 33.5523 25 33C25 32.4477 24.5523 32 24 32L15 32Z" fill="#0C172F"/>
			<path d="M18 36.9999C18 36.4477 18.4477 35.9999 19 35.9999L29 35.9999C29.5523 35.9999 30 36.4476 30 36.9999C30 37.5522 29.5523 37.9999 29 37.9999L19 37.9999C18.4477 37.9999 18 37.5522 18 36.9999Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M20 17C20 15.3431 18.6569 14 17 14C15.3431 14 14 15.3431 14 17C14 18.6569 15.3431 20 17 20C18.6569 20 20 18.6569 20 17ZM17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16Z" fill="#0C172F"/>
		</svg>);
};
