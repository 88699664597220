/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { colors, mediaFeatures } from "../styles/common";
const IconWrapperStyle = `
    padding: 7px;
    background: white;
    border: 1px solid ${colors.faintGrey};
    border-radius: 100%;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${mediaFeatures["for-mobile"]} {
        padding: 6px;
        margin-right: 9px;
    }
`;
const IconWrapper = (props) => {
    return (<div css={css(IconWrapperStyle)}>
           {props.children}
        </div>);
};
export default IconWrapper;
