export const icon = `
    display: block;
    position: relative;
    background-size: 20px auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    overflow: hidden;
    content: "";
    &::before {
        content: "";
        display: inline-block;
        position: relative;
        vertical-align: middle;
        text-align: center;
        background-size: 14px auto;
        background-repeat: no-repeat;
        background-position: center center;
    }
`;
export const iconButtonLarge = `
    ${icon}
    cursor: pointer;
    &::before{
        cursor: pointer;
    }
    &.menu{
        width: 200px;
        height: 32px;
        font-size: 28px;
        font-weight: 900;
        line-height: 1.11;
        letter-spacing: -0.3px;
        color: #373435;
    }
`;
export const triangleArrow = `
    &::after{
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        border: 8px solid transparent;
    }
`;
