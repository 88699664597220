import React from "react";
export const Screening = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24 20C26.2091 20 28 18.2091 28 16C28 13.7909 26.2091 12 24 12C21.7909 12 20 13.7909 20 16C20 18.2091 21.7909 20 24 20ZM24 18C25.1046 18 26 17.1046 26 16C26 14.8954 25.1046 14 24 14C22.8954 14 22 14.8954 22 16C22 17.1046 22.8954 18 24 18Z" fill="#0C172F"/>
			<path d="M25.3333 24.0001C25.3333 24.7365 24.7363 25.3334 24 25.3334C23.2636 25.3334 22.6666 24.7365 22.6666 24.0001C22.6666 23.2637 23.2636 22.6667 24 22.6667C24.7363 22.6667 25.3333 23.2637 25.3333 24.0001Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M28 32C28 34.2091 26.2091 36 24 36C21.7909 36 20 34.2091 20 32C20 29.7909 21.7909 28 24 28C26.2091 28 28 29.7909 28 32ZM26 32C26 33.1046 25.1046 34 24 34C22.8954 34 22 33.1046 22 32C22 30.8954 22.8954 30 24 30C25.1046 30 26 30.8954 26 32Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M32 28C29.7909 28 28 26.2091 28 24C28 21.7909 29.7909 20 32 20C34.2091 20 36 21.7909 36 24C36 26.2091 34.2091 28 32 28ZM32 26C30.8954 26 30 25.1046 30 24C30 22.8954 30.8954 22 32 22C33.1046 22 34 22.8954 34 24C34 25.1046 33.1046 26 32 26Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M16 28C13.7909 28 12 26.2091 12 24C12 21.7909 13.7909 20 16 20C18.2091 20 20 21.7909 20 24C20 26.2091 18.2091 28 16 28ZM16 26C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24C18 25.1046 17.1046 26 16 26Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24C42 33.9411 33.9411 42 24 42C23.7284 42 23.4581 41.994 23.1895 41.9821C23.1281 41.9938 23.0648 42 23 42H7C6.44772 42 6 41.5523 6 41C6 40.4477 6.44772 40 7 40H15.7456C9.95688 37.0075 6 30.9659 6 24ZM24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8Z" fill="#0C172F"/>
		</svg>);
};
