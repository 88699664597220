/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import CarouselHeader from "../components/CarouselHeader";
import CarouselWrapper from "../components/CarouselWrapper";
import DigitalEventsCarouselItem from "./DigitalEventsCarouselItem";
import queryString from "query-string";
import { DigitalEventsIcon } from "./ui/icons/HomePageIcons/DigitalEventsIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
import { getItemUrl } from "../helpers/linkGenerators";
//Grid list size will be max 6 (managed from BE)
const gridStyle = (size) => css `
	display: flex;
	padding: 0;
	justify-content: ${size <= 6 ? `space-between` : `flex-start`};
	margin: 0 auto;
	flex-wrap: wrap;
`;
const renderItem = (item, listSize, city, isFromApp, platform) => {
    const url = item.get("url");
    let cutIndex = url.indexOf("?");
    const params = queryString.parse(url.slice(cutIndex));
    const itemUrl = getItemUrl(item, city);
    return (<DigitalEventsCarouselItem url={itemUrl} imageUrl={item.get("thumbnail_url")} key={params.tagSlug} listSize={listSize} tag={params.tagSlug} resource_type={item.get("resource_type")} title={item.get("title")} isFromApp={isFromApp} platform={platform}/>);
};
const DigitalEventsCarousel = (props) => {
    const { carouselItems, selectedCity, isFromApp, platform } = props;
    return carouselItems && carouselItems.size > 0 ? (<div style={{ marginTop: 30 }}>
			<CarouselWrapper className="digital-events-grid">
				<CarouselHeader className="top-header">
					<CarouselTitleWithIcon title={"FIND NEW EXPERIENCES"} IconComponent={DigitalEventsIcon} subHeader={"Explore. Discover. Make a Plan"}/>
				</CarouselHeader>
				<div className="container">
					<ul css={gridStyle(carouselItems.size)}>
						{carouselItems.map(item => {
            return renderItem(item, carouselItems.size, selectedCity, isFromApp, platform);
        })}
					</ul>
				</div>
			</CarouselWrapper>
		</div>) : null;
};
export default DigitalEventsCarousel;
