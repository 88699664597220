import { mediaFeatures } from "../common";
import { containerWidth, narrowContainerWidth } from "./functions";
export const container = `
    margin-left: auto;
    margin-right: auto;

    @media screen and ${mediaFeatures["for-mobile"]} {
        width: ${containerWidth("mobile")}vw;
    }
    @media screen and ${mediaFeatures["for-tablet-only"]} {
        width: ${containerWidth("tablet")}vw;
    }
    @media screen and ${mediaFeatures["for-pc-only"]} {
        width: ${containerWidth("pc")}vw;
    }
    @media screen and ${mediaFeatures["for-hd"]} {
        width: ${containerWidth("hd")}px;
    }
`;
export const narrowContainer = `
    margin-left: auto;
    margin-right: auto;

    @media screen and ${mediaFeatures["for-mobile"]} {
       width: ${containerWidth("mobile")}vw;
    }

    @media screen and ${mediaFeatures["for-tablet-only"]} {
        width: ${containerWidth("tablet")}vw;
    }

    @media screen and ${mediaFeatures["for-pc-only"]} {
        width: ${narrowContainerWidth("pc")}vw;
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        width: ${narrowContainerWidth("hd")}px;
    }
`;
