/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
// components
import GifVideo from "./GifVideo";
import LazyImage from "./LazyImage";
// helpers
import { imageCacheLink, imageCacheLinkSet } from "../helpers/linkGenerators";
import { isExternalHref } from "../helpers/misc";
const isGif = /\.gif$/;
const WithImageLink = ({ href, className, children }) => {
    if (href) {
        // External links should open in a new tab while internal ones should open in the same tab
        // However internal links will still cause a full page load (non spa)
        // This is because some links may look internal e.g. insider.in/nh7-weekender but
        // hosted externally. So we need to assume that any href is not available on spa
        return (<figure className={className}>
				{isExternalHref(href) ? (<a href={href} rel="noopener" target="_blank">
						{children}
					</a>) : (<a href={href}>{children}</a>)}
			</figure>);
    }
    return <figure className={className}>{children}</figure>;
};
const ImageCaption = ({ caption, captionCSS }) => {
    if (caption) {
        return <figcaption css={css(captionCSS)}>{caption}</figcaption>;
    }
    return null;
};
const ImageOrGif = ({ alt, autoPlay, caption, height, imgWidth, imgWidths, lazy, loop, onClick, optimize, playOnHover, sizeString, src, gifClassName, renderGifAsImage, dataRef }) => {
    const altString = caption || alt || "";
    const imgSource = optimize ? imageCacheLink(src, { w: imgWidth, autoplay: autoPlay, loop: loop }) : src;
    const srcSet = imageCacheLinkSet(src, imgWidths);
    if (isGif.test(src) && !renderGifAsImage) {
        // `tis but a gif
        return <GifVideo src={src} autoPlay={autoPlay} playOnHover={playOnHover} className={gifClassName}/>;
    }
    if (lazy) {
        return (<LazyImage src={imgSource} alt={altString} srcSet={srcSet} sizes={sizeString} height={height} onClick={onClick} data-ref={dataRef}/>);
    }
    return (<picture>
			{imgWidths &&
            imgWidths.map(imgWidth => {
                const sourceSrcSet = imageCacheLink(src, { w: imgWidth });
                return <source media={`(max-width: ${imgWidth}px)`} srcSet={sourceSrcSet}/>;
            })}
			<img data-testid="ad-banner-image" data-ref={dataRef} src={imgSource} alt={altString} srcSet={srcSet} sizes={sizeString} onClick={onClick} height={height} width={imgWidth}/>
		</picture>);
};
class Image extends React.Component {
    render() {
        const { alt, autoPlay, caption, captionCSS, className, gifClassName, gfyName, height, href, imgWidth, imgWidths, lazy, loop, onClick, optimize, playOnHover, sizeString, src, imgCSS, renderGifAsImage, dataRef } = this.props;
        return (<WithImageLink href={href} className={className} css={css(imgCSS)}>
				<ImageOrGif alt={alt} autoPlay={autoPlay} caption={caption} gfyName={gfyName} height={height} imgWidth={imgWidth} imgWidths={imgWidths} lazy={lazy} loop={loop} onClick={onClick} optimize={optimize} playOnHover={playOnHover} sizeString={sizeString} src={src} gifClassName={gifClassName} renderGifAsImage={renderGifAsImage} dataRef={dataRef}/>
				<ImageCaption caption={caption} captionCSS={captionCSS}/>
			</WithImageLink>);
    }
}
Image.defaultProps = {
    alt: "",
    captionCSS: "",
    className: "",
    imgWidths: [],
    lazy: true,
    optimize: true,
    renderGifAsImage: false
};
export default Image;
