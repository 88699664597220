/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// containers
import InsiderLinkWithActiveOverride from "../containers/InsiderLinkWithOverrideWithSubscription";
//helpers
import { pluralize } from "../helpers/constants";
import { colors, mediaFeatures } from "../styles/common";
const CategoryCardNameStyles = `
	span {
		display: block;
		text-align: center;
	}
`;
const CategoryNameStyles = `
	padding: 0px 6px;
	color: ${colors.black};
    font-size: 14px;
    font-weight: 500;
	@media screen and ${mediaFeatures["for-pc"]} {
        font-size: 16px;
        font-weight: 500;
	}
`;
const CategoryEventCountStyles = `
	color: ${colors.black};
	opacity: 0.6;
    font-size: 11px;
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 14px;
	}
`;
const WithTextCardLink = ({ activeClassName, activeOverride, children, className, href, matchWithoutQueryParams, doAutoLinkActivation, doExactMatch }) => {
    if (href) {
        return (<InsiderLinkWithActiveOverride activeClassName={activeClassName} activeOverride={activeOverride} className={className} href={href} matchWithoutQueryParams={matchWithoutQueryParams} doAutoLinkActivation={doAutoLinkActivation} doExactMatch={doExactMatch}>
                {children}
            </InsiderLinkWithActiveOverride>);
    }
    return <div>{children}</div>;
};
const TextCard = ({ activeClassName, activeOverride, className, href, matchWithoutQueryParams, categoryName, categoryEventCount, text, doAutoLinkActivation, doExactMatch }) => {
    return (<WithTextCardLink activeClassName={activeClassName} activeOverride={activeOverride} className={className} href={href} matchWithoutQueryParams={matchWithoutQueryParams} doAutoLinkActivation={doAutoLinkActivation} doExactMatch={doExactMatch}>
            {activeClassName ? (<span css={css(CategoryCardNameStyles)}>{text}</span>) : (<div css={css(CategoryCardNameStyles)}>
                        <span css={css(CategoryNameStyles)}>{categoryName}</span>
                        <span css={css(CategoryEventCountStyles)}>{pluralize(parseInt(categoryEventCount), `event`)}</span>
                    </div>)}
        </WithTextCardLink>);
};
export default TextCard;
