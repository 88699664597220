import React from "react";
import PicksCarousel from "../components/PicksCarousel";
import { connect } from "react-redux";
import { getCapitalizedSelectedCityName, getPicksData } from "../selectors/index";
import { PICKS_CAROUSEL_TITLE } from "../helpers/constants";
const mapStateToProps = state => {
    return {
        title: PICKS_CAROUSEL_TITLE,
        cards: getPicksData(state),
        selectedCity: getCapitalizedSelectedCityName(state)
    };
};
export default connect(mapStateToProps)(PicksCarousel);
