import React from "react";
export const InsiderIconWithGreyBg = () => {
    return (<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="60" height="60" rx="9" fill="#F3F3F5"/>
			<rect x="1" y="1" width="60" height="60" rx="9" stroke="#E7E8EA"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15.349 34.5781L19.0577 38.4485L27.0014 30.158L23.3339 26.3302C21.1391 24.1013 17.572 24.085 15.396 26.3373C13.2055 28.5854 13.1355 32.354 15.349 34.5781Z" fill="#EC1066"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M38.6544 34.5781L34.9459 38.4485L27.002 30.158L30.6697 26.3302C32.8645 24.1013 36.4316 24.085 38.6076 26.3373C40.7981 28.5854 40.8681 32.354 38.6544 34.5781Z" fill="#31C0F0"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24.9729 44.624C26.0196 45.6823 27.9539 45.7339 28.9895 44.664L34.9456 38.4483L27.0012 30.1582L19.0566 38.4496L24.9729 44.624Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M40.1925 24.6832C38.9073 23.3355 37.1873 22.4637 35.336 22.2794C35.3225 22.2781 35.309 22.2768 35.2954 22.2755C35.2214 22.2684 35.1521 22.2357 35.0996 22.1807C34.9746 22.0503 34.9746 21.8389 35.0996 21.7087L38.5819 18.0745C40.7131 15.7642 44.3241 15.8374 46.4782 18.1233V18.1235C48.6686 20.3714 48.7388 24.14 46.525 26.3643L43.0429 29.9986C42.9179 30.1288 42.7154 30.1288 42.5905 29.9984C42.538 29.9436 42.5064 29.8712 42.4996 29.794C42.4986 29.78 42.4973 29.7657 42.4958 29.7516C42.3192 27.8198 41.4839 26.0248 40.1925 24.6832Z" fill="#EC1066"/>
		</svg>);
};
