import React from "react";
export const Tour = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M21 14C20.4477 14 20 14.4477 20 15C20 15.5523 20.4477 16 21 16H33C33.5523 16 34 15.5523 34 15C34 14.4477 33.5523 14 33 14H21Z" fill="#0C172F"/>
			<path d="M14 15C14 14.4477 14.4477 14 15 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H15C14.4477 16 14 15.5523 14 15Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23 7.0007V10H12C11.5673 10 11.1462 10.1404 10.8 10.4L6.8 13.4C6.29639 13.7777 6 14.3705 6 15C6 15.6295 6.29639 16.2223 6.8 16.6L10.8 19.6C11.1462 19.8596 11.5673 20 12 20H36C37.1046 20 38 19.1046 38 18V12C38 10.8954 37.1046 10 36 10H25V7C25 6.44772 24.5523 6 24 6C23.4477 6 23 6.44841 23 7.0007ZM12 12H36V18H12L8 15L12 12Z" fill="#0C172F"/>
			<path d="M15 26C14.4477 26 14 26.4477 14 27C14 27.5523 14.4477 28 15 28H27C27.5523 28 28 27.5523 28 27C28 26.4477 27.5523 26 27 26H15Z" fill="#0C172F"/>
			<path d="M30 27C30 26.4477 30.4477 26 31 26H33C33.5523 26 34 26.4477 34 27C34 27.5523 33.5523 28 33 28H31C30.4477 28 30 27.5523 30 27Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23 32H12C10.8954 32 10 31.1046 10 30V24C10 22.8954 10.8954 22 12 22H36C36.4327 22 36.8538 22.1404 37.2 22.4L41.2 25.4C41.7036 25.7777 42 26.3705 42 27C42 27.6295 41.7036 28.2223 41.2 28.6L37.2 31.6C36.8538 31.8596 36.4327 32 36 32H25V41C25 41.5523 24.5523 42 24 42C23.4477 42 23 41.5523 23 41V32ZM36 30L40 27L36 24H12V30H36Z" fill="#0C172F"/>
		</svg>);
};
