import React from "react";
import { connect } from "react-redux";
// actions + selectors
import { rehydrateRecentlyViewed } from "../actions";
import { getActiveRecentlyViewedEvents, getRehydratedStatus } from "../selectors/recentlyViewed";
const mapStateToProps = (state) => ({
    recentlyViewedEvents: getActiveRecentlyViewedEvents(state),
    isRehydrated: getRehydratedStatus(state)
});
export const firstTimeDispatchProps = {
    rehydrateRecentlyViewed
};
export const noOpDispatchProps = {
    rehydrateRecentlyViewed: () => { }
};
const mapDispatchToProps = (dispatch) => ({
    rehydrateRecentlyViewed: () => dispatch(rehydrateRecentlyViewed())
});
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    if (!stateProps.isRehydrated) {
        // use default, which is the first time we have to
        // rehydrate recently viewed from local cache
        return Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), ownProps);
    }
    // if already rehydrated, then the rehydrate fn passed to child
    // can be a no-op
    return Object.assign(Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), noOpDispatchProps), ownProps);
};
export class RehydrateRecentlyViewed extends React.Component {
    componentDidMount() {
        this.props.rehydrateRecentlyViewed();
    }
    render() {
        return this.props.render({
            recentlyViewedEvents: this.props.recentlyViewedEvents
        });
    }
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RehydrateRecentlyViewed);
