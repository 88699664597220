import { clearfix } from "../../styles/base/clearfix";
import { container } from "../../styles/base/container";
import { medLetterSpacing } from "../../styles/base/padding";
import { medTransition } from "../../styles/base/transitions";
import { mobileSideGutter, tabletSideGutter } from "../../styles/base/variables";
import { topMost } from "../../styles/base/zIndex";
import { bodyFontSizes, colors, mediaFeatures, medPadding, smallFontSize, stripHeightMed, stripHeightMobile, titleFontSizes } from "../../styles/common";
import { disabledButton, primaryButton, primaryHoverDarken } from "../../styles/modules/buttons";
import { splitColors } from "../../styles/splitColors";
export const actionButtonWrapper = `
    ${clearfix}
    display: flex;
    flex-flow: column nowrap;
`;
export const actionButtonWrapperTop = `
    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        display: none;
    }
`;
export const actionButtonWrapperBottom = `
    ${container}
    display: flex;
    text-align: center;
    z-index: ${topMost} - 4;
    position: fixed;
    margin-top: calc(${mobileSideGutter}vw - 2%);
    bottom: 0;
    left: 0;
    padding: 2%;
    background-color: ${colors["white"]};
    border-top: 1px solid ${colors["lighter-gray"]};
    @media screen and ${mediaFeatures["for-mobile"]} {
        width: 100%;
        padding: 0;
    }
    @media screen and ${mediaFeatures["for-tablet"]} {
        padding: 0 0 2%;
        left: ${tabletSideGutter}vw;
        background-color: transparent;
        border: 0;
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        display: none;
    }
    @supports (position: sticky) or (position:-webkit-sticky){
        position: sticky;
        position: -webkit-sticky;
    }
`;
export const actionButtonWrapperEventItemCardAction = `
    position: absolute;
    right: 0;
    bottom: 0;
`;
export const actionButtonMain = `
    ${primaryButton}
    background-color: #3695d8;
    display: inline-block;
    padding: 0 calc(${medPadding} * 1.5);
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: bottom;
    transition: none;
    letter-spacing: ${medLetterSpacing};
    &:hover {
        transform: none;
    }

    img {
        width: 28px;
        position: absolute;
        top: 50%;
        left: -14px;
        transform: translate(-50%,-50%) ;
        ${medTransition}
        @media screen and ${mediaFeatures["for-pc"]} {
            width: 28px;
  		    left: -14px;
        }
    }

    span {
        width: 100%;
        display: inline-block;
        ${medTransition}
    }

    @media screen and ${mediaFeatures["for-mobile-and-small-tablet"]} {
        border-radius: unset;
        font-size: ${smallFontSize}; 
    }

    @media screen and ${mediaFeatures["for-large-tablet"]} {
        padding: 0 calc(${medPadding} *2);
    }
`;
export const actionButtonMainPage = `
    ${titleFontSizes}
    width: 100%;
    height: ${stripHeightMobile};
    line-height: ${stripHeightMobile};
    @media screen and ${mediaFeatures["for-tablet"]} {
        height: ${stripHeightMed};
        line-height: ${stripHeightMed};
    }

`;
export const actionButtonMainTop = `
    display: none;
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        position: relative;
        display: inline-block;
        left: 0;
        right: auto;
        height: 4.5vw;
        line-height: 4.5vw;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        height: 46px;
  	    line-height: 46px;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        height: 48px;
  	    line-height: 48px;
    }
`;
export const actionButtonMainEventItemCardAction = `
    ${titleFontSizes}
    height: 34px;
    line-height: 34px;
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        height: 38px;
        line-height: 38px;
        box-shadow: none;
    }
    @media screen and ${mediaFeatures["for-large-pc"]} {
        height: 42px;
        line-height: 42px;
    }
`;
export const actionButtonMainEnabled = `
    ${primaryHoverDarken}
`;
export const actionButtonMainDisabled = `
    ${disabledButton}
    cursor: default;
`;
export const actionButtonMainCaption = `
    ${bodyFontSizes}
    display: block;
    font-weight: normal;
    line-height: 1.45;
    a {
        text-decoration: none;
        color: ${splitColors["homeGoOut"]};
        font-weight: normal;
    }
    &:not(:empty) {
        margin-top: ${medPadding};
    }
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        &:not(:empty) {
            margin-bottom: ${medPadding};
            margin-top: 0;
        }
    }
`;
export const actionButtonMainCaptionEventItemCardAction = `
    display: none;
`;
export const actionButtonBtnsRow = `
    flex-flow: row nowrap;
    width: 100%;
`;
export const actionButtonMainLarge = `
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        width: auto;
        min-width: 200px;
    }
`;
export const actionButtonMainYoutube = `
    @media screen and ${mediaFeatures["for-large-tablet"]} {
        min-width: 180px;
    }
`;
