/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Image from "../../../Atoms/Image";
import { BaseBold, FontColor, LargeBold, OverlapAfterNLines, SmallMedium, colors } from "../../../Atoms/Typography/styles";
import { CalendarIcon } from "../../../../ui/icons/EventCardCalendarIcon";
import { LocationIcon } from "../../../../ui/icons/LocationIcon";
import { ChevronRight } from "../../../../ui/icons/ChevronRight";
import { InsiderLink } from "../../../../InsiderLink";
const SmallEventCard = ({ index, maxCards = 6, totalCount, image, eventName, date, city, slug, overflowIcon, overflowLink, accentColor, overflowLabel, openInNewTab = false, onClick = () => { }, eventState }) => {
    return totalCount > maxCards && index === maxCards ? (<InsiderLink key={index} href={overflowLink} target={openInNewTab ? "_blank" : undefined} isSPA={!overflowLink.includes("insider.in")} onClick={onClick}>
			<div css={css(ViewAllContainer)}>
				<div css={css(ViewAllBorder)}>
					<div css={css(ViewAllContent)}>
						{overflowIcon &&
            React.cloneElement(overflowIcon, {
                width: "62",
                height: "62",
                fill: accentColor ? accentColor : "#31C0F0"
            })}
						<p css={css([LargeBold, FontColor("--Grey-Text-White")])}>{overflowLabel || `Explore more`}</p>
					</div>
					<div css={css(ViewAllButton)}>
						<p css={css([SmallMedium, FontColor("--grey-highlight-copy")])}>View All</p>
						<ChevronRight strokeWidth="0.8"/>
					</div>
				</div>
			</div>
		</InsiderLink>) : (<InsiderLink href={`/${slug}/event`} key={index} onClick={() => onClick({ link: `/${slug}/event`, resourceType: "event" })}>
			<div css={css(Container)}>
				<Image src={image} style={ImageStyle}/>
				{eventState && eventState === "coming_soon" && <span css={css(Ribbon)}>Coming Soon</span>}
				<div css={css(Title)}>
					<p css={css([BaseBold, OverlapAfterNLines()])}>{eventName}</p>
				</div>
				<div css={css(DateCityWrapper)}>
					<div css={css(Row)}>
						<CalendarIcon fill={accentColor ? accentColor : colors["--Brand-Secondary-Blue"]} width="20" height="20"/>
						<p css={css([SmallMedium, FontColor("--grey-highlight-copy")])}>{date}</p>
					</div>
					<div css={css(Row)}>
						<LocationIcon stroke={accentColor ? accentColor : colors["--Brand-Secondary-Blue"]} size="20"/>
						<p css={css([SmallMedium, FontColor("--grey-highlight-copy")])}>{city}</p>
					</div>
				</div>
			</div>
		</InsiderLink>);
};
export default SmallEventCard;
const Container = `
    display: flex;
    width: 300px;
    max-width: 300px;
    height: 300px;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    background: #FCFCFD;
    flex: 1;
    color: ${colors["--Brand-Primary---Navy"]};
	position: relative;
	z-index: 1;
`;
const Title = `
    display: flex;
    height: 69px;
    padding: 9px 15px;
    justify-content: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
`;
const ImageStyle = `
    width: 300px;
    height: 167px;
    flex-shrink: 0;
    border-radius: 30px 30px 0 0;
    border: 1px solid #E7E8EA;
`;
const DateCityWrapper = `
    display: flex;
    padding: 0px 15px 15px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
`;
const Row = `
    display: flex;
    align-items: center;
    gap: 6px;
`;
const ViewAllContainer = `
    display: flex;
    width: 300px;
    min-width: 300px;
    height: 300px;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    background: #121D34;
`;
const ViewAllBorder = `
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex: 1;
    align-self: stretch;
    border-radius: 15px;
    border: 1px solid rgba(249, 249, 250, 0.15);
`;
const ViewAllContent = `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex: 1;
    align-self: stretch;
`;
const ViewAllButton = `
    display: flex;
    min-width: 72px;
    padding: 6px 6px 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 12px;
    background: #F3F3F5;
`;
const Ribbon = `
	font-size: 11px;
    position: absolute;
    top: calc(16px / 2);
    right: calc(16px / 2);
    color: #fff;
    text-transform: uppercase;
    background-color: #c20000;
    border-radius: 6px;
    font-weight: 700;
    padding: calc(12px / 2) calc(16px / 2);
    background-color: #ccc;
    right: auto;
    left: -10px;
    border-radius: 0 2px 2px 0;
    font-weight: 400;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        height: 16px;
        width: 16px;
        background-color: #999;
        left: 0;
        bottom: 0;
        transform: rotate(35deg);
        transform-origin: 0 100%;
    }
`;
