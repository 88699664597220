import React from "react";
import CarouselWrapper from "../CarouselWrapper";
import CarouselHeader from "../CarouselHeader";
import CarouselExtra from "../CarouselExtra";
import Carousel from "../Carousel";
import TinyCard from "../TinyCard";
import { generateAllArtistsLink } from "../../helpers/linkGenerators";
import { ArtistIcon } from "../ui/icons/HomePageIcons/ArtistIcon";
import CarouselTitleWithIcon from "../CarouselTitleWithIcon";
export default class ArtistCarousel extends React.Component {
    componentDidMount() {
        if (!this.props.artists.size) {
            this.props.fetchArtists().catch((e) => {
                console.error(e);
            });
        }
    }
    componentDidUpdate() {
        if (!this.props.artists.size) {
            this.props.fetchArtists().catch((e) => {
                console.error(e);
            });
        }
    }
    tinyCards(artists) {
        const { artistCarouselClickedEvent } = this.props;
        const TinyCards = artists.map((artist, idx) => {
            return (<li className="carousel-item carousel-item-right" key={`carousel-card-${idx}`} onClick={artistCarouselClickedEvent(idx + 1, artist.get("name"))}>
					<TinyCard key={`artist-${idx}`} cardData={artist} type="artist">
						{/* Removing follow for now until pending redesign */}
						{/* <Follow
                    key={`artist-follow-${idx}`}
                    type="Artist"
                    _id={artist.get("_id")}
                    slug={artist.get("slug")}
                    name={artist.get("name")}
                    context={`Artist-Carousel-${artist.get("slug")}`}
                /> */}
					</TinyCard>
				</li>);
        });
        return TinyCards;
    }
    render() {
        let artists = this.props.artists.filter(artist => {
            return artist.get("slug") !== this.props.hidden;
        });
        if (this.props.filterlist.length > 0) {
            artists = artists.filter(artist => {
                return this.props.filterlist.includes(artist.get("_id"));
            });
        }
        if (this.props.artists.size > 0) {
            return (<CarouselWrapper className="card-list-wrapper tiny-carousel">
					<CarouselHeader className={this.props.headerClass}>
						<CarouselTitleWithIcon title={this.props.listTitle} IconComponent={ArtistIcon} showViewAll/>
						{this.props.showExtra && <CarouselExtra viewAllHref={generateAllArtistsLink()}/>}
					</CarouselHeader>
					<Carousel numCarouselItems={6} listType="card">
						{this.tinyCards(artists)}
					</Carousel>
				</CarouselWrapper>);
        }
        else {
            return null;
        }
    }
}
ArtistCarousel.defaultProps = {
    listTitle: "Artists",
    showExtra: true,
    filterlist: [],
    artistCarouselClickedEvent: () => { }
};
