/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { mediaFeatures } from "../styles/common";
const PageModifierStyle = `
	font-weight: 400;
	line-height: 1.4;
	font-size: 1.825rem;
	@media screen and ${mediaFeatures["for-mobile"]} {
		font-size: 25px;
		margin-bottom: 8px;
	}
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: 2.435rem !important;
		margin-bottom: 8px;
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		margin-bottom: 8px;
		font-size: 24px;
	}
	@media screen and ${mediaFeatures["for-pc-only"]} {
		font-size: 1.825rem !important;
		margin-bottom: 12px !important;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 27.5px !important;
		margin-bottom: 12px;
	}
`;
const PageBannerStyle = `
	font-weight: 400;
	line-height: 1.4;
	font-size: 1.825rem;
	margin-bottom: 1.75vw;
	@media screen and ${mediaFeatures["for-mobile"]} {
		font-size: 25px;
		margin-bottom: 4vw;
	}
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		font-size: 2.435rem !important;
		margin-bottom: 2.75vw !important;
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		margin-bottom: 2.75vw;
		font-size: 24px;
	}
	@media screen and ${mediaFeatures["for-pc-only"]} {
		font-size: 1.825rem !important;
		margin-bottom: 1.75vw !important;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
		font-size: 27.5px !important;
		margin-bottom: 24px !important;
	}
`;
export const PageBanner = ({ children, pageModifier = "" }) => {
    return <h1 css={pageModifier === "partner" ? css(PageModifierStyle) : css(PageBannerStyle)}>{children}</h1>;
};
PageBanner.propTypes = {
    children: PropTypes.node.isRequired
};
PageBanner.defaultProps = {
    pageModifier: ""
};
