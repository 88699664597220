import { connect } from "react-redux";
import { getDigitalEventsCarouselItems } from "../selectors/homepage";
import DigitalEventsCarousel from "../components/DigitalEventsCarousel";
import { getPlatform, getSelectedCityName, isFromApp } from "../selectors";
const mapStateToProps = state => {
    return {
        carouselItems: getDigitalEventsCarouselItems(state),
        selectedCity: getSelectedCityName(state),
        isFromApp: isFromApp(state),
        platform: getPlatform(state)
    };
};
export default connect(mapStateToProps)(DigitalEventsCarousel);
