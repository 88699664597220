/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "../Typography/styles";
import { useState, useEffect, Fragment } from "react";
const ProgressIndicator = ({ componentId, accentColor, lightMode = false }) => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [isOverflowing, setIsOverflowing] = useState(true);
    const handleScroll = () => {
        const container = document.getElementById(componentId);
        const scrollPosition = container.scrollLeft;
        const maxScroll = container.scrollWidth - container.clientWidth;
        const percentage = (scrollPosition / maxScroll) * 100 || 0;
        setScrollPercentage(percentage);
        setIsOverflowing(container.scrollWidth > container.clientWidth);
    };
    useEffect(() => {
        handleScroll();
        const container = document.getElementById(componentId);
        container.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
        return () => {
            container.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);
    if (!isOverflowing || !componentId)
        return <Fragment />;
    return (<div css={css(ScrollIndicatorContainer)}>
			<div css={css(ScrollIndicator(lightMode))}>
				<div css={css(ScrollProgress(scrollPercentage, accentColor))}/>
			</div>
		</div>);
};
export default ProgressIndicator;
const ScrollIndicatorContainer = `
	align-self: center;
`;
const ScrollIndicator = (lightMode) => `
    width: 90px;
    height: 5px;
    border-radius: 100px;
	background: ${lightMode ? "rgba(18, 29, 52, 0.15)" : "rgba(249, 249, 250, 0.15)"};
`;
const ScrollProgress = (width, accentColor) => `
    height: 100%;
	width: ${width}%;
    border-radius: 100px;
	background: ${accentColor ? accentColor : colors["--Brand-Secondary-Blue"]};
`;
