/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Fragment } from "react";
import { Base, BaseBold, Display, FontColor, TextOverflowEllipsis, Uppercase, colors } from "../../../Atoms/Typography/styles";
import { InsiderLink } from "../../../../InsiderLink";
const ScheduleCard = ({ index, maxCards = 6, totalCount, date, month, city, slug, loading = false, overflowLink, accentColor, lightMode = false, openInNewTab = false, onClick = () => { } }) => {
    return loading ? (<LoadingContent lightMode={lightMode}/>) : (<CardContent index={index} maxCards={maxCards} totalCount={totalCount} date={date} month={month} city={city} slug={slug} overflowLink={overflowLink} accentColor={accentColor} lightMode={lightMode} openInNewTab={openInNewTab} onClick={onClick}/>);
};
const LoadingContent = ({ lightMode = false }) => (<div css={css(Container(lightMode))}>
		<div css={css(HorizontalLine(true))}/>
		<Fragment>
			<div css={css(Shimmer("120px", "18px"))}/>
			<div css={css(Shimmer("84px", "18px"))}/>
			<div css={css(Seperator)}/>
			<div css={css(Shimmer("86px", "11px"))}/>
		</Fragment>
	</div>);
const CardContent = ({ index, maxCards = 6, totalCount, date, month, city, slug, overflowLink, accentColor, lightMode = false, openInNewTab = false, onClick = () => { } }) => totalCount > maxCards && index === maxCards ? (<InsiderLink href={overflowLink} css={css(LinkStyle)} target={openInNewTab ? "_blank" : undefined} isSPA={!overflowLink.includes("insider.in")} onClick={onClick}>
			<div css={css(Container(lightMode))}>
				<div css={css(HorizontalLine(false, accentColor))}/>
				<div css={css(DateAndCity)}>
					<p css={css(TextOverflowEllipsis)}>
						<span css={css(BaseBold)}>{`+${totalCount - maxCards + 1} `}</span>
						<span css={css(Base)}>more</span>
					</p>
					<p css={css(BaseBold)}>Events</p>
				</div>
				<div css={css(Seperator)}/>
				<p css={css([
        Display,
        Base,
        accentColor ? `color: ${accentColor};` : FontColor("--Brand-Secondary-Blue"),
        Uppercase
    ])}>
					View All
				</p>
			</div>
		</InsiderLink>) : (<InsiderLink href={`/${slug}/event`} css={css(LinkStyle)} onClick={() => onClick({ link: `/${slug}/event`, resourceType: "event" })}>
			<div css={css(Container(lightMode))}>
				<div css={css(HorizontalLine(false, accentColor))}/>
				<div css={css(DateAndCity)}>
					<p css={css(TextOverflowEllipsis)}>
						<span css={css(BaseBold)}>{`${date} `}</span>
						<span css={css(Base)}>{month}</span>
					</p>
					<p css={css(BaseBold)}>{city}</p>
				</div>
				<div css={css(Seperator)}/>
				<p css={css([
        Display,
        Base,
        accentColor ? `color: ${accentColor};` : FontColor("--Brand-Secondary-Blue"),
        Uppercase
    ])}>
					Buy now
				</p>
			</div>
		</InsiderLink>);
export default ScheduleCard;
const Container = (lightMode) => `
	display: flex;
	padding: 15px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 9px;
	border-radius: 15px;
	background: ${lightMode ? colors["--Background-BG-SecBTN-Light"] : colors["--Brand-Primary---Navy"]};
	position: relative;
	border: 1px solid rgba(249, 249, 250, 0.06);
`;
const LinkStyle = `
	flex: 1;				
	width: 100%;
	min-width: 135px;
	max-width: 410px;
`;
const HorizontalLine = (loading, accentColor) => `
	width: 30px;
	height: 3px;
	background-color: ${loading ? "rgba(249, 249, 250, 0.06)" : accentColor ? accentColor : "#31C0F0"};
	border-radius: 2px;
`;
const Seperator = `
	width: 100%;
	height: 1px;
	background: rgba(249, 249, 250, 0.06);
`;
const Shimmer = (width, height) => `
	width: ${width};
	height: ${height};
	border-radius: 9px;
	background: rgba(249, 249, 250, 0.06);
`;
const DateAndCity = `
	display: flex;
	flex-direction: column;
	width: 125px;
	text-align: center;
`;
