import * as React from "react";
import Observer from "react-intersection-observer";
export class Picture extends React.Component {
    render() {
        const { className, sources, src, alt, lazy } = this.props;
        const picture = (
        // If lazy, classname is defined on the containing div (intersection observer)
        <picture className={lazy ? "" : className}>
				{sources.map(src => {
                return <source media={src.media} srcSet={src.srcSet} key={src.srcSet}/>;
            })}
				<img src={src} alt={alt}/>
			</picture>);
        if (!lazy) {
            return picture;
        }
        return (
        // @ts-ignore
        <Observer triggerOnce={true} rootId="there-you-go" rootMargin="300px" className={className}>
				{inView => {
                if (inView) {
                    return picture;
                }
                else {
                    return <div />;
                }
            }}
			</Observer>);
    }
}
