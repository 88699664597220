import { createSelector } from "reselect";
import { fromJS, List, Map } from "immutable";
import { getMasterList } from "../homepage";
import { isFavouritedEvent } from "../favourites";
import moment from "moment";
export const getSlugFromProps = (_, slug) => slug;
export const getPartialEventDetails = createSelector(getMasterList, getSlugFromProps, (masterlist, slug) => {
    if (!masterlist) {
        return;
    }
    return masterlist.get(slug);
});
export const getFavStats = createSelector(getPartialEventDetails, partialEventData => {
    if (!partialEventData) {
        return;
    }
    return partialEventData.get("favStats");
});
export const getFavCount = (state, ownProps) => {
    const favStats = getFavStats(state, ownProps.slug);
    if (!favStats) {
        return;
    }
    const isFavourited = isFavouritedEvent(state, ownProps);
    const showCountThreshold = 30;
    const isOverThreshold = (favStats) => favStats && favStats.get("actualCount") > showCountThreshold;
    const count = isOverThreshold(favStats) ? favStats.get("prettyCount") : "";
    if (typeof count == "string") {
        return count;
    }
    /*this increases/decreases count by one when user clicks favourite button since the count api is cached*/
    if (isFavourited) {
        return count + 1;
    }
    else {
        return count;
    }
};
// NOTE - we dont' use create selector here because
// we want this to not be cached. Otherwise the partial
// data will always be cached
export const getEventDetails = (state, slug) => {
    // we have 2 cases - either the full event
    // data is available in the state, or we
    // have it partially available from the home API
    const fullEventData = state.getIn(["eventsData", slug, "insider"]);
    if (fullEventData && fullEventData.get("partial") === false)
        return fullEventData;
    // First check the masterlist from the homepage
    const partialEventData = getPartialEventDetails(state, slug);
    if (!partialEventData || !partialEventData.get("slug")) {
        return;
    }
    const normalizedPartialEventData = Map({
        _id: partialEventData.get("_id"),
        name: partialEventData.get("name"),
        slug: partialEventData.get("slug"),
        horizontal_cover_image: partialEventData.get("horizontal_cover_image"),
        price_display_string: partialEventData.get("price_display_string"),
        venue_date_string: partialEventData.get("venue_date_string"),
        venue_name: partialEventData.get("venue_name"),
        partial: true,
        event_state: partialEventData.get("event_state"),
        is_rsvp: partialEventData.get("is_rsvp"),
        tags: partialEventData.get("tags"),
        category_id: partialEventData.get("category_id"),
        group_id: partialEventData.get("group_id"),
        action_button_text: partialEventData.get("action_button_text"),
        event_type: partialEventData.get("event_type")
    });
    return normalizedPartialEventData;
};
export const getDataRequested = (state, slug) => {
    return state.getIn(["eventsData", slug, "dataRequested"], false);
};
export const getDataReceived = (state, slug) => {
    return Boolean(state.getIn(["eventsData", slug, "insider"])); // Assuming name will only come from the api
};
export const getEventStartTime = createSelector(getEventDetails, eventData => {
    let showStartTime = eventData.get("start_utc_timestamp");
    let showDate = new Date(0);
    showDate.setUTCSeconds(showStartTime);
    return showDate;
});
export const getAvailableShowsForEvent = createSelector(getEventDetails, eventData => {
    if (!eventData) {
        return;
    }
    let shows = eventData.getIn(["venue", "shows"]);
    shows = shows ? shows.toJS() : [];
    const now = Date.parse(new Date()) / 1000;
    // const availableShows = shows.filter(show => {
    // 	const showStartTime = show.get("start_utc_timestamp");
    // 	const isHidden = show.get("is_hidden");
    // 	return showStartTime > now && !isHidden;
    // });
    let availableShows = shows.filter(obj => {
        if (obj.is_hidden || (shows.length === 1 && obj.sold_out)) {
            return false;
        }
        const forSale = obj.items_for_sale || [];
        var availabilityList = forSale.map(group => {
            var items = group.items || [];
            var availableItems = items.map(item => {
                var expiryDate = moment(item.availability_date);
                if (expiryDate.isValid() && moment().diff(expiryDate) < 0 && !item.is_hidden) {
                    return true;
                }
                return false;
            });
            return availableItems.some(items => items);
        });
        return availabilityList.some(availabilityList => availabilityList);
    });
    return fromJS(availableShows);
});
const itemgroupValidItems = itemgroupData => {
    return itemgroupData.get("items").filter(item => {
        return (!item.get("is_hidden") &&
            (item.get("restrictions").indexOf("*") >= 0 || item.get("restrictions").indexOf("online") >= 0));
    });
};
export const getAvailableItemGroupsForEvent = createSelector(getAvailableShowsForEvent, activeShows => {
    if (!activeShows) {
        return;
    }
    let ItemGroupArr = [];
    activeShows.forEach(show => {
        show.get("items_for_sale").forEach(itemgroupData => {
            let validItems = itemgroupValidItems(itemgroupData);
            let itemgroupDataObj = itemgroupData.toJS();
            if (validItems.size > 0) {
                itemgroupDataObj.items = validItems.toJS();
                itemgroupDataObj.show_id = show.get("_id");
                ItemGroupArr.push(itemgroupDataObj);
            }
        });
    });
    return fromJS(ItemGroupArr);
});
export const getNextAvailableShowStartTime = createSelector(getAvailableShowsForEvent, shows => {
    if (!shows) {
        return;
    }
    const sortedShows = shows.sort((showA, showB) => {
        return showA.get("start_utc_timestamp") - showB.get("start_utc_timestamp");
    });
    if (!sortedShows.size) {
        return;
    }
    const showStartTime = sortedShows.getIn(["0", "start_utc_timestamp"]);
    let showDate = new Date(0);
    showDate.setUTCSeconds(showStartTime);
    return showDate;
});
export const getStreamDataReceived = (state, slug) => {
    return state.getIn(["eventsData", slug, "activityDataReceived"], false);
};
export const getStreamDataRequested = (state, slug) => {
    return state.getIn(["eventsData", slug, "activityDataRequested"], false);
};
export const getRelatedEvents = (state, slug) => {
    return state.getIn(["eventsData", slug, "relatedEvents", "data"], new List());
};
export const getRelatedEventsDataRequested = (state, slug) => {
    return state.getIn(["eventsData", slug, "relatedEvents", "dataRequested"], false);
};
export const getRelatedEventsDataReceived = (state, slug) => {
    return state.getIn(["eventsData", slug, "relatedEvents", "dataReceived"], false);
};
export const getEventAdBanner = (state, slug) => {
    const eventAdBanner = state.getIn(["eventsData", slug, "eventAdBanner", "data"]);
    if (!eventAdBanner) {
        return;
    }
    const normalizedeventAdBanner = Map({
        campaignName: eventAdBanner.get("name"),
        desktopImageUrl: eventAdBanner.get("edp_image"),
        mobileImageUrl: eventAdBanner.get("edp_image"),
        desktopHref: eventAdBanner.get("map_link"),
        mobileHref: eventAdBanner.get("map_link")
    });
    return normalizedeventAdBanner;
};
export const getEventAdBannerDataRequested = (state, slug) => {
    return state.getIn(["eventsData", slug, "eventAdBanner", "dataRequested"], false);
};
export const getEventAdBannersDataReceived = (state, slug) => {
    return state.getIn(["eventsData", slug, "eventAdBanner", "dataReceived"], false);
};
