import React from "react";
import CarouselHeader from "./CarouselHeader";
import TimeFilterCard from "../containers/TimeFilterCardWithSubscription";
import CarouselWrapper from "./CarouselWrapper";
import Carousel from "./Carousel";
import { generateSEOEverythingLink } from "../helpers/linkGenerators";
import { TIME_FILTERS } from "../helpers/constants";
import { TimeFilterIcon } from "./ui/icons/HomePageIcons/TimeFilterIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
const TimeFilterStrip = ({ dates, selectedCity, dayCarouselClickedEvent }) => {
    return (<CarouselWrapper className="card-list-wrapper timefilter-carousel">
			<CarouselHeader>
				<CarouselTitleWithIcon title={"Events this week"} IconComponent={TimeFilterIcon}/>
			</CarouselHeader>
			<Carousel numCarouselItems={4} listType="card">
				{TIME_FILTERS.map((time, idx) => {
            return (<li key={time.key} onClick={dayCarouselClickedEvent(idx + 1, time.display)}>
							<TimeFilterCard date={dates.get(time.key)} dateString={dates.get(`${time.key}_date_string`)} title={time.display} timeFilter={time.key} href={generateSEOEverythingLink(selectedCity, time.key)}/>
						</li>);
        })}
			</Carousel>
		</CarouselWrapper>);
};
export default TimeFilterStrip;
