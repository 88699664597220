/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { styles } from "./styles";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
import { slugify } from "../../helpers/misc";
const CarouselTitle = ({ title, showViewAll, micrositePrimaryColor = "" }) => {
    return (<div css={css(styles(showViewAll, micrositePrimaryColor))} data-ref={`${DATA_REFS.CAROUSEL_TITLE}_${slugify(title)}`}>
			{title}
		</div>);
};
export default CarouselTitle;
