/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { getBuyButtonText } from "../helpers/constants";
import { eventCardRibbon } from "../styles/cardStyles";
const EventRibbon = ({ eventState }) => {
    const { buyButtonText, disabled } = getBuyButtonText(eventState, "", false);
    if (disabled) {
        return (<span css={css(eventCardRibbon)} className="event-card-ribbon">
				{buyButtonText}
			</span>);
    }
    return null;
};
export default EventRibbon;
