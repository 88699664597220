export const safeJsonLdReplacer = (() => {
    // Reference - https://github.com/google/react-schemaorg/commit/22e7dec0f11db974b02b623bd933f59ce518cb96
    const entities = Object.freeze({
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&apos;"
    });
    const replace = t => entities[t] || t;
    // Utility: Assert never
    const isNever = _ => { };
    return (_, value) => {
        switch (typeof value) {
            case "object":
                return value; // JSON.stringify will recursively call replacer.
            case "number":
            case "boolean":
            case "bigint":
                return value; // These values are not risky.
            case "string":
                return value.replace(/[&<>'"]/g, replace);
            default: {
                // We shouldn't expect other types.
                isNever(value);
                // JSON.stringify will remove this element.
                return undefined;
            }
        }
    };
})();
