import { connect } from "react-redux";
import { withRouter } from "react-router";
// components
import EventCard from "../components/EventCard";
// selectors
import { getAllCities, getSelectedCityName, getGroups } from "../selectors/index";
const mapStateToProps = state => ({
    cities: getAllCities(state),
    groups: getGroups(state),
    queryID: state.getIn(["searchData", "resultsState", "events", "queryID"]),
    query: state.getIn(["searchData", "resultsState", "events", "query"]),
    selectedCity: getSelectedCityName(state)
});
const EventCardWithSubscription = withRouter(connect(mapStateToProps)(EventCard));
export default EventCardWithSubscription;
