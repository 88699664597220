import { Map } from "immutable";
import { createSelector } from "reselect";
import { replaceCityAndTimePlaceholder, ONLINE_CITY_TITLE } from "../../helpers/constants";
// import other selectors
import { getCapitalizedSelectedCityName } from "../index";
/**
 * Returns a map of all the meta props
 * for the home page
 * @param _
 * @param {object} props
 * @returns {Immutable.Map<{title: string, description: string, keywords: string, ogImageHeight: string, ogImageWidth: string, twitterCard: string, ogImage: string, twitterImage: string, ogTitle: string, twitterTitle: string, ogDescription: string, twitterDescription: string}>}
 */
export const getMetaProps = (_, props) => {
    const { title, description, keywords, ogImageHeight, ogImageWidth, twitterCard } = props;
    const ogImage = props.ogImage || props.twitterImage;
    const twitterImage = props.twitterImage || props.ogImage;
    const ogTitle = props.ogTitle || title;
    const twitterTitle = props.twitterTitle || title;
    const ogDescription = props.ogDescription || description;
    const twitterDescription = props.twitterDescription || description;
    return Map({
        title,
        description,
        keywords,
        ogImageHeight,
        ogImageWidth,
        twitterCard,
        ogImage,
        twitterImage,
        ogTitle,
        twitterTitle,
        ogDescription,
        twitterDescription
    });
};
/**
 * Returns an array of meta properties for the homepage
 * of a particular city
 * @return {function}
 */
export const getSEODetailsForCityHomepage = createSelector([getCapitalizedSelectedCityName, getMetaProps], (selectedCity, metaProps) => ({
    title: selectedCity === "Online"
        ? ONLINE_CITY_TITLE
        : replaceCityAndTimePlaceholder(metaProps.get("title"), selectedCity),
    metaProps: [
        {
            name: "description",
            content: replaceCityAndTimePlaceholder(metaProps.get("description"), selectedCity)
        },
        {
            name: "keywords",
            content: metaProps.get("keywords")
        },
        {
            property: "og:title",
            content: replaceCityAndTimePlaceholder(metaProps.get("ogTitle"), selectedCity)
        },
        {
            property: "og:description",
            content: replaceCityAndTimePlaceholder(metaProps.get("ogDescription"), selectedCity)
        },
        {
            property: "og:image",
            content: metaProps.get("ogImage")
        },
        {
            property: "og:image:width",
            content: metaProps.get("ogImageWidth")
        },
        {
            property: "og:image:height",
            content: metaProps.get("ogImageHeight")
        },
        {
            property: "twitter:card",
            content: metaProps.get("twitterCard")
        },
        {
            property: "twitter:title",
            content: replaceCityAndTimePlaceholder(metaProps.get("twitterTitle"), selectedCity)
        },
        {
            property: "twitter:description",
            content: replaceCityAndTimePlaceholder(metaProps.get("twitterDescription"), selectedCity)
        },
        {
            property: "twitter:image",
            content: metaProps.get("twitterImage")
        }
    ]
}));
