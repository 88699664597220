import React from "react";
import { generateCellLink } from "../helpers/linkGenerators";
import GenreStrip from "./GenreStrip";
import Image from "./Image";
import { getCategoryNameFromResource } from "../selectors/index.js";
import { getComputedCategoryColour } from "../selectors/index.js";
class CellCard extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            categoryName: this.props.cardData ? getCategoryNameFromResource(this.props.cardData) : undefined,
            categoryColour: this.props.cardData ? getComputedCategoryColour(this.props.cardData) : undefined
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.cardData !== this.props.cardData) {
            this.setState({
                categoryName: nextProps.cardData ? getCategoryNameFromResource(nextProps.cardData) : undefined,
                categoryColour: nextProps.cardData ? getComputedCategoryColour(nextProps.cardData) : undefined
            });
        }
    }
    render() {
        return (<div className="cell-card">
				<a href={generateCellLink({
                slug: this.props.cardData.get("map_slug"),
                link: this.props.cardData.get("map_link")
            })}>
					<Image src={this.props.cardData.get("feature_image")} className="cell-card-image" caption={this.props.cardData.get("name")} captionClassName="cell-card-name" imgWidth={600}/>
					<GenreStrip categoryName={this.state.categoryName} categoryColour={this.state.categoryColour}/>
				</a>
			</div>);
    }
}
export default CellCard;
