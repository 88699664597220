import React from "react";
export const Drinks = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23.9451 14H30.3817L34.1016 6.56031C34.1072 6.5489 34.113 6.53761 34.119 6.52645C34.2134 6.35066 34.3535 6.21408 34.5171 6.12411C34.6238 6.06514 34.7425 6.02511 34.8686 6.00857C34.9188 6.00189 34.9696 5.99899 35.0207 6.00002L41 6.00002C41.5522 6.00002 42 6.44774 42 7.00002C42 7.55231 41.5522 8.00002 41 8.00002H35.6178L32.6178 14H41C41.1356 14 41.2649 14.027 41.3828 14.0759C41.4981 14.1236 41.6062 14.1937 41.7006 14.2864L41.7136 14.2995C41.8907 14.4799 42 14.7272 42 15C42 15.1356 41.973 15.2649 41.9241 15.3828C41.8772 15.4962 41.8085 15.6028 41.718 15.6961L41.706 15.7082L28 29.4142L28 40H33C33.5523 40 34 40.4477 34 41C34 41.5523 33.5523 42 33 42H21C20.4477 42 20 41.5523 20 41C20 40.4477 20.4477 40 21 40H26L26 29.4142L12.2942 15.7084L12.282 15.6961C12.1923 15.6036 12.124 15.498 12.0771 15.3856C12.0274 15.2669 12 15.1367 12 15C12 14.7306 12.1065 14.486 12.2798 14.3062C12.2884 14.2973 12.2973 14.2884 12.3062 14.2798C12.3985 14.1909 12.5036 14.1232 12.6154 14.0766C12.7338 14.0273 12.8637 14 13 14H21.9291C21.4439 10.6077 18.5265 8 15 8C11.134 8 8 11.134 8 15C8 18.866 11.134 22 15 22C15.5523 22 16 22.4477 16 23C16 23.5523 15.5523 24 15 24C10.0294 24 6 19.9706 6 15C6 10.0294 10.0294 6 15 6C19.6326 6 23.4476 9.50005 23.9451 14ZM15.4142 16L17.4142 18H36.5858L38.5858 16H31.0219C31.0066 16.0004 30.9913 16.0004 30.976 16L15.4142 16ZM19.4142 20L27 27.5858L34.5858 20H19.4142Z" fill="#0C172F"/>
			<path d="M16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9C15.5523 9 16 9.44772 16 10Z" fill="#0C172F"/>
			<path d="M16 20C16 20.5523 15.5523 21 15 21C14.4477 21 14 20.5523 14 20C14 19.4477 14.4477 19 15 19C15.5523 19 16 19.4477 16 20Z" fill="#0C172F"/>
			<path d="M10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16Z" fill="#0C172F"/>
			<path d="M19.2428 12.1716C18.8522 12.5621 18.2191 12.5621 17.8285 12.1716C17.438 11.7811 17.438 11.1479 17.8285 10.7574C18.2191 10.3669 18.8522 10.3669 19.2428 10.7574C19.6333 11.1479 19.6333 11.7811 19.2428 12.1716Z" fill="#0C172F"/>
			<path d="M12.1715 19.2427C11.7809 19.6332 11.1478 19.6332 10.7572 19.2427C10.3667 18.8521 10.3667 18.219 10.7572 17.8284C11.1478 17.4379 11.7809 17.4379 12.1715 17.8284C12.562 18.219 12.562 18.8521 12.1715 19.2427Z" fill="#0C172F"/>
			<path d="M10.7576 12.1717C10.3671 11.7812 10.3671 11.148 10.7576 10.7575C11.1481 10.367 11.7813 10.367 12.1718 10.7575C12.5623 11.148 12.5623 11.7812 12.1718 12.1717C11.7813 12.5622 11.1481 12.5622 10.7576 12.1717Z" fill="#0C172F"/>
		</svg>);
};
