import React from "react";
import cookies from "browser-cookies";
// components
import Loader from "./Loader";
import RecentlyViewedCarousel from "./RecentlyViewedCarousel";
// containers
import ArtistCarousel from "../containers/ArtistCarouselWithSubscription";
import BannerCarousel from "../containers/HomeBannerCarouselWithSubscription";
import BannerCarouselEmptyState from "../containers/BannerCarouselEmptyStateWithSubscription";
import CategoryIconStrip from "../containers/CategoryIconStripWithSubscription";
import FeaturedCarousel from "../containers/FeaturedCarouselWithSubscription";
import HomePageHelmet from "../containers/HomePageHelmetWithSubscription";
import HomePageSearch from "../containers/HomePageSearchWithSubscription";
import PicksCarousel from "../containers/PicksCarouselWithSubscription";
import RehydrateRecentlyViewed from "../containers/RecentlyViewedWithSubscription";
import TimeFilterStrip from "../containers/TimeFilterStripWithSubscription";
import VenueCarousel from "../containers/VenueCarouselWithSubscription";
import MultipleCategoryCarousels from "../containers/MultipleCategoryCarouselsWithSubscription";
import DigitalEventsCarousel from "../containers/DigitalEventsCarouselWithSubscription";
import { generateSEOCategoryLink, generateSEOGroupLink, generateSamePageURL } from "../helpers/linkGenerators";
import { sendClevertapEvent, updateClevertapUserProfile } from "../helpers/misc";
import { addGAEvent, CLEVERTAP_ACTIONS, EVENT_CATEGORIES, GA_RECOMMENDED_EVENTS, GENERAL_ACTIONS, HIT_TYPES } from "../helpers/constants";
import { setPersistedSelectedCity } from "../helpers/selectedCity";
import PaytmMobileMenu from "./PaytmMobileMenu";
import TrendingCarousel from "../containers/TrendingCarouselWithSubscription";
import QuickLinks from "../containers/QuickLinksWithSubscription";
import Showcase from "../containers/ShowcaseWithSubscription";
import { connect } from "react-redux";
import { getIsCityChanged } from "../selectors";
import { getOSFromUserAgent } from "../helpers/misc/common";
import InterstitialAd from "./InterstitialAd";
class HomeView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.generateCtEventPayload = (Depth, Product) => {
            return {
                Depth,
                Product
            };
        };
        this.bannerClickedEvent = (Depth, banner) => e => {
            const name = banner.get("name");
            const url = banner.get("map_link");
            sendClevertapEvent(CLEVERTAP_ACTIONS.HOME_BANNER_CLICKED, this.generateCtEventPayload(Depth, name));
            window.ga &&
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.HOMEPAGE_BANNER,
                    eventAction: name,
                    eventLabel: url
                });
            addGAEvent(GA_RECOMMENDED_EVENTS.SELECT_CONTENT, {
                content_type: "Homepage Banner",
                item_id: url
            });
        };
        this.featuredCarouselClickedEvent = (Depth, card) => e => {
            const name = card.get("name");
            const slug = card.get("slug");
            sendClevertapEvent(CLEVERTAP_ACTIONS.FEATURED_CAROUSEL_CLICKED, this.generateCtEventPayload(Depth, name));
            window.ga &&
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.FEATURED_EVENTS,
                    eventAction: name,
                    eventLabel: slug
                });
            addGAEvent(GA_RECOMMENDED_EVENTS.SELECT_CONTENT, {
                content_type: "Featured Events",
                item_id: slug
            });
        };
        this.dayCarouselClickedEvent = (Depth, Product) => e => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.DAY_CAROUSEL, this.generateCtEventPayload(Depth, Product));
        };
        this.genreCarouselClickedEvent = (Depth, category) => e => {
            const categoryName = category.get("name");
            const selectedCity = this.props.selectedCity;
            sendClevertapEvent(CLEVERTAP_ACTIONS.GENRE_CAROUSEL, this.generateCtEventPayload(Depth, categoryName));
            window.ga &&
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.GENRE,
                    eventAction: categoryName,
                    eventLabel: generateSEOCategoryLink(selectedCity, categoryName)
                });
            addGAEvent(GA_RECOMMENDED_EVENTS.SELECT_CONTENT, {
                content_type: `Genre-${categoryName}`,
                item_id: generateSEOCategoryLink(selectedCity, categoryName)
            });
        };
        this.popularCarouselClickedEvent = (Depth, Product) => e => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.POPULAR_CAROUSEL, this.generateCtEventPayload(Depth, Product));
        };
        this.artistCarouselClickedEvent = (Depth, Product) => e => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.ARTIST_CAROUSEL, this.generateCtEventPayload(Depth, Product));
        };
        this.venueCarouselClickedEvent = (Depth, Product) => e => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.VENUE_CAROUSEL, this.generateCtEventPayload(Depth, Product));
        };
        this.multipleCategoryCarouselClickedEvent = (Depth, Product, Category) => e => {
            sendClevertapEvent(CLEVERTAP_ACTIONS.CATEGORY_CAROUSEL, { Depth, Product, "Carousel name": Category });
        };
        this.updateUserCityClevertap = this.updateUserCityClevertap.bind(this);
    }
    // static fetchData(data) {
    // const { query, store, city } = data;
    // dispatch the UI state update action
    // this is sync dispatch
    //store.dispatch(updateSiteDisplayMode('go-out'));
    // server side render for tabs
    //store.dispatch(updateSelectedTab('go-out','Home'));
    // return the promise here
    // because it is an async action creator
    // 	return store.dispatch(fetchGooutHomeResources(city, query.banner_id));
    // }
    // Redirect old Routes with query param eg: /mumbai?view=Events to new seo friendly urls
    static redirector(url) {
        if (url.indexOf("view=") !== -1) {
            const city = url.split("?")[0].replace("/", "");
            const groupName = url.split("?")[1].split("=")[1];
            if (city && groupName) {
                return {
                    shouldRedirect: true,
                    redirectStatus: 302,
                    redirectLocation: generateSEOGroupLink(city, groupName)
                };
            }
        }
        return {
            shouldRedirect: false
        };
    }
    sendCtHomePageViewedEvent() {
        const { selectedCity } = this.props;
        sendClevertapEvent(CLEVERTAP_ACTIONS.WEB_HOME_VISITED, { city: selectedCity });
    }
    updateUserCityClevertap() {
        const { loginDataReceived, selectedCity, userInfo } = this.props;
        if (!loginDataReceived) {
            return;
        }
        if (selectedCity) {
            const city = cookies.get("cityChanged");
            if (city !== selectedCity) {
                let profileData;
                if (userInfo && userInfo.size) {
                    profileData = {
                        Identity: userInfo.get("_id"),
                        userCity: selectedCity
                    };
                }
                else {
                    profileData = {
                        userCity: selectedCity
                    };
                }
                updateClevertapUserProfile(profileData);
            }
            cookies.set("cityChanged", selectedCity, { secure: true, expires: 365 * 10 });
        }
    }
    getCityFromUrl() {
        return this.props.location.pathname.substring(1); // remove '/'
    }
    fetchGooutDataIfRequired() {
        const { displayMode, clearSiteDisplayMode, homepageDataRequested, homepageDataReceived, getHomepageData, isCityChanged, selectedCity } = this.props;
        this.pageUpdateUrl(isCityChanged, selectedCity);
        if (displayMode !== "go-out") {
            // When returning from digital events group (eg: /online-events/online-entertainment) the displayMode
            // is not cleared instanly on unmount of dynamicList.
            // To avoid re-fetch of data, clear displayMode before checking
            clearSiteDisplayMode();
            return;
        }
        if (homepageDataRequested || homepageDataReceived) {
            return;
        }
        const city = this.getCityFromUrl();
        const device = getOSFromUserAgent();
        getHomepageData(city, "", device);
    }
    //updating same page URL for redirection purpose on city change
    pageUpdateUrl(isCityChanged, selectedCity) {
        if (isCityChanged && selectedCity) {
            const newCity = cookies.get("newCity");
            window.location.href = generateSamePageURL(selectedCity, newCity);
        }
    }
    componentDidMount() {
        const city = this.getCityFromUrl();
        const { selectedCity, updateSelectedCity, getHomepageData } = this.props;
        const device = getOSFromUserAgent();
        getHomepageData(city, "", device); // insted of calling from static method, now calling from here, client side
        const showFirstTimeBanner = cookies.get("showFirstTimeBanner");
        if (showFirstTimeBanner && showFirstTimeBanner == "true") {
            cookies.erase("showFirstTimeBanner");
        }
        if (city) {
            setPersistedSelectedCity(city); // In browser/app cache
        }
        // This shouldnt happen but leaving it here just in case
        if (city && selectedCity !== city) {
            updateSelectedCity(city); // In redux
        }
        this.updateUserCityClevertap();
        this.fetchGooutDataIfRequired();
        this.sendCtHomePageViewedEvent();
    }
    componentDidUpdate() {
        this.fetchGooutDataIfRequired();
    }
    componentWillReceiveProps() {
        this.updateUserCityClevertap();
    }
    render() {
        if (!this.props.homepageDataReceived) {
            return <Loader />;
        }
        const citySelected = this.props.selectedCity && this.props.selectedCity !== "online";
        return (<div key={`go-out-tab-panel-featured`} className="discovery-tab-content">
				<HomePageHelmet />
				<BannerCarousel emptyStateComponent={BannerCarouselEmptyState} bannerClickedEvent={this.bannerClickedEvent}/>
				<HomePageSearch />
				{/* <HomePageCitySelector /> */}
				<QuickLinks />
				<RehydrateRecentlyViewed render={props => <RecentlyViewedCarousel {...props}/>}/>
				<Showcase />
				{citySelected && <TrendingCarousel />}
				{/* {citySelected && HomePageGroupCarouselConstants.enabled && <GroupCarousel />} */}
				<DigitalEventsCarousel />
				<FeaturedCarousel ssrImageCount={4} featuredCarouselClickedEvent={this.featuredCarouselClickedEvent}/>
				<CategoryIconStrip genreCarouselClickedEvent={this.genreCarouselClickedEvent}/>
				<MultipleCategoryCarousels city={this.props.selectedCity} multipleCategoryCarouselClickedEvent={this.multipleCategoryCarouselClickedEvent}/>
				<TimeFilterStrip dayCarouselClickedEvent={this.dayCarouselClickedEvent}/>
				<PicksCarousel />
				{this.props.selectedCity && <ArtistCarousel artistCarouselClickedEvent={this.artistCarouselClickedEvent}/>}
				{this.props.selectedCity && <VenueCarousel venueCarouselClickedEvent={this.venueCarouselClickedEvent}/>}
				<PaytmMobileMenu />
				<InterstitialAd isFromApp={this.props.isFromApp}/>
			</div>);
    }
}
const mapStateToProps = state => {
    return {
        isCityChanged: getIsCityChanged(state)
    };
};
export default connect(mapStateToProps)(HomeView);
