import React from "react";
import classNames from "classnames";
export class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.toggleAccordion = () => {
            if (this.isControlledAccordion()) {
                return;
            }
            this.setState({ isPanelShowing: !this.state.isPanelShowing });
        };
        this.state = {};
        if (!this.isControlledAccordion()) {
            this.state.isPanelShowing = false;
        }
    }
    isControlledAccordion() {
        return typeof this.props.isPanelShowing !== "undefined";
    }
    renderChildren() {
        const children = React.Children.toArray(this.props.children);
        // we don't use array destructuring here because
        // it needs an Array.from polyfill included
        const firstChild = children[0]; // Title
        const secondChild = children[1]; // Panel
        const firstChildWithModifiedProps = React.cloneElement(firstChild, {
            toggleAccordion: this.isControlledAccordion() ? this.props.onToggleAccordion : this.toggleAccordion
        });
        const newChildren = [firstChildWithModifiedProps, secondChild];
        const newPropsToAdd = {
            isPanelShowing: this.isControlledAccordion() ? this.props.isPanelShowing : this.state.isPanelShowing
        };
        // Would be so much nicer with auto curried functions
        return React.Children.map(newChildren, child => React.cloneElement(child, newPropsToAdd));
    }
    render() {
        const sectionClassNames = classNames("accordion-wrapper", { [`${this.props.className}`]: true }, { expanded: this.isControlledAccordion() ? this.props.isPanelShowing : this.state.isPanelShowing }, { disabled: this.props.disabled });
        return (<section className={sectionClassNames} id={this.props.id}>
				{this.renderChildren()}
			</section>);
    }
}
