/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import classNames from "classnames";
const carouselHeaderStyle = `
	justify-content: space-between;
	margin-bottom: 20px;
`;
const CarouselHeader = (props) => {
    return <div className={classNames("card-list-head", props.className)} css={css(carouselHeaderStyle)}>{props.children}</div>;
};
export default CarouselHeader;
