/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import Spinner from "../Spinner";
import { stripHrefDomain } from "../../helpers/misc";
import { actionButtonBtnsRow, actionButtonMainCaption, actionButtonWrapper, actionButtonMain, actionButtonMainPage, actionButtonMainTop, actionButtonMainEventItemCardAction, actionButtonMainEnabled, actionButtonMainDisabled, actionButtonMainCaptionEventItemCardAction, actionButtonWrapperBottom, actionButtonWrapperTop, actionButtonWrapperEventItemCardAction, actionButtonMainLarge, actionButtonMainYoutube } from "./styles";
import { InsiderLink } from "../InsiderLink";
const ChildButton = props => {
    let { children, onClick, loading, disabled, href, hrefTarget, hrefRel, className } = props;
    if (loading) {
        children = <Spinner />;
        onClick = e => e.preventDefault();
    }
    if (!disabled) {
        return (<a href={href} target={hrefTarget} rel={hrefRel} className={className} children={children} onClick={onClick}/>);
    }
    return <button className={props.className} children={children}/>;
};
class ActionButton extends Component {
    componentDidMount() {
        this.rerender = () => {
            this.forceUpdate();
        };
    }
    render() {
        let { favouriteButton, text, link, disabled, loading, htmlCaption, href, hrefTarget, hrefRel, hasPageModifier, hasTopModifier, hasBottomModifier, hasItemEventActionModifier, onClick, name, slug, wrapperStyle, buttonStyle, headerType } = this.props;
        const hasLargeModifier = headerType === "large";
        const hasYoutubeModifier = headerType === "youtube";
        return (<div css={css([
                actionButtonWrapper,
                hasTopModifier && actionButtonWrapperTop,
                hasBottomModifier && actionButtonWrapperBottom,
                hasItemEventActionModifier && actionButtonWrapperEventItemCardAction,
                wrapperStyle
            ])}>
				<span css={css([actionButtonMainCaption, hasItemEventActionModifier && actionButtonMainCaptionEventItemCardAction])} dangerouslySetInnerHTML={{ __html: htmlCaption }}/>
				<div css={css(actionButtonBtnsRow)}>
					{favouriteButton}
					<InsiderLink href={stripHrefDomain(href)}>
						<ChildButton css={css([
                actionButtonMain,
                hasPageModifier && actionButtonMainPage,
                hasTopModifier && actionButtonMainTop,
                hasItemEventActionModifier && actionButtonMainEventItemCardAction,
                !disabled && actionButtonMainEnabled,
                disabled && actionButtonMainDisabled,
                hasLargeModifier && actionButtonMainLarge,
                hasYoutubeModifier && actionButtonMainYoutube,
                buttonStyle
            ])} disabled={disabled} loading={loading} hrefTarget={hrefTarget} hrefRel={hrefRel} onClick={onClick}>
							{text}
						</ChildButton>
					</InsiderLink>
				</div>
			</div>);
    }
}
ActionButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    favouriteButton: PropTypes.element,
    htmlCaption: PropTypes.string,
    href: PropTypes.string,
    hrefRel: PropTypes.string,
    hrefTarget: PropTypes.string,
    hasPageModifier: PropTypes.bool,
    hasTopModifier: PropTypes.bool,
    hasBottomModifier: PropTypes.bool,
    hasItemEventActionModifier: PropTypes.bool,
    text: PropTypes.string.isRequired
};
ActionButton.defaultProps = {
    disabled: false,
    modifierClassName: ""
};
export default ActionButton;
