var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import Image from "./Image";
import GenreStrip from "./GenreStrip";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
const ImageCard = (_a) => {
    var { onClick, className, categoryName, categoryColour, shouldDisplayGenreStrip, imgClassName, href, titleClassName, titleCSS, infoClassName, infoCSS, title, imgCSS, cardPrefix } = _a, imgProps = __rest(_a, ["onClick", "className", "categoryName", "categoryColour", "shouldDisplayGenreStrip", "imgClassName", "href", "titleClassName", "titleCSS", "infoClassName", "infoCSS", "title", "imgCSS", "cardPrefix"]);
    return (<div className={className}>
		<InsiderLink href={href} onClick={onClick}>
			<Image {...imgProps} className={imgClassName} imgWidth={400} alt={title} imgCSS={imgCSS}/>
			<div css={css(infoCSS)} className={infoClassName}>
				{shouldDisplayGenreStrip && (<GenreStrip cardPrefix={cardPrefix} categoryName={categoryName} categoryColour={categoryColour}/>)}
				<span css={css(titleCSS)} className={titleClassName}>
					{title}
				</span>
			</div>
		</InsiderLink>
	</div>);
};
ImageCard.propTypes = Object.assign(Object.assign(Object.assign({}, Image.propTypes), GenreStrip.propTypes), { href: PropTypes.string.isRequired, imgClassName: PropTypes.string.isRequired, shouldDisplayGenreStrip: PropTypes.bool.isRequired, title: PropTypes.string, titleClassName: PropTypes.string, imgCSS: PropTypes.string, infoCSS: PropTypes.string, titleCSS: PropTypes.string, cardPrefix: PropTypes.string });
ImageCard.defaultProps = {
    shouldDisplayGenreStrip: true
};
export default ImageCard;
