import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
export const AccordionTitle = props => {
    const sectionTitleClassName = classNames(props.className, { expanded: props.isPanelShowing });
    return (<div role="button" onClick={props.toggleAccordion} className={sectionTitleClassName}>
			{props.children}
		</div>);
};
AccordionTitle.propTypes = {
    isPanelShowing: PropTypes.bool,
    className: PropTypes.string
};
AccordionTitle.defaultProps = {
    className: "accordion-title"
};
