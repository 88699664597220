/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Image from "./Image";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import { isH5Build, sendClevertapEvent } from "../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../helpers/constants/common";
import { mediaFeatures } from "../styles/common";
import { generateExternalBuyUrl } from "../helpers/linkGenerators";
const IMAGE_WIDTH = 220;
const IMAGE_WIDTHS = [160, 180, 220, 260, 320];
const IMAGE_HEIGHT = 160;
const IMAGE_STRING = `(max-width: 34.9em) 28vw, (max-width: 47.9em): 24.75vw, (max-width: 63.9em) 24.75vw, (max-width: 80em) 25vw, 180px`;
//Grid list size will be max 6 (managed from BE)
const itemStyle = size => css `
	margin: 5px 0;
	&:not(:last-child) {
		margin-right: ${size !== 6 ? "15px" : "0px"};
	}
	list-style-type: none;
`;
const ItemStyles = `
	display: block;
	width: 15%;
	height: auto;
	margin: 5px 0;

	@media screen and ${mediaFeatures["for-mobile-and-tablet"]} {
		width: 30%;
	}
`;
const CarouselImageLinkStyles = `
	display: block;
`;
const CardImageStyles = `
	width: 100%;
	height: 100%;
	border-radius: 6px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}
`;
const DigitalEventsCarouselItem = props => {
    const { url, imageUrl, listSize, title, resource_type, isFromApp, platform } = props;
    const fireCTEvent = e => {
        if (resource_type === "external") {
            e.preventDefault();
            let getUrl = generateExternalBuyUrl(url);
            if (isH5Build || isFromApp) {
                if (platform)
                    getUrl = getUrl.includes("?") ? `${getUrl}&platform=${platform}` : `${getUrl}?platform=${platform}`;
                window.location.href = getUrl;
            }
            else
                window.open(getUrl, "_blank");
        }
        const { tag } = props;
        sendClevertapEvent(CLEVERTAP_ACTIONS.DIGITAL_EVENTS_GRID_CLICK, {
            tag: tag,
            type: resource_type,
            title: title
        });
    };
    return (<li css={css(ItemStyles)}>
			<div>
				<InsiderLink css={css(CarouselImageLinkStyles)} href={url} onClick={fireCTEvent}>
					<Image css={css(CardImageStyles)} src={imageUrl} alt={title} imgWidth={IMAGE_WIDTH} imgWidths={IMAGE_WIDTHS} sizeString={IMAGE_STRING} lazy={false} height={IMAGE_HEIGHT}/>
				</InsiderLink>
			</div>
		</li>);
};
export default DigitalEventsCarouselItem;
