import React from "react";
const Christmas = ({ width = "48", height = "48", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24 21.5858L25.2929 20.2929C25.6834 19.9024 26.3166 19.9024 26.7071 20.2929C27.0976 20.6834 27.0976 21.3166 26.7071 21.7071L25 23.4142V26.1707C25.8524 26.472 26.528 27.1476 26.8293 28H29.5858L31.2929 26.2929C31.6834 25.9024 32.3166 25.9024 32.7071 26.2929C33.0976 26.6834 33.0976 27.3166 32.7071 27.7071L31.4142 29L32.7071 30.2929C33.0976 30.6834 33.0976 31.3166 32.7071 31.7071C32.3166 32.0976 31.6834 32.0976 31.2929 31.7071L29.5858 30H26.8293C26.528 30.8524 25.8524 31.528 25 31.8293V34.5858L26.7071 36.2929C27.0976 36.6834 27.0976 37.3166 26.7071 37.7071C26.3166 38.0976 25.6834 38.0976 25.2929 37.7071L24 36.4142L22.7071 37.7071C22.3166 38.0976 21.6834 38.0976 21.2929 37.7071C20.9024 37.3166 20.9024 36.6834 21.2929 36.2929L23 34.5858V31.8293C22.1476 31.528 21.472 30.8524 21.1707 30H18.4142L16.7071 31.7071C16.3166 32.0976 15.6834 32.0976 15.2929 31.7071C14.9024 31.3166 14.9024 30.6834 15.2929 30.2929L16.5858 29L15.2929 27.7071C14.9024 27.3166 14.9024 26.6834 15.2929 26.2929C15.6834 25.9024 16.3166 25.9024 16.7071 26.2929L18.4142 28H21.1707C21.472 27.1476 22.1476 26.472 23 26.1707V23.4142L21.2929 21.7071C20.9024 21.3166 20.9024 20.6834 21.2929 20.2929C21.6834 19.9024 22.3166 19.9024 22.7071 20.2929L24 21.5858ZM25 29C25 29.5523 24.5523 30 24 30C23.4477 30 23 29.5523 23 29C23 28.4477 23.4477 28 24 28C24.5523 28 25 28.4477 25 29Z" fill="#0C172F"/>
			<path d="M29.6568 24.7573C30.0473 24.3668 30.0473 23.7336 29.6568 23.3431C29.2663 22.9526 28.6331 22.9526 28.2426 23.3431L26.8284 24.7573C26.4379 25.1478 26.4379 25.781 26.8284 26.1715C27.2189 26.562 27.8521 26.562 28.2426 26.1715L29.6568 24.7573Z" fill="#0C172F"/>
			<path d="M19.7573 26.1716C20.1479 26.5621 20.781 26.5621 21.1716 26.1716C21.5621 25.7811 21.5621 25.1479 21.1716 24.7574L19.7573 23.3432C19.3668 22.9526 18.7337 22.9526 18.3431 23.3432C17.9526 23.7337 17.9526 24.3668 18.3431 24.7574L19.7573 26.1716Z" fill="#0C172F"/>
			<path d="M21.1715 31.8284C21.562 32.219 21.562 32.8521 21.1715 33.2427L19.7572 34.6569C19.3667 35.0474 18.7336 35.0474 18.343 34.6569C17.9525 34.2663 17.9525 33.6332 18.343 33.2427L19.7572 31.8284C20.1478 31.4379 20.7809 31.4379 21.1715 31.8284Z" fill="#0C172F"/>
			<path d="M28.2424 34.6569C28.633 35.0475 29.2661 35.0475 29.6567 34.6569C30.0472 34.2664 30.0472 33.6332 29.6567 33.2427L28.2424 31.8285C27.8519 31.438 27.2188 31.438 26.8282 31.8285C26.4377 32.219 26.4377 32.8522 26.8282 33.2427L28.2424 34.6569Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M23 12V7C23 6.44772 23.4477 6 24 6C24.5523 6 25 6.44772 25 7V12C26.6569 12 28 13.3431 28 15V16.627C33.2225 18.3141 37 23.2159 37 29C37 36.1797 31.1797 42 24 42C16.8203 42 11 36.1797 11 29C11 23.2159 14.7775 18.3141 20 16.627V15C20 13.3431 21.3431 12 23 12ZM23 14H25C25.5523 14 26 14.4477 26 15V16H22V15C22 14.4477 22.4477 14 23 14ZM23.9967 18C17.9231 18.0018 13 22.926 13 29C13 35.0751 17.9249 40 24 40C30.0751 40 35 35.0751 35 29C35 22.926 30.0769 18.0018 24.0033 18H23.9967Z" fill="#0C172F"/>
			<path d="M11 8C11.5523 8 12 8.44772 12 9V10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H12V13C12 13.5523 11.5523 14 11 14C10.4477 14 10 13.5523 10 13V12H9C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10H10V9C10 8.44772 10.4477 8 11 8Z" fill="#0C172F"/>
			<path d="M38 13C38 12.4477 37.5523 12 37 12C36.4477 12 36 12.4477 36 13V14H35C34.4477 14 34 14.4477 34 15C34 15.5523 34.4477 16 35 16H36V17C36 17.5523 36.4477 18 37 18C37.5523 18 38 17.5523 38 17V16H39C39.5523 16 40 15.5523 40 15C40 14.4477 39.5523 14 39 14H38V13Z" fill="#0C172F"/>
		</svg>);
};
export default Christmas;
