/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import CarouselWrapper from "./CarouselWrapper";
import CarouselHeader from "./CarouselHeader";
import Carousel from "./Carousel";
import DataCard from "../containers/DataCardWithSubscription";
import { CLEVERTAP_ACTIONS, NUM_CATEGORY_CARDS_TO_DISPLAY_ON_HOME_PAGE } from "../helpers/constants";
import { generateLinkForAllEvents } from "../helpers/linkGenerators";
import { colors, mediaFeatures } from "../styles/common";
import { TrendingIcon } from "./ui/icons/HomePageIcons/TrendingIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
import { sendClevertapEvent } from "../helpers/misc";
const AllEventsStyles = css `
	background-color: ${colors.white};
	color: black;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid ${colors.gray};
	border-radius 5px;
	min-width: 66px;
	  
	&:hover {
		pointer: cursor;
	}
`;
const MainHeaderStyles = css `
	display: flex;
`;
const Wrapper = css `
	height: 125vw;
	max-height: 500px;

	@media screen and (max-width: 300px) {
		height: 138vw;
	}

	@media screen and (min-width: 375px) {
		height: 118vw;
	}

	@media screen and (min-width: 425px) {
		height: 105vw;
	}

	@media screen and ${mediaFeatures["for-tablet"]} {
		height: 70vw;
	}

	@media screen and (min-width: 650px) {
		height: 60vw;
	}

	@media screen and (min-width: 750px) {
		height: 55vw;
	}

	@media screen and (min-width: 850px) {
		height: 50vw;
	}

	@media screen and ${mediaFeatures["for-pc"]} {
		height: 40vw;
	}

	@media screen and (min-width: 1200px) {
		height: 35vw;
	}

	@media screen and (min-width: 1440px) {
		height: 32vw;
	}

	ul {
		height: 115vw;
	}
`;
const TrendingCarouselViewMoreHandler = (selectedCity) => {
    sendClevertapEvent(CLEVERTAP_ACTIONS.CAROUSEL_VIEW_ALL_CLICKED, {
        carousel_name: "Step Out",
        city: selectedCity
    });
};
class TrendingCarousel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.eventCards = (events, categoryCardsNumber) => {
            const dataCards = events.slice(0, categoryCardsNumber).map((card, idx) => {
                return (<li className="carousel-item carousel-item-right" key={`category-carousel-card-${idx}`}>
					<DataCard key={`category-${idx}`} cardData={card}/>
				</li>);
            });
            return dataCards;
        };
    }
    render() {
        const { cards, selectedCity } = this.props;
        const allEventsLink = generateLinkForAllEvents(selectedCity);
        if (cards.get("events").length > 0) {
            const showViewAll = cards.get("events").length > 3;
            return (<div>
					<CarouselWrapper css={Wrapper} className="card-list-wrapper card-carousel" key={`step-out-carousel`}>
						<CarouselHeader css={MainHeaderStyles}>
							<CarouselTitleWithIcon title={"Trending Events"} IconComponent={TrendingIcon} showViewAll={showViewAll}/>
							{showViewAll && (<span css={AllEventsStyles}>
									<a href={allEventsLink} onClick={() => TrendingCarouselViewMoreHandler(selectedCity)}>
										View all
									</a>
								</span>)}
						</CarouselHeader>
						<Carousel numCarouselItems={3} listType="card">
							{this.eventCards(cards.get("events"), NUM_CATEGORY_CARDS_TO_DISPLAY_ON_HOME_PAGE)}
						</Carousel>
					</CarouselWrapper>
				</div>);
        }
        // });
        else {
            return null;
        }
    }
}
export default TrendingCarousel;
