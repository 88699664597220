/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Observer from "react-intersection-observer";
// components
import Carousel from "./Carousel";
import CarouselHeader from "../components/CarouselHeader";
import CarouselWrapper from "../components/CarouselWrapper";
import { RecentlyViewedEventCard } from "../components/RecentlyViewedEventCard";
import { colors } from "../styles/common";
import { RecentlyViewedIcon } from "./ui/icons/HomePageIcons/RecentlyViewedIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
// Emotion JS
const CarouselItemStyles = `
	position: relative;
	display: inline-block;
	width: 182px;
	height: 154px;
	margin-right: 24px;
	vertical-align: top;
	border-radius: 12px;
	border: 1px solid ${colors.faintGrey};
	background: white;
	padding: 6px;

	&:hover {
		border: 1px solid white;
		box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 10px 0px;
	}
`;
const CarouselWrapperStyles = `
	ul {
		white-space: nowrap;
		padding-bottom: 10px;
	}
`;
const DisplayObserverStyles = `
	height: 1px;
	width: 100%;
`;
const OverFlowStyles = `
	overflow: auto hidden;

	&::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	  }
`;
class RecentlyViewedEvents extends React.Component {
    recentlyViewedCards() {
        const { recentlyViewedEvents } = this.props;
        let cards = recentlyViewedEvents.map((rv, index) => {
            const slug = rv.get("slug");
            return (<li css={css(CarouselItemStyles)} key={slug}>
					<RecentlyViewedEventCard key={slug} eventData={rv} index={index}/>
				</li>);
        });
        return cards;
    }
    render() {
        const { recentlyViewedEvents } = this.props;
        if (!recentlyViewedEvents.size) {
            return null;
        }
        return (<Observer rootId="there-you-go" threshold={1} triggerOnce={true}>
				{inView => {
                // if recently viewed hasn't already been shown, show it
                if (inView) {
                    return (<CarouselWrapper className="card-list-wrapper" css={css(CarouselWrapperStyles)}>
								<CarouselHeader>
									<CarouselTitleWithIcon title={"Recently viewed"} IconComponent={RecentlyViewedIcon} subHeader={"Pick up where you left off"}/>
								</CarouselHeader>
								<div css={css(OverFlowStyles)}>
									<Carousel numCarouselItems={6} listType="card" carouselType="gallery">
										{this.recentlyViewedCards()}
									</Carousel>
								</div>
							</CarouselWrapper>);
                }
                else {
                    return <div css={css(DisplayObserverStyles)}/>;
                }
            }}
			</Observer>);
    }
}
export default RecentlyViewedEvents;
