import { medLetterSpacing } from "../base/padding";
import { colors, fastTransition, mediaFeatures, medPadding, stdPadding, subtextFontSize } from "../common";
import { splitColors } from "../splitColors";
import { icon } from "./icon";
import { iconFollow } from "./iconGlyps";
export const actionButton = `
    padding: ${medPadding} calc(${medPadding} * 1.5);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    text-align: center;
    font-weight: normal;
    border-radius: 2px;
    a {
        color: inherit;
    }
	@media screen and ${mediaFeatures["for-pc"]} {
        padding: ${medPadding} calc(${stdPadding} * 1.25);
    }
`;
export const actionButtonSmall = `
    ${subtextFontSize}
    padding: calc(${stdPadding} / 2) ${medPadding};
    font-weight: normal;
`;
export const primaryButton = `
    ${actionButton}
    letter-spacing: ${medLetterSpacing};
    background-color: ${splitColors["insiderBlue"]};
    color: ${colors["white"]};
    ${fastTransition}
    &:hover {
        color: ${colors["white"]};
        @media screen and ${mediaFeatures["for-pc"]} {
            transform: scale(1.05,1.05);
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: default;
        transform: none;
    }
`;
export const primaryHoverDarken = `
    &:hover{
        transform: none;
        &:not(:disabled):not(.spinning){
            background-color: #2889cd;
        }
    }
`;
export const primaryButtonSmallButton = `
    ${primaryHoverDarken}
    white-space: nowrap;
`;
export const primaryButtonSpinning = `
    cursor: default;
    position: relative;
    & > span{
        visibility: hidden;
    }
`;
export const secondaryButton = `
    ${actionButton}
    letter-spacing: ${medLetterSpacing};
    border: 1px solid ${colors["dark-gray"]};
    color: ${colors["dark-gray"]};
    a {
        color: inherit;
    }
    &:hover {
        border-color: ${splitColors["insiderBlue"]};
        color: ${splitColors["insiderBlue"]};
    }
`;
export const followButton = `
    ${primaryButton}
    ${primaryHoverDarken}
    &::before{
        ${icon}
        ${iconFollow}
        background-size: cover;
        margin-right: 2px;
        display: inline-block;
        vertical-align: middle;
    }
    @media screen and ${mediaFeatures["for-pc"]} {
        padding: 0 ${stdPadding};
        &::before {
            width: 13px;
            height: 18px;
        }
    }
`;
export const followButtonActive = `
    ${secondaryButton}
    background-color: ${colors["white"]};
    padding: 0 ${medPadding};
    @media screen and ${mediaFeatures["for-pc"]} {
        padding: 0 ${stdPadding};
    }
`;
export const disabledButton = `
    background: ${colors["lighter-gray"]};
    color: ${colors["gray"]};
    font-weight: normal;
`;
