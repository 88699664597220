/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import Image from "./Image";
import { generatePartnerProfileLink } from "../helpers/linkGenerators";
import { NO_DATA_CLASS, truncate } from "../helpers/constants";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import { tinyCard, tinyCardDetails, tinyCardDetailsNoData, tinyCardImage, tinyCardImageArtist, tinyCardImageNoData, tinyCardName, tinyCardNameNoData, tinyCardNoData, tinyCardSubtext } from "../styles/cardStyles";
const TINY_IMAGE_WIDTH = 200;
const TINY_IMAGE_WIDTHS = [150, 150, 200, 200];
const TINY_IMAGE_STRING = "(max-width:48em) 150px, 200px";
const WithEventLink = props => {
    // don't add the a tag if this is a shell
    if (props.cardData === undefined) {
        return <div className="no-data-wrapper">{props.children}</div>;
    }
    return (<InsiderLink activeClassName="active" href={generatePartnerProfileLink(props.cardData.get("slug"), props.cardData.get("type"))}>
			{props.children}
		</InsiderLink>);
};
const CardImage = props => {
    if (props.cardData === undefined) {
        return null;
    }
    return (<Image src={props.cardData.get("src")} alt={props.cardData.get("name")} imgWidth={TINY_IMAGE_WIDTH} imgWidths={TINY_IMAGE_WIDTHS} sizeString={TINY_IMAGE_STRING} lazy={true}/>);
};
class TinyCard extends React.PureComponent {
    render() {
        const applyNoDataCSS = this.props.cardData === undefined;
        let cardName = (this.props.cardData && this.props.cardData.get("name")) || "";
        let subText = (this.props.cardData && this.props.cardData.get("subtext")) || "";
        cardName = truncate(cardName, 70, null);
        subText = truncate(subText, 35, null);
        return (<div css={css([tinyCard, applyNoDataCSS && tinyCardNoData])}>
				<WithEventLink cardData={this.props.cardData}>
					<div css={css([
                tinyCardImage,
                this.props.type === "artist" && tinyCardImageArtist,
                applyNoDataCSS && tinyCardImageNoData
            ])}>
						<CardImage cardData={this.props.cardData}/>
						<div className="tiny-card-action"/>
					</div>
					<div css={css([tinyCardDetails, applyNoDataCSS && tinyCardDetailsNoData])}>
						<h5 css={css([tinyCardName, applyNoDataCSS && tinyCardNameNoData])}>{cardName}</h5>
						<span css={css(tinyCardSubtext)}>{subText}</span>
					</div>
				</WithEventLink>
				{this.props.children}
			</div>);
    }
}
TinyCard.propTypes = {
    cardData: PropTypes.object,
    isFromApp: PropTypes.bool,
    type: PropTypes.string
};
TinyCard.defaultProps = {
    type: ""
};
export default TinyCard;
