import React from "react";
export default class TrackingImage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            server: true,
            src: ""
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.state.server !== nextState.server;
    }
    componentDidMount() {
        let src = this.props.src;
        src && (src = `${src}${Date.now()}`);
        this.setState(() => {
            return {
                server: false,
                src: src
            };
        });
    }
    render() {
        const { src } = this.state;
        return (<div>
				<img src={src} border={0} height={0} width={0} alt="Advertisement" className={"advertisement hidden"}/>
			</div>);
    }
}
