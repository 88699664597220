import TimeFilterStrip from "../components/TimeFilterStrip";
import { connect } from "react-redux";
import { getDates, getSelectedCityName } from "../selectors/index";
const mapStateToProps = (state) => {
    return {
        dates: getDates(state),
        selectedCity: getSelectedCityName(state)
    };
};
export default connect(mapStateToProps)(TimeFilterStrip);
