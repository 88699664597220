var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import CarouselWrapper from "../CarouselWrapper";
import Carousel from "../Carousel";
import { useSwipeable } from "react-swipeable";
import InsiderLink from "../../containers/InsiderLinkWithSubscription";
import { Picture } from "../ui/Picture";
import { imageCacheLink } from "../../helpers/linkGenerators";
import { isH5Build, stripHrefDomain } from "../../helpers/misc";
import { mediaFeatures, colors, stripHeightMed } from "../../styles/common";
import TrackingImage from "../TrackingImage";
const BannerActionStyles = `
	min-height: ${stripHeightMed};
	color: ${colors.white};
`;
const ImageBannerStyles = `
	padding: 0;
	@media screen and ${mediaFeatures["for-tablet"]} {
		border-radius: 9px;
	}
`;
const TextActionBackgroundStyles = `
    width: 100%;
    position: relative;
    overflow: hidden;
	white-space: normal;
	${BannerActionStyles};
	${ImageBannerStyles};
`;
const TextActionImageStyles = `
	position: static;
	top: 0;
    bottom: 0;
    left: 0;
    background: ${colors.greyIconHeading};
	width: 100%;
	z-index: -1;
	img{
		display: block;
		width: 100%;
		height: auto;
	}
`;
const ClearFixStyles = `
	::before,
	::after {
		display: table;
		content: "";
	}
	::after {
		clear: both;
	}
`;
const CardListWrapperStyles = `
	${ClearFixStyles};
	@media screen and ${mediaFeatures["for-pc"]} {
		padding-top: 30px;
	}
	margin-bottom: 3.5vw;
    background-color: ${colors.primaryDark};
    overflow: hidden;
	clear: both;
	display: block;
	position: relative;
	width: 100%;
	::before {
		content: " ";
    	display: table;
	}
`;
const Swipeable = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const handlers = useSwipeable(props);
    return <div {...handlers}>{children}</div>;
};
export default class BannerCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            server: true
        };
        this.carousel = React.createRef();
    }
    componentDidMount() {
        this.setState(() => {
            return {
                server: false
            };
        });
    }
    bannerItem(banner, lazy) {
        const mobileBanner = banner.get("vertical_cover_image");
        const trackingImage = banner.get("trackingImage");
        return (<div css={css(TextActionBackgroundStyles)}>
				<Picture className="" css={css(TextActionImageStyles)} src={imageCacheLink(mobileBanner, { w: 560 })} alt={banner.get("description")} lazy={lazy} sources={[{ media: "(min-width: 1280px)", srcSet: imageCacheLink(mobileBanner, { w: 2000 }) }]}/>
				{trackingImage && <TrackingImage src={trackingImage}/>}
			</div>);
    }
    render() {
        const { banners, emptyStateComponent = null, bannerClickedEvent } = this.props;
        if (banners.size === 0) {
            return emptyStateComponent;
        }
        const displayBanners = banners.map((banner, i) => {
            // @ts-ignore
            const lazy = !(i !== 0 || i !== 1 || i !== banners.length() - 1); // Dont lazy load the first, second and last banner images
            if (banner.getIn(["display_details", "link_type"], "external") === "external") {
                return (<div key={banner.get("_id")}>
						<a target={(isH5Build || this.props.isFromApp) ? undefined : "_blank"} href={stripHrefDomain(banner.get("map_link"))} onClick={bannerClickedEvent(i + 1, banner)}>
							{this.bannerItem(banner, lazy)}
						</a>
					</div>);
            }
            return (<div key={banner.get("_id")} onClick={bannerClickedEvent(i, banner)}>
					<InsiderLink href={stripHrefDomain(banner.get("map_link"))}>{this.bannerItem(banner, lazy)}</InsiderLink>
				</div>);
        });
        return (<Swipeable trackMouse style={{ touchAction: "none" }} preventDefaultTouchmoveEvent onSwipedRight={() => {
                this.carousel.moveCarouselPrevious();
                this.carousel.resetAutoPlay();
            }} onSwipedLeft={() => {
                this.carousel.moveCarouselNext();
                this.carousel.resetAutoPlay();
            }}>
				<CarouselWrapper css={css(CardListWrapperStyles)} className="banner-carousel">
					<Carousel ref={el => (this.carousel = el)} numCarouselItems={1} listType="card" showDots autoPlay={displayBanners.size > 1} autoPlayDelay={4500} infiniteScrolling carouselType="banner">
						{displayBanners}
					</Carousel>
				</CarouselWrapper>
			</Swipeable>);
    }
}
BannerCarousel.defaultProps = {
    bannerClickedEvent: () => { }
};
