import { connect } from "react-redux";
// components
import InsiderLinkWithActiveOverride from "../components/InsiderLinkWithActiveOverride";
// filter out all disliked events
const mapStateToProps = (state, ownProps) => ({
    activeClassName: ownProps.activeClassName,
    href: ownProps.href,
    isSPA: Boolean(state.getIn(["uiState", "isSPA"]))
});
const InsiderLinkWithActiveOverrideWithSubscription = connect(mapStateToProps, {})(InsiderLinkWithActiveOverride);
export default InsiderLinkWithActiveOverrideWithSubscription;
