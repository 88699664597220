import React from "react";
import { toggleFavouriteForEvent } from "../../helpers/apiClient";
import FavouriteButton from "../FavouriteButton";
import { addGAEvent, CLEVERTAP_ACTIONS, GA_ACTIONS, GA_RECOMMENDED_EVENTS, GENERAL_ACTIONS, HIT_TYPES } from "../../helpers/constants";
import { sendClevertapEvent, truncateString } from "../../helpers/misc";
// Define constants used in metrics reporting + general favouriting logic
const USERS_ROUTE = "/users", LOGGED_IN = "logged in", LOGGED_OUT = "logged out", EVENT_TYPE = "Event", { FAVOURITE, SEND, UNFAVOURITE } = GENERAL_ACTIONS, { FAVOURITE_EVENT, UNFAVOURITE_EVENT } = GA_ACTIONS, { FAVOURITE_BUTTON_CLICK, FAVOURITE_NOTIFICATION } = CLEVERTAP_ACTIONS;
export default class Favourite extends React.Component {
    constructor(props) {
        super(props);
        this.showCount = () => {
            const { favCount, showFavouriteCount, isFavourited } = this.props;
            return showFavouriteCount(favCount, isFavourited);
        };
        this.clickFavouriteButton = this.clickFavouriteButton.bind(this);
        this.loginCallback = this.loginCallback.bind(this);
        this.state = {
            showAnimation: false,
            favouriteClicked: false
        };
    }
    componentDidMount() {
        const { favStats, modifierClass, fetchFavouriteStatsById, slug, _id } = this.props;
        if (!favStats && modifierClass && _id) {
            fetchFavouriteStatsById(_id, slug);
        }
    }
    componentDidUpdate(prevProps) {
        const { isFromApp, isLoggedIn } = this.props;
        const { favouriteClicked } = this.state;
        if (isFromApp && favouriteClicked && !prevProps.isLoggedIn && isLoggedIn) {
            this.toggleFavourite(true);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
            return true;
        }
        if (this.props.isFavourited !== nextProps.isFavourited) {
            return true;
        }
        if (this.state.showAnimation !== nextState.showAnimation) {
            return true;
        }
        if (this.props.favCount !== nextProps.favCount) {
            return true;
        }
        return false;
    }
    clickFavouriteButton(e) {
        const { context, isFavourited, isLoggedIn } = this.props, { ga, location } = window, { pathname } = location, action = isFavourited ? UNFAVOURITE : FAVOURITE, showAnimation = isLoggedIn ? (isFavourited ? false : true) : false;
        e.preventDefault();
        this.setState({ showAnimation: showAnimation, favouriteClicked: true });
        // send GA (Google Analytics) + CleverTap Objects
        ga &&
            ga(SEND, {
                hitType: HIT_TYPES.EVENT,
                eventCategory: FAVOURITE_EVENT,
                eventAction: action,
                eventLabel: pathname
            });
        addGAEvent(GA_RECOMMENDED_EVENTS.ADD_TO_WISHLIST, {
            items: [
                {
                    item_id: this.props._id,
                    item_name: this.props.eventName
                }
            ]
        });
        sendClevertapEvent(FAVOURITE_BUTTON_CLICK, {
            action: action,
            location: context,
            url: pathname
        });
        return this.determineFavouriteAction();
    }
    async determineFavouriteAction() {
        const { isLoggedIn } = this.props;
        if (this.props.isFromApp) {
            await this.props.syncUserInfoForPhoenix();
            return this.toggleFavourite();
        }
        if (isLoggedIn) {
            return this.toggleFavourite();
        }
        return this.openFavouritesLoginModal();
    }
    loginCallback(userData) {
        const { resetModalType, setLoginError, setUserLoginData } = this.props;
        document.documentElement.classList.remove("no-scroll");
        resetModalType();
        setUserLoginData(userData);
        setLoginError("");
        return this.toggleFavourite(true);
    }
    openFavouritesLoginModal(modalText = FAVOURITE) {
        const { loginCallback, props } = this;
        const { context, setModalSource, setModalType } = props;
        setModalSource("edp");
        setModalType({
            modalType: modalText,
            loginText: modalText,
            context: context,
            loginCallback: loginCallback
        });
    }
    toggleFavourite(justLoggedIn = false) {
        const self = this, { _id, eventData, favouriteEvent, isFavourited, isLoggedIn, unfavouriteEvent, eventShowStartDate } = self.props, { ga, location } = window, user = isLoggedIn ? LOGGED_IN : LOGGED_OUT;
        // determine which action to take
        const noActionRequired = justLoggedIn && isFavourited;
        const action = noActionRequired || !isFavourited ? FAVOURITE : UNFAVOURITE;
        const gaAction = action === FAVOURITE ? FAVOURITE_EVENT : UNFAVOURITE_EVENT;
        const sendData = () => {
            sendClevertapEvent(FAVOURITE_BUTTON_CLICK, {
                action: action,
                user: user
            });
            if (ga) {
                ga(SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: FAVOURITE,
                    eventAction: gaAction,
                    eventLabel: location.pathname
                });
            }
        };
        // nothing to do, just send required events since the action was performed
        if (noActionRequired) {
            sendData();
        }
        else {
            if (isLoggedIn) {
                if (action === FAVOURITE) {
                    eventShowStartDate &&
                        sendClevertapEvent(FAVOURITE_NOTIFICATION, {
                            "Show start time": eventShowStartDate,
                            "Event Link": eventData.get("redirect_url"),
                            "Event name": eventData.get("name"),
                            "Event slug": eventData.get("slug"),
                            EventNameTrunc: truncateString(57, eventData.get("name")),
                            "Category name": eventData.getIn(["category_id", "name"])
                        });
                    favouriteEvent(eventData);
                }
                else if (action === UNFAVOURITE) {
                    unfavouriteEvent();
                }
                // POST a new unfavourite request with proper action type and params
                const body = JSON.stringify({
                    target_id: _id || eventData.get("_id"),
                    action: FAVOURITE,
                    target_type: EVENT_TYPE,
                    user: user
                });
                toggleFavouriteForEvent(action, body).then(response => {
                    sendData();
                    // error happened so reverse the changes
                    if (response.status >= 400) {
                        if (action === FAVOURITE) {
                            unfavouriteEvent();
                        }
                        else if (action === UNFAVOURITE) {
                            favouriteEvent();
                        }
                    }
                });
            }
        }
    }
    render() {
        const { clickFavouriteButton, props } = this, { fetchFavouriteStatsById, favStats, ctaLocation, isFavourited, showAnimation = this.state.showAnimation, modifierClass, slug, showButton, _id } = props;
        return (<FavouriteButton _id={_id} fetchFavouriteStatsById={fetchFavouriteStatsById} slug={slug} favStats={favStats} clickFavourite={clickFavouriteButton} ctaLocation={ctaLocation} isFavourited={isFavourited} 
        // state.showAnimation ensures the animation doesn't show on page refresh only on click
        showAnimation={showAnimation && this.state.showAnimation} showButton={showButton} modifierClass={modifierClass} showCount={this.showCount}/>);
    }
}
Favourite.defaultProps = {
    showFavouriteCount: (count) => (count ? `${count}` : ``)
};
