import React from "react";
import { withRouter } from "react-router";
import { InsiderLink } from "./InsiderLink";
import { TIME_FILTER_REGEX } from "../helpers/constants/common";
const extractFirstPath = (str) => {
    let pathEndIndex = str.indexOf("/");
    let queryStartIndex = str.indexOf("?");
    let res = pathEndIndex > -1 ? str.slice(0, pathEndIndex) : str;
    return queryStartIndex > -1 && pathEndIndex > -1 ? res + `?${str.slice(queryStartIndex)}` : res;
};
const overrideCheck = (path, queryString, href, matchWithoutQueryParams, doExactMatch = false, matchWithoutTimeFilter = false, matchOnlyFirstPath = false) => {
    if (doExactMatch) {
        return path + queryString === href;
    }
    let pathname = path.indexOf("/") === 0 ? path.substr(1) : path;
    let link = href.indexOf("/") === 0 ? href.substr(1) : href;
    if (matchWithoutTimeFilter) {
        pathname = pathname.replace(TIME_FILTER_REGEX, "");
        link = link.replace(TIME_FILTER_REGEX, "");
    }
    if (matchOnlyFirstPath) {
        pathname = extractFirstPath(pathname);
        link = extractFirstPath(link);
    }
    if (matchWithoutQueryParams) {
        pathname = pathname.split("?")[0];
        link = link.split("?")[0];
        return pathname === link;
    }
    pathname += queryString;
    return pathname.indexOf(link) === 0;
};
let InsiderLinkWithActiveOverride = props => {
    const { activeClassName, activeOverride, className, children, href, isSPA, location, onClick, matchWithoutQueryParams = false, doExactMatch = false, doAutoLinkActivation, matchWithoutTimeFilter = false, matchOnlyFirstPath = false } = props;
    const { pathname, search: queryString } = location;
    const shouldOverride = Boolean(activeOverride &&
        pathname &&
        overrideCheck(pathname, queryString, href, matchWithoutQueryParams, doExactMatch, matchWithoutTimeFilter, matchOnlyFirstPath));
    const updatedClassName = shouldOverride ? `${className} ${activeClassName}` : className;
    return (<InsiderLink activeClassName={activeClassName} className={updatedClassName} href={href} isSPA={isSPA} onClick={onClick} doAutoLinkActivation={doAutoLinkActivation}>
			{children}
		</InsiderLink>);
};
export default withRouter(InsiderLinkWithActiveOverride);
