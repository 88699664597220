import { mediaFeatures, stdPadding } from "../common";
import { gutter } from "./functions";
export const bigPadding = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        padding${direction ? `-${direction}` : ""}: 8vw;
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        padding${direction ? `-${direction}` : ""}: 5.5vw;
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        padding${direction ? `-${direction}` : ""}: 3.5vw;
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        padding${direction ? `-${direction}` : ""}: calc(${stdPadding} * 3);
    }
`;
export const smallPadding = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        padding${direction ? `-${direction}` : ""}: 4vw;
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        padding${direction ? `-${direction}` : ""}: 2.75vw;
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        padding${direction ? `-${direction}` : ""}: 1.75vw;
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        padding${direction ? `-${direction}` : ""}: calc(${stdPadding} * 1.5);
    }
`;
export const medLetterSpacing = "0.5px";
export const bigLetterSpacing = "1px";
export const gutterPadding = (direction = "") => `
    @media screen and ${mediaFeatures["for-mobile"]} {
        padding${direction ? `-${direction}` : ""}: ${gutter("mobile")}vw;
    }

    @media screen and ${mediaFeatures["for-tablet"]} {
        padding${direction ? `-${direction}` : ""}: ${gutter("tablet")}vw;
    }

    @media screen and ${mediaFeatures["for-pc"]} {
        padding${direction ? `-${direction}` : ""}: ${gutter("pc")}vw;
    }

    @media screen and ${mediaFeatures["for-hd"]} {
        padding${direction ? `-${direction}` : ""}: ${gutter("hd")}px;
    }
`;
