import React from "react";
export const Festivals = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M18.9839 7.17886C19.0827 6.63548 18.7223 6.1149 18.1789 6.01611C17.6355 5.91731 17.115 6.27771 17.0162 6.82109L15.0162 17.8211C14.9174 18.3645 15.2778 18.8851 15.8211 18.9839C16.3645 19.0827 16.8851 18.7223 16.9839 18.1789L18.9839 7.17886Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9.25785 41.7587C7.25122 42.6933 5.30667 40.7488 6.24131 38.7421L13.3438 23.4933C14.0658 21.9433 16.0748 21.5 17.2142 22.6394L25.3606 30.7858C26.5 31.9252 26.0567 33.9342 24.5067 34.6562L9.25785 41.7587ZM14.9762 24.7255L15.1568 24.3378C15.2423 24.1543 15.397 24.0454 15.5534 24.0109C15.7051 23.9774 15.7706 24.0242 15.8 24.0536L23.9463 32.2C23.9758 32.2294 24.0226 32.2949 23.9891 32.4466C23.9546 32.603 23.8457 32.7577 23.6622 32.8432L19.539 34.7637L14.9762 24.7255ZM13.8618 27.1181L12.0731 30.9583L14.675 37.0292L17.7218 35.6101L13.8618 27.1181ZM8.0543 39.5866L10.9367 33.3981L12.856 37.8764L8.41341 39.9457C8.27562 40.0099 8.19774 40.0018 8.1681 39.9964C8.12908 39.9893 8.09189 39.9703 8.06082 39.9392C8.02974 39.9081 8.01071 39.8709 8.00358 39.8319C7.99816 39.8023 7.99012 39.7244 8.0543 39.5866Z" fill="#0C172F"/>
			<path d="M41.9839 29.8211C42.0827 30.3644 41.7223 30.885 41.1789 30.9838L30.1789 32.9838C29.6355 33.0826 29.115 32.7222 29.0162 32.1788C28.9174 31.6355 29.2778 31.1149 29.8212 31.0161L40.8211 29.0161C41.3645 28.9173 41.8851 29.2777 41.9839 29.8211Z" fill="#0C172F"/>
			<path d="M40.7071 8.7071C41.0976 8.31658 41.0976 7.68341 40.7071 7.29289C40.3166 6.90237 39.6834 6.90237 39.2929 7.29289L27.2929 19.2929C26.9024 19.6834 26.9024 20.3166 27.2929 20.7071C27.6834 21.0976 28.3166 21.0976 28.7071 20.7071L40.7071 8.7071Z" fill="#0C172F"/>
			<path d="M19.5858 21.4142C20.3668 22.1953 21.6332 22.1953 22.4142 21.4142C23.1953 20.6332 23.1953 19.3668 22.4142 18.5858C21.6332 17.8047 20.3668 17.8047 19.5858 18.5858C18.8047 19.3668 18.8047 20.6332 19.5858 21.4142Z" fill="#0C172F"/>
			<path d="M40.4142 19.4142C39.6332 20.1953 38.3668 20.1953 37.5858 19.4142C36.8047 18.6332 36.8047 17.3668 37.5858 16.5858C38.3668 15.8047 39.6332 15.8047 40.4142 16.5858C41.1953 17.3668 41.1953 18.6332 40.4142 19.4142Z" fill="#0C172F"/>
			<path d="M25.2469 6.97727C25.5698 6.37753 26.4301 6.37753 26.753 6.97727L27.0757 7.57659C27.1551 7.72396 27.276 7.84481 27.4233 7.92417L28.0226 8.24687C28.6224 8.56982 28.6224 9.43009 28.0226 9.75303L27.4233 10.0757C27.276 10.1551 27.1551 10.2759 27.0757 10.4233L26.753 11.0226C26.4301 11.6224 25.5698 11.6224 25.2469 11.0226L24.9242 10.4233C24.8448 10.2759 24.724 10.1551 24.5766 10.0757L23.9773 9.75303C23.3775 9.43009 23.3775 8.56982 23.9773 8.24687L24.5766 7.92417C24.724 7.84481 24.8448 7.72396 24.9242 7.57659L25.2469 6.97727Z" fill="#0C172F"/>
			<path d="M31.753 22.9773C31.4301 22.3775 30.5698 22.3775 30.2469 22.9773L29.9242 23.5766C29.8448 23.724 29.724 23.8448 29.5766 23.9242L28.9773 24.2469C28.3775 24.5698 28.3775 25.4301 28.9773 25.753L29.5766 26.0757C29.724 26.1551 29.8448 26.2759 29.9242 26.4233L30.2469 27.0226C30.5698 27.6224 31.4301 27.6224 31.753 27.0226L32.0757 26.4233C32.1551 26.2759 32.2759 26.1551 32.4233 26.0757L33.0226 25.753C33.6224 25.4301 33.6224 24.5698 33.0226 24.2469L32.4233 23.9242C32.2759 23.8448 32.1551 23.724 32.0757 23.5766L31.753 22.9773Z" fill="#0C172F"/>
		</svg>);
};
