import React from "react";
import { InsiderLink } from "../../../../InsiderLink";
import Image from "../../../Atoms/Image";
/**
 *
 * Clickable image banner
 *
 * @param {string} image - The source URL of the image to be displayed in the banner.
 * @param {string} link - The target URL the user will be directed to after clicking the banner.
 * @param {boolean} [openInNewTab] - A flag indicating whether the link should open in a new tab (default: false).
 * @param {string} [imageCss] - Additional CSS styles to be applied to the image.
 * @param {function} [onClick] - A callback function to be executed when the banner is clicked.
 * @returns {ReactElement} ImageBanner component.
 */
const ImageBanner = ({ image, link, openInNewTab, imageCss = "", onClick }) => {
    const isInsiderLink = link.includes("insider.in");
    return (<InsiderLink href={link} target={openInNewTab ? "_blank" : undefined} isSPA={!isInsiderLink} onClick={onClick}>
			<Image src={image} style={imageCss + "cursor: pointer;"}/>
		</InsiderLink>);
};
export default ImageBanner;
