/** @jsx jsx */
import { css, jsx } from "@emotion/core";
export const GenreIconStripIcon = (props) => {
    return (<svg css={css(props.cssStyle)} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33333 4.75562C5.49238 4.75562 4 6.248 4 8.08895V12.7556C4 14.5966 5.49238 16.0889 7.33333 16.0889H12C13.8409 16.0889 15.3333 14.5966 15.3333 12.7556V8.08895C15.3333 6.248 13.841 4.75562 12 4.75562H7.33333ZM5.33333 8.08895C5.33333 6.98438 6.22876 6.08895 7.33333 6.08895H12C13.1046 6.08895 14 6.98438 14 8.08895V12.7556C14 13.8602 13.1046 14.7556 12 14.7556H7.33333C6.22876 14.7556 5.33333 13.8602 5.33333 12.7556V8.08895Z" fill="#0C172F"/>
			<path d="M5.33333 20.7556C5.33333 19.651 6.22876 18.7556 7.33333 18.7556H11.3333C11.7015 18.7556 12 18.4571 12 18.0889C12 17.7208 11.7015 17.4223 11.3333 17.4223H7.33333C5.49238 17.4223 4 18.9147 4 20.7556V25.4223C4 27.2632 5.49238 28.7556 7.33333 28.7556H12C13.8409 28.7556 15.3333 27.2632 15.3333 25.4223V18.0889C15.3333 17.7208 15.0349 17.4223 14.6667 17.4223C14.2985 17.4223 14 17.7208 14 18.0889V25.4223C14 26.5269 13.1046 27.4223 12 27.4223H7.33333C6.22876 27.4223 5.33333 26.5269 5.33333 25.4223V20.7556Z" fill="#0C172F"/>
			<path d="M18 15.4223V8.08895C18 6.98438 18.8954 6.08895 20 6.08895L24.6667 6.08895C25.7712 6.08895 26.6667 6.98438 26.6667 8.08895V12.7556C26.6667 13.8602 25.7712 14.7556 24.6667 14.7556H20.6667C20.2985 14.7556 20 15.0541 20 15.4223C20 15.7905 20.2985 16.0889 20.6667 16.0889H24.6667C26.5076 16.0889 28 14.5966 28 12.7556V8.08895C28 6.248 26.5076 4.75562 24.6667 4.75562H20C18.1591 4.75562 16.6667 6.248 16.6667 8.08895V15.4223C16.6667 15.7905 16.9651 16.0889 17.3333 16.0889C17.7015 16.0889 18 15.7905 18 15.4223Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M20 17.4223C18.1591 17.4223 16.6667 18.9147 16.6667 20.7556V25.4223C16.6667 27.2632 18.1591 28.7556 20 28.7556H24.6667C26.5076 28.7556 28 27.2632 28 25.4223V20.7556C28 18.9147 26.5076 17.4223 24.6667 17.4223H20ZM18 20.7556C18 19.651 18.8954 18.7556 20 18.7556H24.6667C25.7712 18.7556 26.6667 19.651 26.6667 20.7556V25.4223C26.6667 26.5269 25.7712 27.4223 24.6667 27.4223H20C18.8954 27.4223 18 26.5269 18 25.4223V20.7556Z" fill="#0C172F"/>
		</svg>);
};
