import React from "react";
import { connect } from "react-redux";
import Favourite from "../components/Favourite";
import { fetchFavouriteStatsById, favouriteEvent, fetchActiveFavourites, fetchPastFavourites, resetModalType, setLoginError, setModalSource, setModalType, setUserLoginData, unfavouriteEvent, syncUserInfoForPhoenix } from "../actions/index";
import { getEventDetails, getFavCount } from "../selectors/events";
import { isUserLoggedIn } from "../selectors/index";
import { hasFetchedFavouriteEvents, isFavouritedEvent } from "../selectors/favourites";
import { getNextAvailableShowStartTime } from "../selectors/events/common";
const mapStateToProps = (state, ownProps) => {
    return {
        eventData: getEventDetails(state, ownProps.slug),
        isFavourited: isFavouritedEvent(state, ownProps),
        isLoggedIn: isUserLoggedIn(state),
        favCount: getFavCount(state, ownProps),
        eventShowStartDate: getNextAvailableShowStartTime(state, ownProps.slug)
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchFavouriteStatsById: (eventId, slug) => dispatch(fetchFavouriteStatsById({ eventId, slug })),
        favouriteEvent: event => dispatch(favouriteEvent(event)),
        fetchActiveFavourites: () => dispatch(fetchActiveFavourites()),
        fetchPastFavourites: () => dispatch(fetchPastFavourites()),
        resetModalType: () => dispatch(resetModalType()),
        setLoginError: msg => dispatch(setLoginError(msg)),
        setModalSource: source => dispatch(setModalSource(source)),
        setModalType: payload => dispatch(setModalType(payload)),
        setUserLoginData: userInfo => dispatch(setUserLoginData(userInfo)),
        unfavouriteEvent: () => dispatch(unfavouriteEvent(ownProps.slug)),
        syncUserInfoForPhoenix: () => dispatch(syncUserInfoForPhoenix())
    };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), ownProps);
};
const FavouriteWithSubscription = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Favourite);
export default FavouriteWithSubscription;
