import React from "react";
class CarouselWrapper extends React.Component {
    constructor(props) {
        super(props);
    }
    renderChildren() {
        return React.Children.map(this.props.children, child => React.cloneElement(child));
    }
    render() {
        return <section className={this.props.className} date-ref={this.props.dataRef}>{this.renderChildren()}</section>;
    }
}
export default CarouselWrapper;
