import React from "react";
const NyeParties = ({ width = "48", height = "48", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M24 7C24.5522 7 24.9998 7.44292 24.9998 7.9893V10.9572C24.9998 11.5036 24.5522 11.9465 24 11.9465C23.4478 11.9465 23.0002 11.5036 23.0002 10.9572V7.9893C23.0002 7.44292 23.4478 7 24 7Z" fill="#0C172F"/>
			<path d="M30.2486 9.30995C30.7268 9.58313 30.8906 10.1882 30.6145 10.6614L29.1149 13.2316C28.8388 13.7048 28.2273 13.8669 27.7492 13.5937C27.271 13.3205 27.1071 12.7155 27.3832 12.2423L28.8829 9.67206C29.159 9.19888 29.7704 9.03676 30.2486 9.30995Z" fill="#0C172F"/>
			<path d="M17.1355 10.4625C16.8594 9.98937 17.0233 9.38433 17.5015 9.11114C17.9796 8.83795 18.5911 9.00007 18.8672 9.47325L20.3668 12.0435C20.6429 12.5167 20.4791 13.1217 20.0009 13.3949C19.5227 13.6681 18.9113 13.506 18.6352 13.0328L17.1355 10.4625Z" fill="#0C172F"/>
			<path d="M16.7443 24.1752C17.2777 24.3166 17.8259 24.0034 17.9688 23.4757C18.1117 22.9479 17.7952 22.4054 17.2619 22.264C16.7285 22.1226 16.1803 22.4358 16.0374 22.9636C15.8945 23.4913 16.211 24.0338 16.7443 24.1752Z" fill="#0C172F"/>
			<path d="M15.0022 26.7859C14.8593 27.3137 14.3111 27.6269 13.7777 27.4855C13.2444 27.3441 12.9279 26.8016 13.0708 26.2738C13.2137 25.7461 13.7619 25.4329 14.2953 25.5743C14.8286 25.7157 15.1451 26.2582 15.0022 26.7859Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2102 14.5507C15.3531 14.023 15.0366 13.4805 14.5032 13.3391C13.9699 13.1977 13.4217 13.5109 13.2787 14.0386L10.1736 25.5057C9.54769 27.8172 10.6844 30.1851 12.7684 31.2122L11.19 37.0413L9.25879 36.5293C8.72544 36.3879 8.17722 36.7011 8.03431 37.2288C7.89141 37.7566 8.20792 38.299 8.74126 38.4404L11.6271 39.2056L11.6381 39.2086L11.6492 39.2115L14.5355 39.9767C15.0689 40.1182 15.6171 39.805 15.76 39.2772C15.9029 38.7494 15.5864 38.207 15.053 38.0656L13.1214 37.5534L14.6998 31.7243C17.0237 31.8661 19.2048 30.3778 19.8307 28.0662L22.9358 16.5991C23.0787 16.0713 22.7622 15.5289 22.2289 15.3875C21.6955 15.2461 21.1473 15.5592 21.0044 16.087L20.2281 18.9538L14.4339 17.4175L15.2102 14.5507ZM17.8993 27.5541L19.7106 20.865L13.9164 19.3287L12.105 26.0178C11.6763 27.6011 12.6258 29.2285 14.2259 29.6527C15.8259 30.0769 17.4706 29.1374 17.8993 27.5541Z" fill="#0C172F"/>
			<path d="M31.2557 25.1645C30.7223 25.3059 30.1741 24.9927 30.0312 24.465C29.8883 23.9372 30.2048 23.3947 30.7381 23.2533C31.2715 23.1119 31.8197 23.4251 31.9626 23.9529C32.1055 24.4806 31.789 25.0231 31.2557 25.1645Z" fill="#0C172F"/>
			<path d="M34.2223 28.4748C33.6889 28.6162 33.1407 28.303 32.9978 27.7752C32.8549 27.2475 33.1714 26.705 33.7048 26.5636C34.2381 26.4222 34.7863 26.7354 34.9292 27.2631C35.0721 27.7909 34.7556 28.3334 34.2223 28.4748Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M32.7898 15.54C32.6469 15.0122 32.9634 14.4698 33.4968 14.3284C34.0301 14.187 34.5783 14.5002 34.7213 15.0279L37.8264 26.495C38.4523 28.8065 37.3156 31.1744 35.2316 32.2015L36.81 38.0306L38.7412 37.5186C39.2746 37.3772 39.8228 37.6903 39.9657 38.2181C40.1086 38.7459 39.7921 39.2883 39.2587 39.4297L36.3726 40.195L36.3619 40.1979L36.3511 40.2007L33.4645 40.966C32.9311 41.1075 32.3829 40.7943 32.24 40.2665C32.0971 39.7387 32.4136 39.1963 32.947 39.0549L34.8786 38.5427L33.3002 32.7136C30.9763 32.8554 28.7952 31.367 28.1693 29.0555L25.0642 17.5884C24.9213 17.0606 25.2378 16.5182 25.7711 16.3768C26.3045 16.2354 26.8527 16.5485 26.9956 17.0763L27.7719 19.9431L33.5661 18.4068L32.7898 15.54ZM35.895 27.0071L34.0836 20.3179L28.2894 21.8542L30.1007 28.5434C30.5294 30.1266 32.1741 31.0662 33.7741 30.642C35.3742 30.2178 36.3237 28.5904 35.895 27.0071Z" fill="#0C172F"/>
		</svg>);
};
export default NyeParties;
