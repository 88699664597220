import React from "react";
import FeaturedCarousel from "../components/FeaturedCarousel";
import { connect } from "react-redux";
import { getCapitalizedSelectedCityName, getFeaturedData, getFeaturedStayInData } from "../selectors/index";
import { getDisplayMode } from "../selectors/homepage";
const mapStateToProps = state => {
    const cards = getDisplayMode(state) === "go-out" ? getFeaturedData(state) : getFeaturedStayInData(state);
    return {
        title: "Featured events",
        description: `The most happening events in ${getCapitalizedSelectedCityName(state)}`,
        cards: cards,
        selectedCity: getCapitalizedSelectedCityName(state)
    };
};
export default connect(mapStateToProps, null)(FeaturedCarousel);
