import { connect } from "react-redux";
import { getHomePageEventsByGenre, getSelectedCityName } from "../selectors/index";
import CategoryIconStrip from "../components/CategoryIconStrip";
import { fetchGooutELPResourcesWithoutPopular } from "../actions";
import { getELPDataReceived, getELPDataRequested } from "../selectors/elp";
const mapStateToProps = (state) => ({
    categories: getHomePageEventsByGenre(state),
    selectedCity: getSelectedCityName(state),
    elpDataRequested: getELPDataRequested(state),
    elpDataReceived: getELPDataReceived(state)
});
const mapDispatchToProps = (dispatch) => ({
    getELPData: (city) => dispatch(fetchGooutELPResourcesWithoutPopular(city))
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoryIconStrip);
