import { hdColumns, hdColumnWidth, hdGutterWidth, mobileColumns, mobileColumnWidth, mobileGutterWidth, narrowHdColumns, narrowPcColumns, pcColumns, pcColumnWidth, pcGutterWidth, tabletColumns, tabletColumnWidth, tabletGutterWidth } from "./variables";
export const size = (viewport, type, columns, widthType = "normal") => {
    let width = 88;
    let gutters = 0;
    if (widthType == "wide") {
        gutters = columns;
    }
    else {
        gutters = columns - 1;
    }
    if (type === "gutter") {
        gutters = 1;
        columns = 0;
    }
    if (viewport === "mobile") {
        width = columns * mobileColumnWidth + gutters * mobileGutterWidth;
    }
    else if (viewport === "tablet") {
        width = columns * tabletColumnWidth + gutters * tabletGutterWidth;
    }
    else if (viewport === "pc") {
        width = columns * pcColumnWidth + gutters * pcGutterWidth;
    }
    else if (viewport === "hd") {
        width = columns * hdColumnWidth + gutters * hdGutterWidth;
    }
    return width;
};
export const columns = (viewport, columns, widthType = "normal") => {
    return size(viewport, "columns", columns, widthType);
};
export const narrowContainerWidth = (viewport) => {
    if (viewport === "mobile") {
        return columns(viewport, mobileColumns);
    }
    else if (viewport === "tablet") {
        return columns(viewport, tabletColumns);
    }
    else if (viewport === "pc") {
        return columns(viewport, narrowPcColumns);
    }
    else if (viewport === "hd") {
        return columns(viewport, narrowHdColumns);
    }
};
export const gutter = (viewport = "mobile") => {
    return size(viewport, "gutter", 1);
};
export const containerWidth = (viewport) => {
    if (viewport === "mobile") {
        return columns(viewport, mobileColumns);
    }
    else if (viewport === "tablet") {
        return columns(viewport, tabletColumns);
    }
    else if (viewport === "pc") {
        return columns(viewport, pcColumns);
    }
    else if (viewport === "hd") {
        return columns(viewport, hdColumns);
    }
};
