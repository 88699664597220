import React from "react";
//components
import Carousel from "../Carousel";
import CarouselExtra from "../CarouselExtra";
import CarouselHeader from "../CarouselHeader";
import CarouselWrapper from "../CarouselWrapper";
// import Follow from "../../containers/FollowButtonWithSubscription";
import TinyCard from "../TinyCard";
// helpers
import { generateAllVenuesLink } from "../../helpers/linkGenerators";
import { VENUES_CAROUSEL_MAX_CARDS } from "../../helpers/constants";
import { VenueIcon } from "../ui/icons/HomePageIcons/VenueIcon";
import CarouselTitleWithIcon from "../CarouselTitleWithIcon";
const venuesWithoutSelf = (venues, hidden) => {
    return venues.filter((venue) => {
        return venue.get("slug") !== hidden;
    });
};
export default class VenueCarousel extends React.Component {
    componentDidMount() {
        if (!this.props.venues.size) {
            this.props.fetchVenues().catch((e) => {
                // unsure if this is the place to deal with the error
                console.error(e);
                //window.location.href = 'https://insider.in/not-found';
            });
        }
    }
    componentDidUpdate() {
        if (!this.props.venues.size) {
            this.props.fetchVenues().catch((e) => {
                // unsure if this is the place to deal with the error
                console.error(e);
                //window.location.href = 'https://insider.in/not-found';
            });
        }
    }
    tinyCards(venues) {
        const { venueCarouselClickedEvent } = this.props;
        let cards = venues.map((venue, idx) => {
            return (<li className="carousel-item carousel-item-right" key={`carousel-card-${idx}`} onClick={venueCarouselClickedEvent(idx + 1, venue.get("name"))}>
					<TinyCard key={`venue-${idx}`} cardData={venue} type="venue">
						{/* Removing follow for now until pending redesign */}
						{/* <Follow
                    key={`venue-follow-${idx}`}
                    type="Venue"
                    _id={venue.get("_id")}
                    slug={venue.get("slug")}
                    name={venue.get("name")}
                    context={`Venue-Carousel-${venue.get("slug")}`}
                /> */}
					</TinyCard>
				</li>);
        });
        return cards;
    }
    render() {
        const withoutSelf = venuesWithoutSelf(this.props.venues, this.props.hidden);
        if (withoutSelf && withoutSelf.size > 0) {
            return (<CarouselWrapper className="card-list-wrapper tiny-carousel">
					<CarouselHeader>
						<CarouselTitleWithIcon title={this.props.listTitle} IconComponent={VenueIcon} showViewAll={withoutSelf.size >= VENUES_CAROUSEL_MAX_CARDS}/>
						{withoutSelf.size >= VENUES_CAROUSEL_MAX_CARDS && (<CarouselExtra viewAllHref={generateAllVenuesLink(this.props.selectedCity)}/>)}
					</CarouselHeader>
					<Carousel numCarouselItems={6} listType="card">
						{this.tinyCards(withoutSelf)}
					</Carousel>
				</CarouselWrapper>);
        }
        else {
            return null;
        }
    }
}
VenueCarousel.defaultProps = {
    listTitle: "Venues",
    venueCarouselClickedEvent: () => { }
};
