/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import Observer from "react-intersection-observer";
const lazyWrapperStyles = `
	width: 100%;
	height: 100%;
`;
const FadeInAnimation = keyframes `
	from {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;
const FadeInImage = css `
	animation: ${FadeInAnimation} ease 0.5s;
`;
// @TODO
// default images with captions
// fade in animation
// blur / multi color
const LazyImage = (props) => (<Observer triggerOnce={true} rootId="there-you-go" rootMargin="300px" css={css(lazyWrapperStyles)}>
		{inView => {
        if (inView) {
            return <img css={css(FadeInImage)} {...props}/>;
        }
        else {
            const style = {
                minHeight: "1px",
                minWidth: "1px"
            };
            if (props.width) {
                style.width = `${props.width}px`;
            }
            if (props.height) {
                style.height = `${props.height}px`;
            }
            return <div css={style}/>;
        }
    }}
	</Observer>);
export default LazyImage;
