/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as cardStyles from "../styles/cardStyles";
const GenreStrip = (props) => {
    const { categoryColour, categoryName, cardPrefix } = props;
    if (categoryName === undefined || categoryName.length === 0) {
        return null;
    }
    return (<span css={css([cardStyles["cardGenre"], cardStyles[`${cardPrefix}CardGenre`]])} className="card-genre" style={{ backgroundColor: categoryColour }}>
			{categoryName}
		</span>);
};
GenreStrip.defaultProps = {
    categoryColour: "#C20000"
};
export default GenreStrip;
