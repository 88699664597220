import React from "react";
export const Experiences = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M24 6C14.6112 6 7 13.6112 7 23C7 23.5523 7.44772 24 8 24C8.55228 24 9 23.5523 9 23C9 14.7157 15.7157 8 24 8C32.2843 8 39 14.7157 39 23C39 23.5523 39.4477 24 40 24C40.5523 24 41 23.5523 41 23C41 13.6112 33.3888 6 24 6Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M13.293 26.1003H13.7878C15.9068 26.1003 16.7765 28.7477 15.175 30.038L14.6065 30.496L14.803 31.0895C15.4691 33.1022 13.1387 34.8974 11.3644 33.5476L11.0002 33.2705L10.636 33.5476C8.86177 34.8974 6.53132 33.1022 7.19749 31.0895L7.39394 30.496L6.82545 30.038C5.22398 28.7477 6.09371 26.1003 8.21265 26.1003H8.70752L8.90042 25.5175C9.57013 23.4942 12.4303 23.4942 13.1001 25.5175L13.293 26.1003ZM8.21265 28.1003C8.01225 28.1003 7.92373 28.3545 8.08022 28.4806L9.61283 29.7153C9.68218 29.7712 9.7096 29.8647 9.68151 29.9496L9.09619 31.718C9.03035 31.9169 9.25896 32.0822 9.42509 31.9558L10.8725 30.8547C10.9481 30.7972 11.0524 30.7972 11.128 30.8547L12.5754 31.9558C12.7415 32.0822 12.9701 31.9169 12.9043 31.718L12.319 29.9496C12.2909 29.8647 12.3183 29.7712 12.3876 29.7153L13.9203 28.4806C14.0767 28.3545 13.9882 28.1003 13.7878 28.1003H12.001C11.9098 28.1003 11.8288 28.0415 11.7999 27.9544L11.2014 26.146C11.1369 25.9513 10.8635 25.9513 10.7991 26.146L10.2006 27.9544C10.1717 28.0415 10.0907 28.1003 9.99945 28.1003H8.21265Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M39.7878 26.1003H39.293L39.1001 25.5175C38.4303 23.4942 35.5701 23.4942 34.9004 25.5175L34.7075 26.1003H34.2127C32.0937 26.1003 31.224 28.7477 32.8254 30.038L33.3939 30.496L33.1975 31.0895C32.5313 33.1022 34.8618 34.8974 36.636 33.5476L37.0002 33.2705L37.3644 33.5476C39.1387 34.8974 41.4691 33.1022 40.803 31.0895L40.6065 30.496L41.175 30.038C42.7765 28.7477 41.9068 26.1003 39.7878 26.1003ZM34.0802 28.4806C33.9237 28.3545 34.0123 28.1003 34.2127 28.1003H35.9995C36.0907 28.1003 36.1717 28.0415 36.2006 27.9544L36.7991 26.146C36.8635 25.9513 37.1369 25.9513 37.2014 26.146L37.7999 27.9544C37.8288 28.0415 37.9098 28.1003 38.001 28.1003H39.7878C39.9882 28.1003 40.0767 28.3545 39.9203 28.4806L38.3876 29.7153C38.3183 29.7712 38.2909 29.8647 38.319 29.9496L38.9043 31.718C38.9701 31.9169 38.7415 32.0822 38.5754 31.9558L37.128 30.8547C37.0524 30.7972 36.9481 30.7972 36.8725 30.8547L35.4251 31.9558C35.259 32.0822 35.0304 31.9169 35.0962 31.718L35.6815 29.9496C35.7096 29.8647 35.6822 29.7712 35.6128 29.7153L34.0802 28.4806Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M26.3012 27.6635L27.1409 30.2007H29.5754C31.8947 30.2007 32.853 33.1022 31.095 34.5185L28.8785 36.3043L29.707 38.8075C30.439 41.0191 27.88 42.9796 25.9396 41.5034L24.0002 40.028L22.0609 41.5034C20.1205 42.9796 17.5614 41.0191 18.2935 38.8075L19.122 36.3043L16.9054 34.5185C15.1475 33.1022 16.1057 30.2007 18.4251 30.2007H20.8595L21.6993 27.6635C22.4334 25.4455 25.567 25.4455 26.3012 27.6635ZM21.9987 32.2007C22.1812 32.2007 22.3432 32.0831 22.4009 31.9087L23.598 28.292C23.7269 27.9027 24.2736 27.9027 24.4025 28.292L25.5996 31.9087C25.6573 32.0831 25.8193 32.2007 26.0018 32.2007H29.5754C29.9762 32.2007 30.1532 32.709 29.8403 32.9611L26.775 35.4307C26.6363 35.5424 26.5815 35.7294 26.6377 35.8991L27.8083 39.4359C27.94 39.8338 27.4828 40.1645 27.1505 39.9117L24.2558 37.7095C24.1046 37.5944 23.8959 37.5944 23.7447 37.7095L20.85 39.9117C20.5177 40.1645 20.0605 39.8338 20.1922 39.4359L21.3628 35.8991C21.419 35.7294 21.3641 35.5424 21.2254 35.4307L18.1602 32.9611C17.8472 32.709 18.0243 32.2007 18.4251 32.2007H21.9987Z" fill="#0C172F"/>
			<path d="M32.1831 38.5769C31.8645 38.1258 31.9719 37.5018 32.423 37.1832C33.0794 36.7196 33.6774 36.1225 34.1895 35.4141C34.5131 34.9665 35.1382 34.866 35.5858 35.1896C36.0334 35.5131 36.1339 36.1383 35.8103 36.5858C35.1786 37.4598 34.426 38.217 33.5768 38.8168C33.1257 39.1354 32.5017 39.028 32.1831 38.5769Z" fill="#0C172F"/>
			<path d="M15.577 37.1832C16.0281 37.5018 16.1355 38.1258 15.8169 38.5769C15.4983 39.028 14.8743 39.1354 14.4232 38.8168C13.574 38.217 12.8214 37.4598 12.1897 36.5858C11.8661 36.1383 11.9666 35.5131 12.4142 35.1896C12.8618 34.866 13.4869 34.9665 13.8105 35.4141C14.3226 36.1225 14.9206 36.7196 15.577 37.1832Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24 10C20.6863 10 18 12.6863 18 16C18 17.8837 18.868 19.5646 20.2259 20.6646C18.2077 21.4019 16.4661 22.7132 15.1995 24.3994C14.8678 24.841 14.9569 25.4679 15.3985 25.7996C15.8401 26.1313 16.467 26.0422 16.7986 25.6006C18.4423 23.4123 21.056 22 23.9998 22C26.9436 22 29.5573 23.4123 31.201 25.6006C31.5327 26.0422 32.1595 26.1313 32.6011 25.7996C33.0427 25.4679 33.1318 24.841 32.8001 24.3994C31.5336 22.7133 29.7921 21.402 27.7739 20.6647C29.1319 19.5647 30 17.8837 30 16C30 12.6863 27.3137 10 24 10ZM20 16C20 18.2091 21.7909 20 24 20C26.2091 20 28 18.2091 28 16C28 13.7909 26.2091 12 24 12C21.7909 12 20 13.7909 20 16Z" fill="#0C172F"/>
		</svg>);
};
