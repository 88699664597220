/** @jsx jsx */
import { css } from "@emotion/core";
import theme from "./common";
export const tinyFontSize = "12px";
export const smallFontSize = "14px";
export const medFontSize = "15px";
export const stdFontSize = "16px";
export const bigFontSize = "18px";
export const titleFontSize = "24px";
export const bannerFontSize = "30px";
export const subBannerFontSize = css `
	font-size: ${bigFontSize};
	${theme.mediaQueries["for-large-tablet"]} {
		font-size: 2.03rem;
	}
	${theme.mediaQueries["for-pc"]} {
		font-size: 1.67rem;
	}
	${theme.mediaQueries["for-large-pc"]} {
		font-size: 1.52rem;
	}
	${theme.mediaQueries["for-hd"]} {
		font-size: 24px;
	}
`;
export const bodyFontSize = css `
	font-size: ${tinyFontSize};
	${theme.mediaQueries["for-large-tablet"]} {
		font-size: 1.38rem;
	}
	${theme.mediaQueries["for-pc"]} {
		font-size: 1.07rem;
	}
	${theme.mediaQueries["for-large-pc"]} {
		font-size: 0.98rem;
	}
	${theme.mediaQueries["for-hd"]} {
		font-size: ${stdFontSize};
	}
`;
export const headerFontSize = css `
	font-size: ${stdFontSize};
	${theme.mediaQueries["for-large-tablet"]} {
		font-size: 1.75rem;
	}
	${theme.mediaQueries["for-pc"]} {
		font-size: 1.35rem;
	}
	${theme.mediaQueries["for-large-pc"]} {
		font-size: 1.25rem;
	}
	${theme.mediaQueries["for-hd"]} {
		font-size: 22px;
	}
`;
export const smallGap = direction => {
    const gap = css `
		padding: 1.75vw;
		${theme.mediaQueries["for-large-tablet"]} {
			${direction}: 4vw;
		}

		${theme.mediaQueries["for-pc"]} {
			${direction}: 2.75vw;
		}
		${theme.mediaQueries["for-large-pc"]} {
			${direction}: 1.75vw;
		}
		${theme.mediaQueries["for-hd"]} {
			${direction}: 24vw;
		}
	`;
    return gap;
};
