import React from "react";
import { connect } from "react-redux";
import DataCard from "../components/DataCard";
import { isFromApp, getPlatform } from "../selectors";
const mapStateToProps = state => ({
    platform: getPlatform(state),
    isFromApp: isFromApp(state),
    isSummerLandingPage: state.getIn(["uiState", "isSummerLandingPage"])
});
const mergeProps = (stateProps, dispatchProps, ownProps) => (Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), ownProps));
const DataCardWithSubscription = connect(mapStateToProps, null, mergeProps)(DataCard);
export default DataCardWithSubscription;
