import { connect } from "react-redux";
// components
import BannerCarousel from "../components/BannerCarousel";
// selectors
import { getBannersForHomePage } from "../selectors/homepage";
import { getSelectedCityName } from "../selectors/index";
import { disabledBanners, HOME_BANNER_CAROUSEL_AD_CONFIGS } from "../helpers/constants";
import { isFromApp } from "../selectors";
const mapStateToProps = (state, props) => {
    let banners = getBannersForHomePage(state, props);
    banners = banners.filter(b => !disabledBanners.includes(b.get("_id")));
    // add tracking image data for ad banner
    // not making a random tracking pixel here because this will re-rendering a lot
    const adBannerTrackingImg = HOME_BANNER_CAROUSEL_AD_CONFIGS.TrackingImageUrl;
    const adBannerId = HOME_BANNER_CAROUSEL_AD_CONFIGS.BannerID;
    const adBannerIndex = banners.findIndex(b => b.get("_id") === adBannerId);
    // add trackingImage to ad banner index data
    if (~adBannerIndex) {
        banners = banners.setIn([adBannerIndex, "trackingImage"], adBannerTrackingImg);
    }
    return {
        banners: banners,
        selectedCity: getSelectedCityName(state),
        isFromApp: isFromApp(state)
    };
};
const HomeBannerCarouselWithSubscription = connect(mapStateToProps)(BannerCarousel);
export default HomeBannerCarouselWithSubscription;
