import React from "react";
export const SummerCamp = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M23.9995 6C24.5518 6 24.9995 6.44772 24.9995 7L24.9995 23L40.9995 23C41.5518 23 41.9995 23.4477 41.9995 24C41.9995 24.5523 41.5518 25 40.9995 25L24.9995 25L24.9995 41C24.9995 41.5523 24.5518 42 23.9995 42C23.4472 42 22.9995 41.5523 22.9995 41L22.9995 25L6.99951 25C6.44723 25 5.99951 24.5523 5.99951 24C5.99951 23.4477 6.44723 23 6.99951 23L22.9995 23L22.9995 7C22.9995 6.44772 23.4472 6 23.9995 6Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M33.9995 7C30.1335 7 26.9995 10.134 26.9995 14C26.9995 17.866 30.1335 21 33.9995 21C37.8655 21 40.9995 17.866 40.9995 14C40.9995 10.134 37.8655 7 33.9995 7ZM28.9995 14C28.9995 11.2386 31.2381 9 33.9995 9C36.7609 9 38.9995 11.2386 38.9995 14C38.9995 16.7614 36.7609 19 33.9995 19C31.2381 19 28.9995 16.7614 28.9995 14Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M6.99951 34C6.99951 30.134 10.1335 27 13.9995 27C17.8655 27 20.9995 30.134 20.9995 34C20.9995 37.866 17.8655 41 13.9995 41C10.1335 41 6.99951 37.866 6.99951 34ZM13.9995 29C11.2381 29 8.99951 31.2386 8.99951 34C8.99951 36.7614 11.2381 39 13.9995 39C16.7609 39 18.9995 36.7614 18.9995 34C18.9995 31.2386 16.7609 29 13.9995 29Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M26.9995 28C26.9995 27.4477 27.4472 27 27.9995 27H39.9995C40.5518 27 40.9995 27.4477 40.9995 28V40C40.9995 40.5523 40.5518 41 39.9995 41H27.9995C27.4472 41 26.9995 40.5523 26.9995 40V28ZM28.9995 39H38.9995V29H28.9995V39Z" fill="#0C172F"/>
			<path d="M7.29256 7.29289C7.68308 6.90237 8.31625 6.90237 8.70677 7.29289L13.9998 12.5859L19.2927 7.29289C19.6833 6.90237 20.3164 6.90237 20.707 7.29289C21.0975 7.68342 21.0975 8.31658 20.707 8.70711L15.414 14.0001L20.7067 19.2928C21.0972 19.6833 21.0972 20.3165 20.7067 20.707C20.3161 21.0975 19.683 21.0975 19.2925 20.707L13.9998 15.4143L8.70706 20.707C8.31653 21.0975 7.68337 21.0975 7.29284 20.707C6.90232 20.3165 6.90232 19.6833 7.29284 19.2928L12.5855 14.0001L7.29256 8.70711C6.90203 8.31658 6.90203 7.68342 7.29256 7.29289Z" fill="#0C172F"/>
		</svg>);
};
