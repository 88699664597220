/** @jsx jsx */
import React from "react";
import classNames from "classnames";
import { jsx, css } from "@emotion/core";
import { colors, mediaFeatures } from "../styles/common";
import { CaretLeft } from "./ui/icons/CaretLeft";
import { CaretRight } from "./ui/icons/CaretRight";
import { ArrowLeftIcon } from "./ui/icons/ArrowLeftIcon";
import { ChevronRight } from "./ui/icons/ChevronRight";
const SvgStyles = `
	width: 0;
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%,-50%);
`;
const CarouselPreviousAndNextStyles = (isBanner) => `
	display:none;
	top: 0;
	color: ${colors.white};
	@media screen and ${mediaFeatures["for-tablet"]} {
		${isBanner
    ? `display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
		width: 38px;
		height: 38px;
		background: white;
		box-shadow: 0px 0px 30px ${colors.primaryDark};
		z-index: 10;`
    : `display: inline-block;
		position: absolute;
		width: 120px;
		top: 0;
		height: 100%;
		margin-top: 0;
		z-index: 10;
		background: linear-gradient(to left, rgba(55,52,53,1), rgba(55,52,53,0.2), transparent);
		svg {
    		fill: #fff;
			left: 50%;
			${SvgStyles}
			width: 15px;
		}}`}
	.is-disabled{
		opacity: 0.45;
	}
`;
const CarouselPreviousStyles = (isBanner) => `
	${CarouselPreviousAndNextStyles(isBanner)};
	${isBanner
    ? `border-radius: 0px 100px 100px 0px;`
    : `@media screen and ${mediaFeatures["for-tablet"]} {
		background: linear-gradient(90deg,#373435,rgba(55,52,53,.2),transparent);
	}`}
`;
const CarouselNextStyles = (isBanner) => `
	${CarouselPreviousAndNextStyles(isBanner)};
	${isBanner && `border-radius: 100px 0px 0px 100px;`}
	@media screen and ${mediaFeatures["for-tablet"]}{
		right: 0;
	}
`;
const CarouselPreviousGalleryStyles = `
	left: -30px;
	fill: #c20000;
	${SvgStyles};
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: none;
	}
`;
const CarouselNextGalleryStyles = `
	right: -15px;
	fill: #c20000;
	${SvgStyles};
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: none;
	}
`;
const DisplayNone = `
	display: none;
`;
const DisplayInlineBlock = `
	width: 15px;
	display: inline-block;
`;
const eventGalleryCarousel = `
	margin-left: 0 !important;
`;
const eventGalleryCarouselImageList = `
	@media screen and ${mediaFeatures["for-mobile-and-tablet"]}{
		height: auto !important;
	}
`;
class Carousel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.autoPlay = () => {
            this.timeout = setTimeout(() => {
                this.nextPosition();
                this.autoPlay();
            }, this.props.autoPlayDelay);
        };
        this.resetAutoPlay = () => {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            if (this.props.autoPlay) {
                this.autoPlay();
            }
        };
        this.nextPosition = () => {
            if (this.props.infiniteScrolling) {
                this.moveCarouselNext();
            }
            else if (this.state.position === this.totalItems - 1) {
                this.setState(() => {
                    return {
                        position: 0
                    };
                });
            }
            else {
                this.setState(prevState => {
                    return {
                        position: prevState.position + 1
                    };
                });
            }
        };
        this.setPosition = idx => {
            this.setState(() => {
                return {
                    position: idx
                };
            });
        };
        this.state = {
            willChange: false,
            position: 0,
            numCarouselItems: props.numCarouselItems,
            actualHeight: 0
        };
        this.moveCarouselPrevious = this.moveCarouselPrevious.bind(this);
        this.moveCarouselNext = this.moveCarouselNext.bind(this);
        this.toggleWillChange = this.toggleWillChange.bind(this);
    }
    componentDidMount() {
        this.drawCarousel();
        if (this.props.autoPlay) {
            this.autoPlay();
        }
    }
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    drawCarousel() {
        var _a, _b;
        // calculating #of items in carousel from rendered width and re-rendering carousel component
        const listWidth = (_a = this.refs.list) === null || _a === void 0 ? void 0 : _a.offsetWidth;
        const itemWidth = (_b = this.refs.item0) === null || _b === void 0 ? void 0 : _b.offsetWidth;
        const numPossibility = Math.round(listWidth / itemWidth);
        let newState = {
            numCarouselItems: Math.min(numPossibility, this.state.numCarouselItems)
        };
        // if variable height, set height of carousel to computed height
        // assuming the children have heights and widths sent from the server
        if (this.props.variableHeight && this.props.trueFirstChildDimensions) {
            const firstChildActualWidth = this.refs.list.children[0].offsetWidth;
            const firstChildTrueWidth = this.props.trueFirstChildDimensions.width;
            const firstChildTrueHeight = this.props.trueFirstChildDimensions.height;
            newState.actualHeight = (firstChildActualWidth / firstChildTrueWidth) * firstChildTrueHeight;
        }
        this.setState(newState);
    }
    moveCarouselPrevious() {
        if (this.props.infiniteScrolling) {
            let newPosition = this.state.position + 1;
            if (newPosition > this.totalItems - 1) {
                newPosition = 0;
            }
            this.setState({
                position: newPosition
            });
        }
        else {
            if (this.state.position > 0) {
                const position = this.state.position - 1;
                this.setState({
                    position
                });
            }
        }
    }
    moveCarouselNext() {
        if (this.props.infiniteScrolling) {
            let newPosition = this.state.position - 1;
            if (newPosition < 0) {
                newPosition = this.totalItems - 1;
            }
            this.setState({
                position: newPosition
            });
        }
        else {
            // for non non infinite carousel
            if (this.state.position < this.totalItems - this.state.numCarouselItems) {
                const position = this.state.position + 1;
                this.setState({
                    position
                });
            }
        }
    }
    toggleWillChange() {
        this.setState(prevState => {
            return { willChange: !prevState.willChange };
        });
    }
    render() {
        const { listType, infiniteScrolling, showDots, carouselType, children, style, carouselCSS, isLimitedCarousel } = this.props;
        this.totalItems = children.length || children.size;
        const position = this.state.position;
        const numItems = this.state.numCarouselItems;
        const totalItems = this.totalItems;
        const computedStyle = this.state.actualHeight ? { height: this.state.actualHeight } : {};
        let positionName = "";
        let currentPosition = "";
        let Children;
        if (infiniteScrolling) {
            Children = React.Children.map(children, (el, idx) => {
                currentPosition = (idx + position) % totalItems;
                positionName = "";
                const isFirstItem = idx === 0;
                const isFirstItemNotVisible = position >= 2 && position !== totalItems - 1;
                const addFirstItemClass = isFirstItem && isFirstItemNotVisible; //adding this class to explicitly modify the background behavior of the first item of the carousel
                if (currentPosition < numItems) {
                    positionName = `carousel-item-${currentPosition}`;
                }
                else if (currentPosition === totalItems - 1) {
                    positionName = "carousel-item-left";
                }
                else {
                    positionName = "carousel-item-right";
                }
                const className = classNames("carousel-item", `${listType}-list-item`, `${positionName}`, {
                    "will-change": this.state.willChange,
                    "first-item": addFirstItemClass
                });
                return React.cloneElement(el, { className, ref: `item${idx}` });
            });
        }
        else {
            Children = React.Children.map(children, (el, idx) => {
                if (idx - position < 0) {
                    positionName = "carousel-item-left";
                }
                else if (idx - position < numItems) {
                    positionName = `carousel-item-${idx - position}`;
                }
                else {
                    positionName = "carousel-item-right";
                }
                const className = classNames("carousel-item", `${listType}-list-item`, `${positionName}`, {
                    "will-change": this.state.willChange
                });
                return React.cloneElement(el, { className, ref: `item${idx}` });
            });
        }
        const leftDisabled = !infiniteScrolling && this.state.position === 0;
        const rightDisabled = totalItems <= this.state.numCarouselItems ||
            this.state.position === this.totalItems - this.state.numCarouselItems;
        const hasMultipleChildren = React.Children.count(children) > 1;
        const isGallery = carouselType === "gallery";
        const isEventGallery = carouselType === "event-gallery" && isLimitedCarousel;
        return (<div className="carousel" style={style} css={css([carouselCSS, isEventGallery && eventGalleryCarousel])}>
				{hasMultipleChildren && !isGallery && (<a onMouseOver={this.toggleWillChange} onMouseOut={this.toggleWillChange} onClick={e => {
                    if (e) {
                        e.preventDefault();
                    }
                    this.moveCarouselPrevious();
                    this.resetAutoPlay();
                }} css={carouselType == "banner"
                    ? css(CarouselPreviousStyles(carouselType == "banner"))
                    : css(CarouselPreviousGalleryStyles)} href="#">
						<span css={carouselType != "banner" && leftDisabled ? css(DisplayNone) : css(DisplayInlineBlock)}>
							{carouselType == "banner" ? <ArrowLeftIcon fill={colors.primaryDark}/> : <CaretLeft />}
						</span>
					</a>)}
				<ul className={`${listType}-list`} ref="list" style={computedStyle} css={css(isEventGallery && eventGalleryCarouselImageList)}>
					{Children}
				</ul>
				{hasMultipleChildren && !isGallery && (<a onMouseOver={this.toggleWillChange} onMouseOut={this.toggleWillChange} onClick={e => {
                    if (e) {
                        e.preventDefault();
                    }
                    this.moveCarouselNext();
                    this.resetAutoPlay();
                }} css={carouselType == "banner"
                    ? css(CarouselNextStyles(carouselType == "banner"))
                    : css(CarouselNextGalleryStyles)} href="#">
						<span css={carouselType != "banner" && rightDisabled ? css(DisplayNone) : css(DisplayInlineBlock)}>
							{carouselType == "banner" ? <ChevronRight /> : <CaretRight />}
						</span>
					</a>)}
				{this.state.numCarouselItems === 1 && showDots && (<div className="carousel-dots">
						{hasMultipleChildren &&
                    React.Children.map(children, (_, idx) => {
                        const activeClass = React.Children.count(children) - this.state.position - 1 === idx ? "active" : "";
                        return (<span className={classNames("carousel-dots-each", activeClass)} key={idx} onMouseOver={this.toggleWillChange} onMouseOut={this.toggleWillChange} onClick={e => {
                                if (e) {
                                    e.preventDefault();
                                }
                                this.setPosition(React.Children.count(children) - idx - 1);
                                this.resetAutoPlay();
                            }}/>);
                    })}
					</div>)}
			</div>);
    }
}
Carousel.defaultProps = {
    infiniteScrolling: false,
    numCarouselItems: 3,
    variableHeight: false,
    showDots: false,
    autoPlayDelay: 1000,
    autoPlay: false
};
export default Carousel;
