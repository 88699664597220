import { connect } from "react-redux";
import { fetchCityArtists } from "../actions/index";
import { getCityArtists, getSelectedCityName } from "../selectors/index";
import ArtistCarousel from "../components/ArtistCarousel";
const mapStateToProps = (state) => ({
    artists: getCityArtists(state),
    selectedCity: getSelectedCityName(state)
});
const mapDispatchToProps = (dispatch) => ({
    fetchArtists: (city) => dispatch(fetchCityArtists(city))
});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign(Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), ownProps), { fetchArtists: dispatchProps.fetchArtists.bind(null, stateProps.selectedCity) });
};
const ArtistCarouselContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArtistCarousel);
export default ArtistCarouselContainer;
