/** @jsx jsx */
import React from "react";
import CarouselWrapper from "./CarouselWrapper";
import CarouselHeader from "./CarouselHeader";
import Carousel from "./Carousel";
import DataCard from "../containers/DataCardWithSubscription";
import { FeaturedIcon } from "./ui/icons/HomePageIcons/FeaturedIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
import { css, jsx } from "@emotion/core";
const HeightStyles = `
	ul {
		@media screen and (max-width: 300px) {
			height: 135vw;
		}
	}

`;
class FeaturedCarousel extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { featuredCarouselClickedEvent, cards, ssrImageCount, title } = this.props;
        const DataCards = cards.map((card, idx) => {
            return (<li className="carousel-item carousel-item-right" key={`featured-carousel-card-${idx}`} onClick={featuredCarouselClickedEvent(idx + 1, card)}>
					<DataCard cardPrefix="featured" key={`featured-${idx}`} cardData={card} lazy={idx >= ssrImageCount}/>
				</li>);
        });
        if (cards.size > 0) {
            return (<CarouselWrapper className="card-list-wrapper featured-carousel">
					<CarouselHeader>
						<CarouselTitleWithIcon title={title} IconComponent={FeaturedIcon}/>
					</CarouselHeader>
					<div css={css(HeightStyles)}>
						<Carousel numCarouselItems={4} listType="card">
							{DataCards}
						</Carousel>
					</div>
				</CarouselWrapper>);
        }
        else {
            return null;
        }
    }
}
FeaturedCarousel.defaultProps = {
    ssrImageCount: 0,
    featuredCarouselClickedEvent: () => { }
};
export default FeaturedCarousel;
