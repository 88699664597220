import React from "react";
import { colors } from "../../../styles/common";
export class ArrowLeftIcon extends React.Component {
    render() {
        const { fill = colors.primaryDark } = this.props;
        return (<svg width="12" height="14" viewBox="0 0 12 22" fill={fill ? fill : "none"} xmlns="http://www.w3.org/2000/svg">
				<path d="M10.8995 2.51707C11.29 2.12655 11.29 1.49338 10.8995 1.10286C10.509 0.712334 9.8758 0.712334 9.48528 1.10286L0.292888 10.2952C-0.0976366 10.6858 -0.0976363 11.3189 0.292888 11.7095L9.48528 20.9018C9.8758 21.2924 10.509 21.2924 10.8995 20.9018C11.29 20.5113 11.29 19.8782 10.8995 19.4876L2.41421 11.0024L10.8995 2.51707Z" fill={fill}/>
			</svg>);
    }
}
