import { createSelector } from "reselect";
import { fromJS, List } from "immutable";
import { getSlug, homeScreenSlugs } from "./index";
import { sortEventByTagCarousalPosition } from "../helpers/misc";
export const getGoPageData = state => state.get("goPage");
export const getGoPageLoading = createSelector(getGoPageData, getSlug, (goPage, slug) => goPage.getIn([slug, "loading"]));
export const getGoPageTitle = createSelector(getGoPageData, getSlug, (goPage, slug) => goPage.getIn([slug, "title"]));
export const getGoPageDescription = createSelector(getGoPageData, getSlug, (goPage, slug) => goPage.getIn([slug, "description"]));
export const getGoPageArticles = createSelector(getGoPageData, getSlug, homeScreenSlugs, (goPage, slug, homeScreenSlugsArr) => goPage
    .getIn([slug, "data"], List([]))
    .filter((list) => list.get("model") === "article")
    .filter((event) => event.get("tags").some(tag => {
    if (homeScreenSlugsArr.length === 0)
        return true;
    return homeScreenSlugsArr.includes(tag.getIn(["tag_id", "slug"]));
}))
    .sort(sortEventByTagCarousalPosition(slug)));
export const getGoPageEvents = createSelector(getGoPageData, getSlug, homeScreenSlugs, (goPage, slug, homeScreenSlugsArr) => {
    return goPage
        .getIn([slug, "data"], List([]))
        .filter((list) => list.get("model") !== "article" && list.get("type") === "event")
        .filter((event) => event.get("tags").some(tag => {
        if (homeScreenSlugsArr.length === 0)
            return true;
        return homeScreenSlugsArr.includes(tag.getIn(["tag_id", "slug"]));
    }))
        .sort(sortEventByTagCarousalPosition(slug))
        .map((event) => {
        var _a, _b;
        const eventObj = event.toJS();
        const earliestShow = (_b = (_a = eventObj === null || eventObj === void 0 ? void 0 : eventObj.events[0]) === null || _a === void 0 ? void 0 : _a.venues[0]) === null || _b === void 0 ? void 0 : _b.shows[0];
        return fromJS({
            name: event.get("name"),
            slug: event.get("slug"),
            category_id: event.get("category_id"),
            event_state: event.get("event_state"),
            model: "event",
            is_rsvp: event.get("is_rsvp"),
            horizontal_cover_image: event.get("horizontal_cover_image"),
            price_display_string: event.get("price_display_string"),
            venue_name: event.getIn(["events", "0", "venues", "0", "name"]),
            venue_date_string: event.getIn(["events", "0", "venues", "0", "date_string"]),
            event_type: event.get("event_type"),
            city: event.get("city"),
            start_date: (earliestShow === null || earliestShow === void 0 ? void 0 : earliestShow.start_utc_timestamp) || event.get("min_show_start_utc_timestamp")
        });
    });
});
