import PropTypes from "prop-types";
import React from "react";
import CarouselWrapper from "../CarouselWrapper";
import CarouselHeader from "../CarouselHeader";
import CarouselExtra from "../CarouselExtra";
import Carousel from "../Carousel";
import DataCard from "../../containers/DataCardWithSubscription";
import { MagazineIcon } from "../ui/icons/HomePageIcons/MagazineIcon";
import CarouselTitleWithIcon from "../CarouselTitleWithIcon";
const PicksCarousel = ({ title, cards, viewAllHref }) => {
    if (cards.size > 0) {
        return (<CarouselWrapper className="card-list-wrapper cell-carousel">
				<CarouselHeader>
					<CarouselTitleWithIcon title={title} IconComponent={MagazineIcon} showViewAll={viewAllHref}/>
					{viewAllHref && <CarouselExtra viewAllHref={viewAllHref}/>}
				</CarouselHeader>
				<Carousel numCarouselItems={4} listType="card">
					{cards.map((card, idx) => {
                return (<li className="carousel-item carousel-item-right" key={`picks-carousel-card-${idx}`}>
								<DataCard key={`picks-${idx}`} cardData={card} addBackground/>
							</li>);
            })}
				</Carousel>
			</CarouselWrapper>);
    }
    return null;
};
PicksCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    viewAllHref: PropTypes.string,
    cards: PropTypes.object
};
export default PicksCarousel;
