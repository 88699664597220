/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { carouselHeaderTitleStyle, carouselIconStyle, headerWrapperStyle, subHeaderStyles } from "../styles/modules/carouselStyles";
import CarouselHeader from "./CarouselHeader";
import CarouselTitle from "./CarouselTitle";
import IconWrapper from "./IconWrapper";
const CarouselTitleWithIcon = ({ title, IconComponent, subHeader, showViewAll }) => {
    return (<div css={css(carouselHeaderTitleStyle)}>
			<IconWrapper>
				<IconComponent cssStyle={carouselIconStyle}/>
			</IconWrapper>
			<div css={css(headerWrapperStyle)}>
				<CarouselTitle title={title} showViewAll={showViewAll}/>
				{subHeader && (<CarouselHeader css={css(subHeaderStyles)}>
						<div>{subHeader}</div>
					</CarouselHeader>)}
			</div>
		</div>);
};
export default CarouselTitleWithIcon;
