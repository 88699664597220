export const aspectRatio = 16 / 9;
export const stdBoxShadow = `0 1px 6px -1px rgba(black, 0.3)`;
export const mobileColumnWidth = 19;
export const mobileGutterWidth = 4;
export const mobileColumns = 4;
export const mobileGutters = mobileColumns - 1;
export const mobileSideGutter = (100 - (mobileColumns * mobileColumnWidth + mobileGutters * mobileGutterWidth)) / 2;
export const tabletColumnWidth = 11.5;
export const tabletGutterWidth = 3;
export const tabletColumns = 6;
export const tabletGutters = tabletColumns - 1;
export const tabletSideGutter = (100 - (tabletColumns * tabletColumnWidth + tabletGutters * tabletGutterWidth)) / 2;
export const pcColumnWidth = 4.375;
export const pcGutterWidth = 2.5;
export const pcColumns = 12;
export const narrowPcColumns = 8;
export const pcGutters = pcColumns - 1;
export const pcSideGutter = (100 - (pcColumns * pcColumnWidth + pcGutters * pcGutterWidth)) / 2;
export const hdColumnWidth = 70;
export const hdGutterWidth = 40;
export const hdColumns = pcColumns;
export const narrowHdColumns = narrowPcColumns;
export const hdGutters = hdColumns - 1;
export const hdContainer = hdColumns * hdColumnWidth + hdGutters * hdGutterWidth;
export const hdSideGutter = `calc((100vw - ${hdContainer}px)/2)`;
export const stripHeightMobile = "48px";
export const stripHeightMed = "60px";
