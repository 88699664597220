import React from "react";
import { Map, List } from "immutable";
import { connect } from "react-redux";
import { fetchCityVenues } from "../actions/index";
import { getCarouselCityVenues, getSelectedCityName } from "../selectors/index";
import { generateAllVenuesLink } from "../helpers/linkGenerators";
import VenueCarousel from "../components/VenueCarousel";
const mapStateToProps = state => ({
    venues: getCarouselCityVenues(state),
    selectedCity: getSelectedCityName(state)
});
const mapDispatchToProps = dispatch => ({
    fetchVenues: city => dispatch(fetchCityVenues(city))
});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const venues = stateProps.venues;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), ownProps), { fetchVenues: dispatchProps.fetchVenues.bind(null, stateProps.selectedCity) });
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VenueCarousel);
