import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
const FavouriteButton = props => {
    const { clickFavourite, isFavourited, showAnimation, showButton, modifierClass, showCount } = props;
    return (<div className={classNames(`icon-favourite`, {
            isEdpMobile: modifierClass,
            edp: showButton && !modifierClass,
            elp: !modifierClass && !showButton
        })} onClick={clickFavourite}>
			<div className={`fave-container${isFavourited ? (showAnimation ? " active" : "") : ""}`}>
				<div className="drop"/>
				<div className="drop"/>
				<div className="drop"/>
				<div className="drop"/>
				<div className="drop"/>
				<div className="drop"/>
				<div className="drop"/>
				<svg data-name="Layer 1" viewBox={"0 0 24 24"} className={`icon-favourite${isFavourited ? (showAnimation ? " animate" : " active") : ""} ${!modifierClass && !showButton ? `outer-elp` : `outer`}`} preserveAspectRatio={"xMidYMid meet"}>
					<path d="M9.223 5.319l2.282 2.286.423.426a.1.1 0 0 0 .142 0l.426-.426 2.285-2.286c1.751-1.759 4.64-1.759 6.394 0a4.52 4.52 0 0 1 0 6.394 11276.306 11276.306 0 0 1-8.976 8.983c-.11.111-.29.111-.401 0a11681.27 11681.27 0 0 1-8.976-8.983 4.527 4.527 0 0 1 0-6.394c1.758-1.759 4.64-1.759 6.401 0z"/>
				</svg>
				{showButton && !modifierClass && showCount()}
			</div>
			{!(showButton && !modifierClass) && (<div className={classNames(`icon-favourite`, {
                "isEdpMobile-count": modifierClass,
                "edp-count": showButton && !modifierClass,
                "elp-count": !modifierClass && !showButton
            })}>
					{showCount()}
				</div>)}
		</div>);
};
FavouriteButton.propTypes = {
    clickFavourite: PropTypes.func,
    ctaLocation: PropTypes.string,
    isFavourited: PropTypes.bool
};
export default FavouriteButton;
