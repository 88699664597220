import React from "react";
export const ChevronLeft = ({ fill = "#0C172F" }) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_2858_6488" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M7.15515 10.4882C6.50428 11.139 6.50427 12.1943 7.15515 12.8452L13.3504 19.0404C13.7409 19.431 14.3741 19.431 14.7646 19.0404C15.1551 18.6499 15.1551 18.0168 14.7646 17.6262L8.80506 11.6667L14.7646 5.70711C15.1551 5.31658 15.1551 4.68342 14.7646 4.29289C14.3741 3.90237 13.7409 3.90237 13.3504 4.29289L7.15515 10.4882Z" fill={fill}/>
			</mask>
			<g mask="url(#mask0_2858_6488)">
				<rect width="24" height="24" fill={fill}/>
			</g>
		</svg>);
};
