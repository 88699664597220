/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useEffect } from "react";
// components
import { Accordion } from "./Accordion";
import { AccordionPanel } from "./AccordionPanel";
import { AccordionTitle } from "./AccordionTitle";
import CarouselHeader from "./CarouselHeader";
// containers
import CategoryCard from "../containers/CategoryCardWithSubscription";
// helpers
import { NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE } from "../helpers/constants";
import { mediaFeatures } from "../styles/common";
import { GenreIconStripIcon } from "./ui/icons/HomePageIcons/GenreIconStripIcon";
import CarouselTitleWithIcon from "./CarouselTitleWithIcon";
const CategoryListItem = `
	margin-right: 0 !important;
	width: 78px;
	min-width: 21%;
	height: auto;
	@media screen and ${mediaFeatures["for-small-mobile"]} {
		width: 64px;
		min-width: 21%;
	}
	@media screen and ${mediaFeatures["for-small-tablet-only"]} {
    	width: 105px;
		min-width: 21%;
	}
	@media screen and ${mediaFeatures["for-smaller-mobile"]} {
		width: 64px;
		min-width: 21%;
	}
	@media screen and ${mediaFeatures["for-large-tablet"]} {
    	width: 80px;
		min-width: unset;
	}
	@media screen and ${mediaFeatures["for-large-pc"]} {
		width: 105px;
		min-width: unset;
	}
	@media screen and ${mediaFeatures["for-hd"]} {
    	width: 125px;
		min-width: unset;
	}
`;
const categoryListStyle = (flexStart) => `
	display: flex;
	justify-content: ${flexStart ? "flex-start" : "space-between"};
    flex-wrap: wrap;
	height: auto;
	${flexStart ? "gap: 18px;" : "row-gap: 18px;"} 
	@media screen and ${mediaFeatures["for-large-tablet"]} {
		${flexStart ? "gap: 30px;" : "row-gap: 30px;"} 
	}
	&::before {
		content: unset;
	}
	&::after {
		content: unset;
	}
`;
const ShowMore = (props) => {
    if (props.categories.size > NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE) {
        return (<Accordion className="category-accordion">
				<AccordionTitle>
					<span>Show more</span>
				</AccordionTitle>
				<AccordionPanel>
					<ul className="card-list category-text-list">
						{props.categories
                .takeLast(props.categories.size - NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE)
                .map((category, i) => (<li key={category.hashCode()} className="category-text-list-item" onClick={props.genreCarouselClickedEvent(i + NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE + 1, category)}>
									<CategoryCard category={category} key={category.hashCode()} primary={false} timeFilter={props.timeFilter}/>
								</li>))}
					</ul>
				</AccordionPanel>
			</Accordion>);
    }
    else {
        return null;
    }
};
const CategoryIconStrip = ({ categories, timeFilter, genreCarouselClickedEvent, elpDataRequested, elpDataReceived, getELPData, selectedCity }) => {
    const fetchGooutDataIfRequired = () => {
        if (elpDataRequested || elpDataReceived) {
            return;
        }
        getELPData(selectedCity);
    };
    useEffect(() => {
        fetchGooutDataIfRequired();
    }, [selectedCity]);
    if (elpDataRequested || !elpDataReceived) {
        return null;
    }
    return (<section className="card-list-wrapper category-wrapper">
			<CarouselHeader>
				<CarouselTitleWithIcon title={"Browse events by genre"} IconComponent={GenreIconStripIcon}/>
			</CarouselHeader>
			<ul className="card-list category-list" css={css(categoryListStyle(categories.size < NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE))}>
				{categories.take(NUM_CATEGORIES_TO_DISPLAY_ON_HOME_PAGE).map((category, i) => {
            return (<li className="category-list-item short-list-item" css={css(CategoryListItem)} key={category.hashCode()} onClick={genreCarouselClickedEvent(i + 1, category)}>
							<CategoryCard timeFilter={timeFilter} category={category} primary={true}/>
						</li>);
        })}
			</ul>
			<ShowMore categories={categories} timeFilter={timeFilter} genreCarouselClickedEvent={genreCarouselClickedEvent}/>
		</section>);
};
CategoryIconStrip.defaultProps = {
    timeFilter: "",
    genreCarouselClickedEvent: () => { }
};
export default CategoryIconStrip;
