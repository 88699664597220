import { connect } from "react-redux";
// components
import BannerCarouselEmptyState from "../components/BannerCarouselEmptyState";
import { getSelectedCityName } from "../selectors/index";
const mapStateToProps = (state) => {
    return {
        selectedCity: getSelectedCityName(state)
    };
};
const BannerCarouselEmptyStateWithSubscription = connect(mapStateToProps)(BannerCarouselEmptyState);
export default BannerCarouselEmptyStateWithSubscription;
