import React from "react";
export const TrySomethingNew = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M23 13C23 13.5523 23.4477 14 24 14C24.5523 14 25 13.5523 25 13V7C25 6.44772 24.5523 6 24 6C23.4477 6 23 6.44772 23 7V13Z" fill="#0C172F"/>
			<path d="M24 34C23.4477 34 23 33.5523 23 33V31.7324C22.4022 31.3866 22 30.7403 22 30C22 28.8954 22.8954 28 24 28C25.1046 28 26 28.8954 26 30C26 30.7403 25.5978 31.3866 25 31.7324V33C25 33.5523 24.5523 34 24 34Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 18.2386 39.7614 16 37 16L11 16C8.23857 16 6 18.2386 6 21V39C6 40.6569 7.34315 42 9 42H39C40.6569 42 42 40.6569 42 39V21ZM8 38H11C11.5523 38 12 37.5523 12 37C12 36.4477 11.5523 36 11 36H8V34H15C15.5523 34 16 33.5523 16 33C16 32.4477 15.5523 32 15 32H8V30H13C13.5523 30 14 29.5523 14 29C14 28.4477 13.5523 28 13 28H8V26H18V35C18 36.6569 19.3431 38 21 38H27C28.6569 38 30 36.6569 30 35V26H40V28H35C34.4477 28 34 28.4477 34 29C34 29.5523 34.4477 30 35 30H40V32H33C32.4477 32 32 32.4477 32 33C32 33.5523 32.4477 34 33 34H40V36H37C36.4477 36 36 36.4477 36 37C36 37.5523 36.4477 38 37 38H40V39C40 39.5523 39.5523 40 39 40H9C8.44772 40 8 39.5523 8 39V38ZM37 18H35.0004C35.6281 18.8357 36 19.8744 36 21V24H40V21C40 19.3431 38.6569 18 37 18ZM34 21C34 19.3431 32.6569 18 31 18L17 18C15.3431 18 14 19.3431 14 21L14 24L34 24V21ZM12 21C12 19.8744 12.3719 18.8357 12.9996 18H11C9.34314 18 8 19.3431 8 21L8 24L12 24L12 21ZM20 35V26H28V35C28 35.5523 27.5523 36 27 36H21C20.4477 36 20 35.5523 20 35Z" fill="#0C172F"/>
			<path d="M28.0717 12.6286C27.8666 13.1414 28.116 13.7233 28.6288 13.9285C29.1416 14.1336 29.7236 13.8842 29.9287 13.3714L31.9287 8.37137C32.1338 7.85858 31.8844 7.27661 31.3716 7.0715C30.8588 6.86639 30.2769 7.1158 30.0717 7.62859L28.0717 12.6286Z" fill="#0C172F"/>
			<path d="M33.2929 12.2929L37.2929 8.29289C37.6834 7.90236 38.3166 7.90236 38.7071 8.29289C39.0976 8.68341 39.0976 9.31658 38.7071 9.7071L34.7071 13.7071C34.3166 14.0976 33.6834 14.0976 33.2929 13.7071C32.9024 13.3166 32.9024 12.6834 33.2929 12.2929Z" fill="#0C172F"/>
			<path d="M18.0717 13.3714C18.2769 13.8842 18.8588 14.1336 19.3716 13.9285C19.8844 13.7233 20.1338 13.1414 19.9287 12.6286L17.9287 7.62859C17.7236 7.1158 17.1416 6.86639 16.6288 7.0715C16.116 7.27662 15.8666 7.85859 16.0717 8.37137L18.0717 13.3714Z" fill="#0C172F"/>
			<path d="M14.7071 13.7071C14.3166 14.0976 13.6834 14.0976 13.2929 13.7071L9.29289 9.70711C8.90237 9.31658 8.90237 8.68342 9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289L14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071Z" fill="#0C172F"/>
		</svg>);
};
