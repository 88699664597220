/** @jsx jsx */
import { jsx } from "@emotion/core";
import theme from "../../styles";
import { Component } from "react";
const spinner = (theme) => {
    const result = {
        position: "absolute",
        top: `calc(50% - ${theme.medPadding} /2)`,
        left: `calc(50% - (3*${theme.medPadding} + 4px)/2)`
    };
    return result;
};
const bouncer = ({ width = "12px", height = "12px", backgroundColor = "#fff", borderRadius = "12px" }) => {
    return {
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        display: "inline-block",
        WebkitAnimation: "bouncing 1.4s infinite ease-in-out both",
        animation: "bouncing 1.2s infinite ease-in-out both",
        "&:first-of-type": {
            WebkitAnimationDelay: "-0.3s",
            animationDelay: "-0.3s",
            marginRight: "2px"
        },
        "&:nth-of-type(2)": {
            WebkitAnimationDelay: "-0.15s",
            animationDelay: "-0.15s",
            marginRight: "2px"
        }
    };
};
class Spinner extends Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<div css={spinner(theme)} data-testid="spinner">
				<span css={bouncer(this.props.customStyle || {})}/>
				<span css={bouncer(this.props.customStyle || {})}/>
				<span css={bouncer(this.props.customStyle || {})}/>
			</div>);
    }
}
export default Spinner;
