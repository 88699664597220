/** @jsx jsx */
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { css, jsx } from "@emotion/core";
// components
import EventRibbon from "./EventRibbon";
import GenreStrip from "./GenreStrip";
import Image from "./Image";
import theme, { colors } from "../styles/common";
// containers
import FavouriteButton from "../containers/FavouriteWithSubscription";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
// helpers + selectors
import { NO_DATA_CLASS, EVENT_CATEGORIES, GENERAL_ACTIONS, HIT_TYPES, CategoryIdsToSkipTagAndInfo, ACCESSIBLITY_TALKBACK_PHRASES } from "../helpers/constants";
import { capitalizeFirstLetter, getBuyButtonText, truncate, addGAEvent, GA_RECOMMENDED_EVENTS } from "../helpers/constants";
import { DDEventIds } from "../helpers/hardCodedIds";
import { generateEventLink } from "../helpers/linkGenerators";
import { getComputedCategoryColour, getCategoryNameFromResource } from "../selectors/index.js";
import YellowOfferStrip from "./YellowOfferStrip";
import * as cardStyles from "../styles/cardStyles";
import { DATA_REFS } from "../helpers/constants/dataRefs";
import { CalendarIcon } from "./ui/icons/EventCardCalendarIcon";
import { LocationIcon } from "./ui/icons/LocationIcon";
import { OnlinePlayIcon } from "./ui/icons/OnlinePlayIcon";
import { sendClevertapEvent } from "../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../helpers/constants";
const { SEARCH_RESULT_CLICKED, EVENT_CARD_LIST_COMPONENT_CLICKED } = CLEVERTAP_ACTIONS;
const FEATURED_IMAGE_WIDTH = 420;
const FEATURED_IMAGE_WIDTHS = [220, 280, 320, 420, 400];
// These are taken from the widths defined on featured-carousel
const FEATURED_IMAGE_STRING = `(max-width: 34.9em) 57.33333vw, (max-width: 63.9em) 32.25vw, (max-width: 100em) 18.125vw, 420px`;
const transformFreePriceString = priceString => (priceString === "0" ? "Free" : priceString);
const WithEventLink = props => {
    const { cardData, children, isFromApp, onClick } = props;
    // don't add the a tag if this is a shell
    if (cardData === undefined) {
        return (<div css={css(cardStyles.noDataWrapper)} className="no-data-wrapper">
				{children}
			</div>);
    }
    return (<InsiderLink onClick={onClick} href={generateEventLink(cardData.get("slug"), isFromApp)}>
			{children}
		</InsiderLink>);
};
const EventImage = props => {
    const { cardData, cardPrefix, lazy } = props;
    if (cardData === undefined) {
        return null;
    }
    if (cardPrefix === "featured") {
        let eventName = (cardData && cardData.get("name")) || "";
        return (<div>
				<Image src={cardData.get("vertical_cover_image")} alt={cardData.get("name")} imgWidth={FEATURED_IMAGE_WIDTH} imgWidths={FEATURED_IMAGE_WIDTHS} sizeString={FEATURED_IMAGE_STRING} lazy={lazy} dataRef={DATA_REFS.EVENT_CARD_IMAGE}/>
				<div css={css(getCSSArrayWithNoData(`${cardPrefix}CardName`))}>
					<span css={css(getCSSArrayWithNoData(`${cardPrefix}CardNameString`))} data-ref={DATA_REFS.EVENT_CARD_TITLE}>
						{eventName}
					</span>
				</div>
			</div>);
    }
    return (<Image src={cardData.get("horizontal_cover_image")} alt={cardData.get("name")} imgWidth={400} className="" captionClassName="" lazy={lazy} dataRef={DATA_REFS.EVENT_CARD_IMAGE}/>);
};
const getCSSArrayWithNoData = (cssName, addNoDataCSS = false) => {
    return [cardStyles[`${cssName}`], addNoDataCSS && cardStyles[`${cssName}NoData`]];
};
class EventCard extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            categoryName: this.props.cardData ? getCategoryNameFromResource(this.props.cardData) : undefined,
            categoryColour: this.props.cardData ? getComputedCategoryColour(this.props.cardData) : undefined
        };
        this.onClick = e => {
            const { cardData, queryID, query, location, addToCt, tags, index } = this.props, objectID = cardData.get("objectID"), position = cardData.get("searchPosition"), slug = cardData.get("slug"), search_query = location.query.q;
            if (objectID && position && queryID) {
                window.aa && window.aa("click", { queryID, objectID, position });
            }
            if (window.ga && query) {
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.SEARCH,
                    eventAction: query,
                    eventLabel: cardData.get("slug")
                });
            }
            if (query) {
                addGAEvent(GA_RECOMMENDED_EVENTS.SEARCH, {
                    search_term: query
                });
            }
            sendClevertapEvent(SEARCH_RESULT_CLICKED, {
                is_trending: false,
                slug,
                item_position: position,
                keyword: search_query || ""
            });
            const sourceURL = window && window.location && window.location.pathname;
            const eventName = cardData && cardData.get("name");
            if (addToCt === true && tags && (index + 1)) {
                let resp = sendClevertapEvent(EVENT_CARD_LIST_COMPONENT_CLICKED, {
                    sourceURL,
                    eventName,
                    position: index + 1,
                    tag: tags
                });
            }
        };
    }
    componentWillReceiveProps(nextProps) {
        const { cardData } = nextProps;
        if (cardData !== this.props.cardData) {
            this.setState({
                categoryName: cardData ? getCategoryNameFromResource(cardData) : undefined,
                categoryColour: cardData ? getComputedCategoryColour(cardData) : undefined
            });
        }
    }
    render() {
        var { allowFavouriting, cardData, cardPrefix, isFromApp, lazy } = this.props, { categoryColour, categoryName } = this.state;
        cardPrefix = Boolean(cardPrefix) ? cardPrefix : "event";
        const applyNoDataCSS = cardData === undefined;
        let eventName = (cardData && cardData.get("name")) || "";
        let dateString = (cardData && cardData.get("venue_date_string")) || "";
        let venueName = (cardData && cardData.get("venue_name")) || "";
        let cityName = (cardData && cardData.get("city")) || "";
        let type = (cardData && cardData.get("event_type")) || "";
        let priceDisplayString = (cardData && cardData.get("price_display_string")) || "";
        const priceString = transformFreePriceString(priceDisplayString);
        let addFreeClass = cardData &&
            (priceDisplayString.toLowerCase() === "free" ||
                priceDisplayString === "0" ||
                cardData.get("is_free") ||
                !/\d/g.test(priceString));
        const activeOffersString = (cardData && cardData.get("discount_text")) || "";
        const isPhysicalEvent = type === "physical";
        const isEventOrFeaturedCard = ["event", "featured"].includes(cardPrefix);
        const venueWithCity = venueName + (isPhysicalEvent && cityName ? `, ${capitalizeFirstLetter(cityName)}` : "");
        const accessiblityTalkbackString = `${categoryName} event. ${eventName} ${dateString} ${venueWithCity}. ${ACCESSIBLITY_TALKBACK_PHRASES.BUTTON} ${priceString} ${isPhysicalEvent ? 'Buy now' : ''}`;
        return (<div css={css(getCSSArrayWithNoData(`${cardPrefix}Card`, applyNoDataCSS))} data-ref={DATA_REFS.EVENT_CARD} aria-label={accessiblityTalkbackString} role="region">
				<WithEventLink cardData={cardData} isFromApp={isFromApp} onClick={this.onClick}>
					<div id="event_image" css={css(getCSSArrayWithNoData(`${cardPrefix}CardImage`, applyNoDataCSS))}>
						<EventImage cardData={cardData} cardPrefix={cardPrefix} lazy={lazy}/>
						<GenreStrip cardPrefix={cardPrefix} categoryName={categoryName} categoryColour={categoryColour}/>
						{activeOffersString ? <YellowOfferStrip offerString={activeOffersString}/> : null}
					</div>
					{cardData && <EventRibbon eventState={cardData.get("event_state")}/>}
					<div css={css(getCSSArrayWithNoData(`${cardPrefix}CardDetails`, applyNoDataCSS))}>
						<div css={css(cardStyles[`${cardPrefix}CardDetailsTop`])}>
							{/* Event name section in card content for non-featured cards */}
							{cardPrefix !== "featured" && (<div css={css(getCSSArrayWithNoData(`${cardPrefix}CardName`, applyNoDataCSS))}>
									<span css={css(getCSSArrayWithNoData(`${cardPrefix}CardNameString`, applyNoDataCSS))} data-ref={DATA_REFS.EVENT_CARD_TITLE}>
										{eventName}
									</span>
								</div>)}

							{/* Date and venue sections */}
							{isEventOrFeaturedCard ? (<Fragment>
									<div css={css(getCSSArrayWithNoData(`${cardPrefix}CardDateVenueContainer`))} style={{ marginTop: "0" }} data-ref={DATA_REFS.EVENT_CARD_DATE_STRING}>
										<div style={{ display: "flex" }}>
											<CalendarIcon />
										</div>
										<p css={css(getCSSArrayWithNoData(`${cardPrefix}DateVenue`))}>{dateString}</p>
									</div>
									<div css={css(getCSSArrayWithNoData(`${cardPrefix}CardDateVenueContainer`, applyNoDataCSS))} data-ref={DATA_REFS.EVENT_CARD_LOCATION}>
										<div style={{ display: "flex" }}>
											{isPhysicalEvent ? (<LocationIcon size="24" stroke={colors.lightGray}/>) : (<OnlinePlayIcon fill={colors.lightGray}/>)}
										</div>
										<p css={css(getCSSArrayWithNoData(`${cardPrefix}DateVenue`))}>{venueWithCity}</p>
									</div>
								</Fragment>) : (<Fragment>
									<span css={css(getCSSArrayWithNoData(`${cardPrefix}CardDate`, applyNoDataCSS))} data-ref={DATA_REFS.EVENT_CARD_DATE_STRING}>
										{dateString}
									</span>
									<span css={css(getCSSArrayWithNoData(`${cardPrefix}CardVenue`, applyNoDataCSS))} data-ref={DATA_REFS.EVENT_CARD_LOCATION}>
										{venueName}
										{isPhysicalEvent && cityName ? `, ${capitalizeFirstLetter(cityName)}` : ""}
									</span>
								</Fragment>)}
						</div>

						{/* Footer price section */}
						{isEventOrFeaturedCard ? (<div css={css([
                    cardStyles[`${cardPrefix}FooterContainer`](isPhysicalEvent),
                    getCSSArrayWithNoData(`${cardPrefix}FooterText`)
                ])}>
								<p css={css(cardStyles[`${cardPrefix}FooterPrice`])}>{(!addFreeClass ? "₹" : "") + priceString}</p>
								<p css={css(cardStyles[`${cardPrefix}FooterBuyNow`](isPhysicalEvent))}>Buy now</p>
							</div>) : (<div css={css(cardStyles[`${cardPrefix}CardContainer`])}>
								<div css={css([
                    ...getCSSArrayWithNoData(`${cardPrefix}CardPrice`, applyNoDataCSS),
                    addFreeClass && cardStyles.eventCardPriceFree
                ])} data-ref={DATA_REFS.EVENT_CARD_PRICE_STRING}>
									{priceString}
								</div>
								{cardData && allowFavouriting && (<FavouriteButton _id={cardData.get("_id")} eventData={cardData} showAnimation={false} showButton={false} slug={cardData.get("slug")}/>)}
							</div>)}
					</div>
				</WithEventLink>
			</div>);
    }
}
EventCard.propTypes = {
    cardData: PropTypes.object,
    lazy: PropTypes.bool,
    isFromApp: PropTypes.bool
};
export default EventCard;
