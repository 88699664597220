/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import InsiderLink from "../containers/InsiderLinkWithSubscription";
import theme from "../styles";
import { timefilterCard, timefilterCardDateString, timefilterCardName } from "../styles/cardStyles";
const styles = {
    color: theme.primaryColor
};
const TimeFilterCard = ({ date, dateString, href, title }) => {
    const dateClassModifier = date.length > 2 ? "weekend" : "";
    return (<InsiderLink href={href}>
			<div css={css(timefilterCard)}>
				<span css={css([styles, timefilterCardName])}>{title}</span>
				<span css={css(timefilterCardDateString)}>{dateString}</span>
			</div>
		</InsiderLink>);
};
export default TimeFilterCard;
