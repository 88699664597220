/** @jsx jsx */
import { css, jsx } from "@emotion/core";
export const MagazineIcon = (props) => {
    return (<svg css={css(props.cssStyle)} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3333 8.50586C18.9651 8.50586 18.6667 8.80434 18.6667 9.17253V14.5059C18.6667 14.8741 18.9651 15.1725 19.3333 15.1725H24.6667C25.0349 15.1725 25.3333 14.874 25.3333 14.5059V9.17253C25.3333 8.80434 25.0349 8.50586 24.6667 8.50586H19.3333ZM24 9.83919H20V13.8392H24V9.83919Z" fill="#0C172F"/>
			<path d="M12 9.17253C12 8.80434 12.2985 8.50586 12.6667 8.50586H16.6667C17.0349 8.50586 17.3333 8.80434 17.3333 9.17253C17.3333 9.54072 17.0349 9.83919 16.6667 9.83919H12.6667C12.2985 9.83919 12 9.54072 12 9.17253Z" fill="#0C172F"/>
			<path d="M12.6667 11.1725C12.2985 11.1725 12 11.471 12 11.8392C12 12.2074 12.2985 12.5059 12.6667 12.5059H16.6667C17.0349 12.5059 17.3333 12.2074 17.3333 11.8392C17.3333 11.471 17.0349 11.1725 16.6667 11.1725H12.6667Z" fill="#0C172F"/>
			<path d="M12 14.5059C12 14.1377 12.2985 13.8392 12.6667 13.8392H16.6667C17.0349 13.8392 17.3333 14.1377 17.3333 14.5059C17.3333 14.8741 17.0349 15.1725 16.6667 15.1725H12.6667C12.2985 15.1725 12 14.8741 12 14.5059Z" fill="#0C172F"/>
			<path d="M12.6667 16.5059C12.2985 16.5059 12 16.8043 12 17.1725C12 17.5407 12.2985 17.8392 12.6667 17.8392H24.6667C25.0349 17.8392 25.3333 17.5407 25.3333 17.1725C25.3333 16.8043 25.0349 16.5059 24.6667 16.5059H12.6667Z" fill="#0C172F"/>
			<path d="M12 19.8392C12 19.471 12.2985 19.1725 12.6667 19.1725H24.6667C25.0349 19.1725 25.3333 19.471 25.3333 19.8392C25.3333 20.2074 25.0349 20.5059 24.6667 20.5059H12.6667C12.2985 20.5059 12 20.2074 12 19.8392Z" fill="#0C172F"/>
			<path d="M12.6667 21.8392C12.2985 21.8392 12 22.1377 12 22.5059C12 22.8741 12.2985 23.1725 12.6667 23.1725H24.6667C25.0349 23.1725 25.3333 22.8741 25.3333 22.5059C25.3333 22.1377 25.0349 21.8392 24.6667 21.8392H12.6667Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.83919C4 5.99824 5.49238 4.50586 7.33333 4.50586C8.42388 4.50586 9.39211 5.02956 10.0002 5.83919H27.3333C27.7015 5.83919 28 6.13767 28 6.50586V25.1725C28 25.5407 27.7015 25.8392 27.3333 25.8392H25.3333V27.1725C25.3333 27.9089 24.7364 28.5059 24 28.5059H7.33333C5.49238 28.5059 4 27.0135 4 25.1725V7.83919ZM5.33333 25.1725C5.33333 26.2771 6.22876 27.1725 7.33333 27.1725H24V25.8392H10C9.63181 25.8392 9.33333 25.5407 9.33333 25.1725C9.33333 24.068 8.4379 23.1725 7.33333 23.1725C6.22876 23.1725 5.33333 24.068 5.33333 25.1725ZM9.33333 22.5056C8.77623 22.0872 8.08374 21.8392 7.33333 21.8392C6.58293 21.8392 5.89044 22.0872 5.33333 22.5056V7.83919C5.33333 6.73462 6.22876 5.83919 7.33333 5.83919C8.4379 5.83919 9.33333 6.73462 9.33333 7.83919V22.5056ZM26.6667 7.17253V24.5059H10.6667V7.17253H26.6667Z" fill="#0C172F"/>
		</svg>);
};
