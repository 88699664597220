export const iconFollow = `
    background-image: url("/assets/images/icons/follow2.svg");
    background-size: cover;
`;
export const iconFollowActive = `
  background-image: url("/assets/images/icons/follow2-blue.svg");
`;
export const paymentIcon = `
  content: "";
  vertical-align: top;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #e4e5e7;
`;
export const paytm = `
  ${paymentIcon}
  background-image: url(/assets/img/paytm.png);
`;
