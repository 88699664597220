/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Observer from "react-intersection-observer";
// components
import CarouselHeader from "../components/CarouselHeader";
import CarouselWrapper from "../components/CarouselWrapper";
import { InsiderLink } from "../components/InsiderLink";
import { SearchIcon } from "../components/ui/icons/SearchIcon/header";
//containers
import SelectedCity from "../containers/SelectedCityWithSubscription";
import { GENERAL_ACTIONS, HIT_TYPES, EVENT_CATEGORIES } from "../helpers/constants";
import { colors, mediaFeatures } from "../styles/common";
import { DATA_REFS } from "../helpers/constants/dataRefs";
import { sendClevertapEvent } from "../helpers/misc/clevertap";
import { CLEVERTAP_ACTIONS } from "../helpers/constants";
// Emotion JS
const HomePageSearchStyles = `
	textarea:focus, input:focus{
		outline: none;
	}

	.card-list-head {
		justify-content: flex-end;
		margin-bottom: 11px;
	}
`;
const HomepageSearchActionable = `
	display: flex;
	padding: 7px 9px;
	align-items: center;
	gap: 6px;
	border-radius: 9px;
	border: 1px solid ${colors.borderColor};
`;
const HomePageSearchContainerStyles = `
	display: none;
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: unset;
	}
`;
const HomePageSearchBarStyles = `
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	color: ${colors["slight-light-gray"]};
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; 
`;
class HomePageSearch extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onOpenCitySelector = () => {
            window.ga &&
                ga(GENERAL_ACTIONS.SEND, {
                    hitType: HIT_TYPES.EVENT,
                    eventCategory: EVENT_CATEGORIES.CITY_SELECTOR,
                    eventAction: this.props.selectedCity || "",
                    eventLabel: window.location.pathname
                });
        };
    }
    render() {
        const { setSearchBarInView } = this.props;
        const SearchField = (<div css={css(HomePageSearchContainerStyles)}>
				<CarouselWrapper className="card-list-wrapper" css={css(HomePageSearchStyles)}>
					<div className="card-list category-list" css={css(HomePageSearchStyles)}>
						<CarouselHeader>
							<SelectedCity onClick={this.onOpenCitySelector}/>
						</CarouselHeader>
						<InsiderLink href="/search" activeClassName="" isSPA={true} dataRef={DATA_REFS.SEARCH_ICON_HOME}>
							<div css={css(HomepageSearchActionable)}>
								<SearchIcon fill={colors.lightGray}/>
								<div css={css(HomePageSearchBarStyles)} onClick={() => {
                sendClevertapEvent(CLEVERTAP_ACTIONS.SEARCH_CLICKED, {
                    is_web: false
                }); //sending event to celvertap when search icon is clicked on mobile view
            }} aria-label="Homepage search text field. Double tap to open search page">
									concert, comedy show, cricket match, football game...
								</div>
							</div>
						</InsiderLink>
					</div>
				</CarouselWrapper>
			</div>);
        return (<Observer onChange={inView => {
                if (!inView) {
                    setSearchBarInView(false);
                }
                else {
                    setSearchBarInView(true);
                }
            }} threshold={0.75} root={null}>
				{SearchField}
			</Observer>);
    }
}
export default HomePageSearch;
