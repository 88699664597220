import React from "react";
export const Talks = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12 11C12 10.4477 12.4477 10 13 10L33 10C33.5523 10 34 10.4477 34 11C34 11.5523 33.5523 12 33 12L13 12C12.4477 12 12 11.5523 12 11Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12 15C12 14.4477 12.4477 14 13 14L27 14C27.5523 14 28 14.4477 28 15C28 15.5523 27.5523 16 27 16L13 16C12.4477 16 12 15.5523 12 15Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.0017V10C10 8.89543 10.8954 8 12 8H36C37.1046 8 38 8.89543 38 10V16C38 17.1046 37.1046 18 36 18H27.0015C26.3331 18 25.7089 18.3339 25.3379 18.8899L22.3647 23.3457C22.2549 23.5102 21.9983 23.4325 21.9983 23.2347V20.0084C21.9983 18.9045 21.1039 18.0093 20 18.0084L11.9983 18.0017C10.8944 18.0007 10 17.1056 10 16.0017ZM12 6H36C38.2091 6 40 7.79086 40 10V16C40 18.2091 38.2091 20 36 20H27.0015L24.0283 24.4558C22.8204 26.266 19.9983 25.4109 19.9983 23.2347V20.0084L11.9967 20.0017C9.78882 19.9998 8 18.2095 8 16.0017V10C8 7.79086 9.79086 6 12 6Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M9 27C9 28.6569 10.3431 30 12 30C13.6569 30 15 28.6569 15 27C15 25.3431 13.6569 24 12 24C10.3431 24 9 25.3431 9 27ZM12 22C9.23858 22 7 24.2386 7 27C7 29.7614 9.23858 32 12 32C14.7614 32 17 29.7614 17 27C17 24.2386 14.7614 22 12 22Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M33 27C33 28.6569 34.3431 30 36 30C37.6569 30 39 28.6569 39 27C39 25.3431 37.6569 24 36 24C34.3431 24 33 25.3431 33 27ZM36 22C33.2386 22 31 24.2386 31 27C31 29.7614 33.2386 32 36 32C38.7614 32 41 29.7614 41 27C41 24.2386 38.7614 22 36 22Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12 35C9.79086 35 8 36.7909 8 39V41C8 41.5523 7.55228 42 7 42C6.44772 42 6 41.5523 6 41V39C6 35.6863 8.68629 33 12 33C15.3137 33 18 35.6863 18 39V41C18 41.5523 17.5523 42 17 42C16.4477 42 16 41.5523 16 41V39C16 36.7909 14.2091 35 12 35Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M36 35C33.7909 35 32 36.7909 32 39V41C32 41.5523 31.5523 42 31 42C30.4477 42 30 41.5523 30 41V39C30 35.6863 32.6863 33 36 33C39.3137 33 42 35.6863 42 39V41C42 41.5523 41.5523 42 41 42C40.4477 42 40 41.5523 40 41V39C40 36.7909 38.2091 35 36 35Z" fill="#0C172F"/>
		</svg>);
};
