import React from "react";
export const Kids = ({ width = "64", height = "64", fill = 'none', viewBox = "0 0 48 48" }) => {
    return (<svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M29.553 18.1056C30.0383 17.8629 30.6265 18.0519 30.8812 18.5269C30.8843 18.5318 30.8911 18.542 30.9018 18.5562C30.9299 18.5938 30.9821 18.6549 31.0624 18.7191C31.2104 18.8375 31.4931 19 32.0002 19C32.5073 19 32.7901 18.8375 32.938 18.7191C33.0184 18.6549 33.0705 18.5938 33.0987 18.5562C33.1093 18.542 33.1161 18.5318 33.1192 18.5269C33.374 18.0519 33.9622 17.8629 34.4474 18.1056C34.9414 18.3526 35.1416 18.9532 34.8946 19.4472C34.8946 19.4472 34.895 19.4464 34.8941 19.4483L34.8936 19.4494L34.8924 19.4516L34.89 19.4564L34.8844 19.4671C34.8825 19.4708 34.8804 19.4748 34.8782 19.4789C34.8758 19.4834 34.8732 19.4881 34.8705 19.4931C34.8599 19.5124 34.8466 19.5355 34.8306 19.5619C34.7986 19.6147 34.7551 19.681 34.6987 19.7562C34.5862 19.9062 34.4196 20.0951 34.1874 20.2809C33.7104 20.6625 32.9931 21 32.0002 21C31.0073 21 30.2901 20.6625 29.813 20.2809C29.5809 20.0951 29.4143 19.9062 29.3018 19.7562C29.2454 19.681 29.2019 19.6147 29.1698 19.5619C29.1538 19.5355 29.1406 19.5124 29.13 19.4931C29.1247 19.4835 29.12 19.4748 29.116 19.4671L29.1104 19.4564L29.108 19.4516L29.1069 19.4494L29.1058 19.4472C28.8588 18.9532 29.059 18.3526 29.553 18.1056Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M35.4785 23.1737C34.4101 23.7027 33.2066 24 31.9336 24C30.7119 24 29.5543 23.7262 28.5185 23.2365C28.5065 23.5001 28.5 23.7554 28.5 24C28.5 24.2652 28.3946 24.5196 28.2071 24.7071C28.0196 24.8946 27.7652 25 27.5 25H23C22.4477 25 22 24.5523 22 24C22 21.9202 23.0295 19.8022 24.199 18.0512C24.0258 17.3966 23.9336 16.7091 23.9336 16C23.9336 11.5817 27.5153 8 31.9336 8C36.3519 8 39.9336 11.5817 39.9336 16C39.9336 16.6576 39.8542 17.2967 39.7046 17.9082C40.9134 19.6862 42 21.8632 42 24C42 24.5523 41.5523 25 41 25H36.5C36.2348 25 35.9805 24.8946 35.7929 24.7071C35.6054 24.5196 35.5 24.2652 35.5 24C35.5 23.7361 35.4925 23.4596 35.4785 23.1737ZM37.9336 16C37.9336 19.3137 35.2473 22 31.9336 22C28.6199 22 25.9336 19.3137 25.9336 16C25.9336 12.6863 28.6199 10 31.9336 10C35.2473 10 37.9336 12.6863 37.9336 16ZM25.1749 20.2821C25.5719 20.9074 26.0533 21.4738 26.603 21.9654C26.5711 22.3167 26.5456 22.6629 26.5279 23H24.1042C24.2803 22.1148 24.6679 21.1901 25.1749 20.2821ZM37.3867 21.8536C37.4234 22.2432 37.4526 22.6272 37.4721 23H39.8959C39.7125 22.0784 39.2999 21.1141 38.762 20.1703C38.3812 20.7926 37.9176 21.3588 37.3867 21.8536Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M14 22C17.3137 22 20 19.3137 20 16C20 12.6863 17.3137 10 14 10C10.6863 10 8 12.6863 8 16C8 19.3137 10.6863 22 14 22ZM14 24C18.4183 24 22 20.4183 22 16C22 11.5817 18.4183 8 14 8C9.58172 8 6 11.5817 6 16C6 20.4183 9.58172 24 14 24Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12.881 18.5269C12.6263 18.0519 12.038 17.8629 11.5528 18.1056C11.0588 18.3526 10.8586 18.9532 11.1056 19.4472L11.1066 19.4494L11.1078 19.4516L11.1102 19.4564L11.1158 19.4671C11.1198 19.4748 11.1245 19.4835 11.1298 19.4931C11.1404 19.5124 11.1536 19.5355 11.1696 19.5619C11.2016 19.6147 11.2451 19.681 11.3016 19.7562C11.4141 19.9062 11.5806 20.0951 11.8128 20.2809C12.2898 20.6625 13.0071 21 14 21C14.9929 21 15.7102 20.6625 16.1872 20.2809C16.4194 20.0951 16.5859 19.9062 16.6984 19.7562C16.7549 19.681 16.7984 19.6147 16.8304 19.5619C16.8464 19.5355 16.8596 19.5124 16.8702 19.4931C16.8755 19.4835 16.8802 19.4748 16.8842 19.4671L16.8898 19.4564L16.8922 19.4516L16.8934 19.4494L16.8939 19.4483L16.8944 19.4472C17.1414 18.9532 16.9412 18.3526 16.4472 18.1056C15.962 17.8629 15.3737 18.0519 15.119 18.5269C15.1159 18.5318 15.1091 18.542 15.0984 18.5562C15.0703 18.5938 15.0181 18.6549 14.9378 18.7191C14.7898 18.8375 14.5071 19 14 19C13.4929 19 13.2102 18.8375 13.0622 18.7191C12.9819 18.6549 12.9297 18.5938 12.9016 18.5562C12.8909 18.542 12.8841 18.5318 12.881 18.5269Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M6 34C6 29.5817 9.58172 26 14 26C18.4183 26 22 29.5817 22 34V39C22 39.5523 21.5523 40 21 40C20.4477 40 20 39.5523 20 39V34C20 30.6863 17.3137 28 14 28C10.6863 28 8 30.6863 8 34V39C8 39.5523 7.55228 40 7 40C6.44772 40 6 39.5523 6 39V34Z" fill="#0C172F"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M24 34C24 29.5817 27.5817 26 32 26C36.4183 26 40 29.5817 40 34V39C40 39.5523 39.5523 40 39 40C38.4477 40 38 39.5523 38 39V34C38 30.6863 35.3137 28 32 28C28.6863 28 26 30.6863 26 34V39C26 39.5523 25.5523 40 25 40C24.4477 40 24 39.5523 24 39V34Z" fill="#0C172F"/>
		</svg>);
};
